import { createAction, props } from '@ngrx/store';

export const changeOrganization = createAction(
  '[ChangeOrganization] Change Organization',
  props<{ data: any }>()
);

export const changeOrganizationSuccess = createAction(
  '[ChangeOrganization] Change Organization Success',
  props<{ data: any }>()
);

export const changeOrganizationFailure = createAction(
  '[ChangeOrganization] Change Organization Failure',
  props<{ error: any }>()
);

export const clearChangeOrganizationState = createAction(
  '[ChangeOrganization] Clear Change Organization State',
);
