import { createAction, props } from '@ngrx/store';

export const loadInputs = createAction(
  '[Inputs] Load Inputs'
);

export const loadInputsSuccess = createAction(
  '[Inputs] Load Inputs Success',
  props<{ data: any }>()
);

export const loadInputStatusSuccess = createAction(
  '[Inputs] Load Input Status Success',
  props<{ data: any }>()
);

export const loadInputsFailure = createAction(
  '[Inputs] Load Inputs Failure',
  props<{ error: any }>()
);

export const clearInputsState = createAction(
  '[Inputs] clear Inputs State',
);
