<div [formGroup]="destinationControl" class="form-block">

  <!-- Encoder address field -->
  <mat-form-field>
    <mat-label>Encoder Address
      <app-required-field [validator]="destinationControl.get('address').validator"></app-required-field>
    </mat-label>
    <input matInput autocomplete="off" placeholder="Encoder Address" type="text" formControlName="address"
           appInputFilter [controlRef]="destinationControl['controls']['address']" [filterSpaceAtStart]="true" [filterTags]="true"
           (paste)="onPasteSrtAddress()">
    <mat-error *ngIf="destinationControl.get('address').invalid &&
              (destinationControl.get('address').dirty ||
              destinationControl.get('address').touched)">
      Property is always required and can`t be empty
    </mat-error>
  </mat-form-field>


  <!-- Encoder port field -->
  <mat-form-field>
    <mat-label>Encoder Port
      <app-required-field [validator]="destinationControl.get('port').validator"></app-required-field>
    </mat-label>
    <input matInput autocomplete="off" placeholder="Encoder Port" type="number" formControlName="port">
    <mat-error *ngIf="destinationControl.get('port').invalid &&
              (destinationControl.get('port').dirty ||
              destinationControl.get('port').touched)">
      Property is always required and can`t be empty
    </mat-error>
  </mat-form-field>

  <!-- Stream ID field -->
  <mat-form-field>
    <mat-label>Stream ID</mat-label>
    <input matInput autocomplete="off" placeholder="Stream ID" type="text" formControlName="srtStreamId">
  </mat-form-field>

  <!-- Is encrypted field -->
  <mat-checkbox color="primary" formControlName="isEncrypted"
                (change)="onChangeEncryption()">Encryption</mat-checkbox>

  <!-- Passphrase field -->
  <ng-container *ngIf="destinationControl.get('isEncrypted').value">
    <mat-form-field>
      <mat-label>Passphrase
        <app-required-field [validator]="destinationControl.get('passphrase').validator"></app-required-field>
      </mat-label>
      <input matInput autocomplete="off" placeholder="Passphrase" type="text" formControlName="passphrase">
      <mat-error *ngIf="destinationControl.get('passphrase').invalid &&
              (destinationControl.get('passphrase').dirty ||
              destinationControl.get('passphrase').touched)">
        Passphrase length must be between 10 and 79 characters
      </mat-error>
    </mat-form-field>
  </ng-container>

  <!-- Latency field -->
  <mat-form-field>
    <mat-label>Latency (ms)
      <app-required-field [validator]="destinationControl.get('latency').validator"></app-required-field>
    </mat-label>
    <input matInput autocomplete="off" type="number" formControlName="latency">
    <mat-error *ngIf="destinationControl.get('latency').invalid &&
              (destinationControl.get('latency').dirty ||
              destinationControl.get('latency').touched)">
      Latency must be between 120ms and 4000ms
    </mat-error>
  </mat-form-field>
</div>
