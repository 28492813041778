import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableSearchComponent {
  @ViewChild('searchInput') input: ElementRef;
  @Input() dataSource;
  @Input() itemsCount: number | undefined;
  @Output() search: EventEmitter<string> = new EventEmitter();

  public getValue() {
    return this.input.nativeElement.value;
  }

  public setValue(value: string) {
    this.input.nativeElement.value = value;
    this.search.emit(value);
  }
}
