import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, first, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import * as IncomeBandwidthActions from '../actions/income-bandwidth.actions';
import { DashboardService } from '../../../services/api/dashboard.service';
import { getChartTimeRecord } from '../selectors/chart-time.selectors';
import { Store } from '@ngrx/store';
import { getStatisticsClusterRecord } from '../selectors/statistics-cluster.selectors';



@Injectable()
export class IncomeBandwidthEffects {

  loadIncomeBandwidth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(IncomeBandwidthActions.loadIncomeBandwidth),
      switchMap(() =>
        forkJoin(
          this.store.select(getChartTimeRecord).pipe(first()),
          this.store.select(getStatisticsClusterRecord).pipe(first())
        ).pipe(
          first(),
          concatMap(([time, clusterId]) =>
            this.dashboardService.getIncomeBandwidth(time, clusterId).pipe(
              map(data => IncomeBandwidthActions.loadIncomeBandwidthSuccess({ data })),
              catchError(error => of(IncomeBandwidthActions.loadIncomeBandwidthFailure({ error }))))
          )
        )
      )
    );
  });



  constructor(private actions$: Actions,
              private store: Store,
              private dashboardService: DashboardService) {}

}
