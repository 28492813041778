import { createAction, props } from '@ngrx/store';

export const loadInput = createAction(
  '[Input] Load Input',
  props<{ data: any }>()
);

export const loadInputSuccess = createAction(
  '[Input] Load Input Success',
  props<{ data: any }>()
);

export const loadInputFailure = createAction(
  '[Input] Load Input Failure',
  props<{ error: any }>()
);

export const clearInputState = createAction(
  '[Input] clear Input State',
);
