import { createAction, props } from '@ngrx/store';

export const userDelete = createAction(
  '[UserDelete] User Delete',
  props<{ data: any }>()
);

export const userDeleteSuccess = createAction(
  '[UserDelete] User Delete Success',
  props<{ data: any }>()
);

export const userDeleteFailure = createAction(
  '[UserDelete] User Delete Failure',
  props<{ error: any }>()
);

export const clearUserDeleteState = createAction(
  '[UserDelete] clear User Delete State',
);
