import { createReducer, on } from '@ngrx/store';
import * as IncomeBandwidthActions from '../actions/income-bandwidth.actions';

export const incomeBandwidthFeatureKey = 'incomeBandwidth';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(IncomeBandwidthActions.loadIncomeBandwidth, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(IncomeBandwidthActions.loadIncomeBandwidthSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(IncomeBandwidthActions.loadIncomeBandwidthFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(IncomeBandwidthActions.clearIncomeBandwidthState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
