<button mat-stroked-button (click)="onSwitchRecordingMode()"
        [disabled]="(status === 'stopping'|| status === 'deleting')"
        [ngClass]="{
            'reverse':
              status === 'completed' ||
              status === 'failed'
            }"
>
  <span *ngIf="status === 'recording'">
    <app-stream-timer [startTime]="startedAt"></app-stream-timer>
  </span>
  <span *ngIf="status === 'starting'">Recording Starting...</span>
  <span *ngIf="status === 'waiting'">Waiting for Stream...</span>
  <span *ngIf="status === 'stopping'">Completing...</span>
  <span *ngIf="status === 'deleting'">Completing...</span>
  <span *ngIf="status === 'completed' || status === 'failed'">OFF</span>
  <mat-icon color="primary"
            [ngClass]="{
            'flickering-dot':
              status === 'recording' ||
              status === 'waiting' ||
              status === 'starting' ||
              status === 'deleting' ||
              status === 'stopping'
            }"
  >radio_button_checked
  </mat-icon>
</button>
