import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SwitchRecordingModeEvent} from '../../../pages/inputs/interfaces/switch-recording-mode.event.interface';
import {StreamDvrRecording} from '../../../models/stream/interfaces/stream-dvr-recording.interface';
import {Record} from '../../../models/record.interface';
import {InstanceRole} from '../../../services/api/streams/streams.service';
import {DvrRecordStatus} from '../../../services/api/dvr/types/dvr-record.status.type';

@Component({
  selector: 'app-switch-recording-btn',
  templateUrl: './switch-recording-btn.component.html',
  styleUrls: ['./switch-recording-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchRecordingBtnComponent implements OnChanges {
  @Input() inputId: string;
  @Input() clusterId: string;
  @Input() instanceRole: InstanceRole;
  @Input() dvrRecordsList: Record[];
  @Input() dvrRecord: StreamDvrRecording | null;
  @Output() switchRecordingModeEvent: EventEmitter<SwitchRecordingModeEvent> = new EventEmitter();

  public status: DvrRecordStatus = 'completed';
  public startedAt: number = Date.now();
  private recordId: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dvrRecord && changes.dvrRecord.currentValue) {
      const record: StreamDvrRecording = changes.dvrRecord.currentValue;
      this.status = record.status;
      this.startedAt = record.startedAt;
      this.recordId = record.id;
    }

    if (changes.dvrRecordsList && changes.dvrRecordsList.currentValue && changes.dvrRecordsList.currentValue.length) {
      const records: Record[] = changes.dvrRecordsList.currentValue
        .filter(dvrRecord =>
          dvrRecord.inputId === this.inputId &&
          dvrRecord.clusterId === this.clusterId &&
          dvrRecord.role === this.instanceRole
        );
      const sortedRecords: Record[] = records
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      const record: Record = sortedRecords[0];
      if (record) {
        this.recordId = record.id;
        this.status  = record.status;
        this.startedAt = new Date(record.start_timestamp).getTime() || null;
      }
    }
  }

  public onSwitchRecordingMode(): void {
    const recordId: string = this.recordId;
    switch (this.status) {
      case 'recording':
      case 'starting':
        this.switchRecordingModeEvent.emit({mode: 'stop', recordId});
        break;
      case 'completed':
      case 'failed':
        this.switchRecordingModeEvent.emit({mode: 'start', recordId});
        break;
      case 'waiting':
        this.switchRecordingModeEvent.emit({mode: 'delete', recordId});
        break;
      default:
        console.error('Unknown status: ' + status);
    }
  }

}
