<div *ngIf="inputsRecord && destinations && entityControl">
  <ng-container *ngIf="mode === 'all'">
    <mat-button-toggle-group name="type"
                             (change)="onChangeType()"
                             [value]="entityControl?.get('type')?.value ? entityControl?.get('type')?.value : entity?.type ? entity.type : 'stream'"
                             #group="matButtonToggleGroup"
                             class="entity-toggle-btn">
      <mat-button-toggle value="stream" >Streams</mat-button-toggle>
      <mat-button-toggle value="destination">Destinations</mat-button-toggle>
    </mat-button-toggle-group>

    <app-input-select *ngIf="group.value === 'stream'"
                      [entityControl]="entityControl"
                      [index]="index"
                      [entity]="entity?.type === 'stream' ? entity : null"
                      [validationRequired]="validationRequired"
                      [inputsRecord]="inputsRecord"
                      (changeEntity)="onEntityChange($event)"
                      (mouseEnter)="mouseEnter.emit($event)"></app-input-select>

    <app-destination-select *ngIf="group.value === 'destination'"
                            [entityControl]="entityControl"
                            [index]="index"
                            [entity]="entity?.type === 'destination' ? entity : null"
                            [destinations]="destinations"
                            (changeEntity)="onEntityChange($event)"
                            (mouseEnter)="mouseEnter.emit($event)"></app-destination-select>
  </ng-container>
  <ng-container *ngIf="mode === 'streams'">
    <app-input-select [entityControl]="entityControl"
                      [index]="index"
                      [entity]="entity"
                      [validationRequired]="validationRequired"
                      [inputsRecord]="inputsRecord"
                      (mouseEnter)="mouseEnter.emit($event)"></app-input-select>
  </ng-container>
  <ng-container></ng-container>
</div>
