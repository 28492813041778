import { createAction, props } from '@ngrx/store';

export const deleteInput = createAction(
  '[DeleteInput] Delete Input',
  props<{ data: any }>()
);

export const deleteInputSuccess = createAction(
  '[DeleteInput] Delete Input Success',
  props<{ data: any }>()
);

export const deleteInputFailure = createAction(
  '[DeleteInput] Delete Input Failure',
  props<{ error: any }>()
);

export const cleardeleteInputState = createAction(
  '[DeleteInput] clear Delete Input State',
);
