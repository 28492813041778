import { createAction, props } from '@ngrx/store';

export const activateInvitation = createAction(
  '[ActivateInvitation] Activate Invitation',
  props<{ data: any }>()
);

export const activateInvitationSuccess = createAction(
  '[ActivateInvitation] Activate Invitation Success',
  props<{ data: any }>()
);

export const activateInvitationFailure = createAction(
  '[ActivateInvitation] Activate Invitation Failure',
  props<{ error: any }>()
);

export const clearActivateInvitationState = createAction(
  '[ActivateInvitation] Clear Activate Invitation State',
);
