import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOrganization from '../reducers/organization.reducer';

export const selectOrganizationState = createFeatureSelector<fromOrganization.State>(
  fromOrganization.organizationFeatureKey
);

export const getOrganizationRecord = createSelector(
  selectOrganizationState,
  (state) => state.record
);

export const getOrganizationLoaded = createSelector(
  selectOrganizationState,
  (state) => state.loaded
);

export const getOrganizationLoading = createSelector(
  selectOrganizationState,
  (state) => state.loading
);

export const getOrganizationError = createSelector(
  selectOrganizationState,
  (state) => state.error
);
