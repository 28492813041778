import { createAction, props } from '@ngrx/store';

export const startVmixServer = createAction(
  '[StartVmixServer] Start Vmix Server',
  props<{ data: string }>()
);

export const startVmixServerSuccess = createAction(
  '[StartVmixServer] Start Vmix Server Success',
  props<{ data: any }>()
);

export const startVmixServerFailure = createAction(
  '[StartVmixServer] Start Vmix Server Failure',
  props<{ error: any }>()
);

export const clearStartVmixServerState = createAction(
  '[StartVmixServer] Clear Start Vmix Server State',
);
