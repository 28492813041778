<div [formGroup]="inputControl" autocomplete="off">
  <mat-form-field>
    <mat-label>Stream Name</mat-label>
    <input #trigger="matAutocompleteTrigger" type="text" matInput formControlName="currentInput" autocomplete="off"
           [matAutocomplete]="auto" (input)="onTypingInput()">
    <button *ngIf="inputControl.value" class="clear-button"
            mat-icon-button matSuffix aria-label="Clear"
            (click)="trigger.openPanel(); onInputCancelClick($event);">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectInput()">
      <mat-option *ngFor="let input of filteredInputs | async"
                  [value]="input"
                  [disabled]="isBlocked(input)">

        <div>
          {{input.inputName}} <span *ngIf="isBlocked(input)" class="used-label">({{ input.blockReason }})</span>
        </div>

      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="inputControl.invalid &&
                    (inputControl.dirty || inputControl.touched)">
      Please choose value from the list
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="showInputDetails">
    <mat-label>Cluster</mat-label>
    <mat-select formControlName="clusterId" (selectionChange)="onChangeCluster()">
      <mat-option *ngFor="let cluster of inputClusters" [value]="cluster.id">
        {{cluster.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="showInputDetails">
    <mat-label>Type</mat-label>
    <mat-select formControlName="role" (selectionChange)="onChangeCluster()">
      <mat-option [value]="'primary'">Primary</mat-option>
      <mat-option *ngIf="currentClusterInstances.length > 1" [value]="'backup'">Backup</mat-option>
    </mat-select>
  </mat-form-field>
</div>
