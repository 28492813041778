import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStartVmixServer from '../reducers/start-vmix-server.reducer';

export const selectStartVmixServerState = createFeatureSelector<fromStartVmixServer.State>(
  fromStartVmixServer.startVmixServerFeatureKey
);

export const getStartVmixServerRecord = createSelector(
  selectStartVmixServerState,
  (state) => state.record
);

export const getStartVmixServerLoaded = createSelector(
  selectStartVmixServerState,
  (state) => state.loaded
);

export const getStartVmixServerLoading = createSelector(
  selectStartVmixServerState,
  (state) => state.loading
);

export const getStartVmixServerError = createSelector(
  selectStartVmixServerState,
  (state) => state.error
);
