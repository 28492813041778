import { createAction, props } from '@ngrx/store';

export const deleteAccount = createAction(
  '[DeleteAccount] Delete Account'
);

export const deleteAccountSuccess = createAction(
  '[DeleteAccount] Delete Account Success',
  props<{ data: any }>()
);

export const deleteAccountFailure = createAction(
  '[DeleteAccount] Delete Account Failure',
  props<{ error: any }>()
);

export const clearDeleteAccountState = createAction(
  '[DeleteAccount] clear Delete Account State',
);
