import { createAction, props } from '@ngrx/store';

export const setMonitoringCluster = createAction(
  '[MonitoringCluster] Load Statistics Cluster',
  props<{ data: any }>()
);

export const clearMonitoringClusterState = createAction(
  '[MonitoringCluster] clear Statistics Cluster State',
);
