import { createReducer, on } from '@ngrx/store';
import * as ValidateShortnameActions from '../actions/validate-shortname.actions';

export const validateShortnameFeatureKey = 'validateShortname';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(ValidateShortnameActions.validateShortname, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ValidateShortnameActions.validateShortnameSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ValidateShortnameActions.validateShortnameFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ValidateShortnameActions.clearvalidateShortnameState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

