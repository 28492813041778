import { createReducer, on } from '@ngrx/store';
import * as OrganizationsActions from '../actions/organizations.actions';
import { Utils } from '../../../services/Utils';

export const organizationsFeatureKey = 'organizations';

export interface State {
  record: any[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(OrganizationsActions.loadOrganizations, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(OrganizationsActions.loadOrganizationsSuccess, (state, action) => ({
    record: Utils.naturalSortArrayByKey(action.data.data, 'name'),
    error: null,
    loaded: true,
    loading: false,
  })),
  on(OrganizationsActions.loadOrganizationsFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(OrganizationsActions.clearOrganizationsState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  })),
);
