<ng-container>
  <!-- sidenav container-->
  <mat-sidenav-container class="sidenav-container">
    <!-- sidenav -->
    <mat-sidenav #sidenav class="sidenav vvcr-primary"
                 mode="{{(device$ | async) === 'mobile' ? 'over' : 'side'}}"
                 opened="{{(device$ | async) === 'mobile' ? 'false' : 'true'}}"
                 [fixedInViewport]="true" [fixedTopGap]="0"
                 [fixedBottomGap]="0">
      <!-- app sidenav -->
      <app-sidenav [currentUser]="currentUser$ | async" (sidenavToggle)="sidenav.toggle()"></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- navbar -->
      <app-navbar [currentUser]="currentUser$ | async"
                  [organizationsRecord]="organizationsRecord$ | async"
                  [organizationId]="organizationId"
                  (chooseOrganization)="chooseOrganization($event)"
                  (sidenavToggle)="sidenav.toggle()"></app-navbar>
      <!-- router outlet -->
      <div class="router-container">
        <router-outlet (activate)="onActivate()"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
