import { createAction, props } from '@ngrx/store';
import {TranscodersSettings} from '../../../models/dashboard.interface';

export const setTranscodersSettings = createAction(
  '[TranscodersSettings] set Transcoders Settings',
  props<{ data: TranscodersSettings }>()
);

export const clearTranscodersSettingsState = createAction(
  '[TranscodersSettings] clear Transcoders Settings State',
);



