import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRecords from '../reducers/records.reducer';

export const selectRecordsState = createFeatureSelector<fromRecords.State>(
  fromRecords.recordsFeatureKey
);

export const getRecords = createSelector(
  selectRecordsState,
  (state) => state.record
);

export const getRecordsLoaded = createSelector(
  selectRecordsState,
  (state) => state.loaded
);

export const getRecordsLoading = createSelector(
  selectRecordsState,
  (state) => state.loading
);

export const getRecordsError = createSelector(
  selectRecordsState,
  (state) => state.error
);
