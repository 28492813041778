<div *ngIf="!disabled; else disable" class="content">
  <!-- Left copy icon -->
  <mat-icon color="primary" *ngIf="iconPosition === 'left' && (value || copyValue)"
            matTooltip="Copy" matTooltipPosition="above"
            [cdkCopyToClipboard]="copyValue ? copyValue : value"
            (click)="onCopy($event)">content_copy</mat-icon>
  <!-- Copy link -->
  <div *ngIf="!disabled" class="copy-link"
       matTooltip="{{copyValue ? copyValue : value}}" matTooltipPosition="above"
       [cdkCopyToClipboard]="copyValue ? copyValue : value"
       (click)="onCopy($event)">{{value}}</div>
  <!-- Right copy icon -->
  <mat-icon color="primary" *ngIf="iconPosition === 'right' && (value || copyValue)"
            matTooltip="Copy" matTooltipPosition="above"
            [cdkCopyToClipboard]="copyValue ? copyValue : value"
            (click)="onCopy($event)">content_copy</mat-icon>
  <!-- Open in new tab icon -->
  <mat-icon color="primary" *ngIf="showOpenIcon"
            matTooltip="Open in new tab" matTooltipPosition="above"
            (click)='onOpenNewTab($event)'>open_in_new</mat-icon>
</div>

<ng-template #disable>
  <!-- Left copy icon -->
  <mat-icon *ngIf="iconPosition === 'left'" class="disabled">content_copy</mat-icon>
  <!-- disabled copy link -->
  <div *ngIf="disabled" class="copy-link disabled">{{value}}</div>
  <!-- Right copy icon -->
  <mat-icon *ngIf="iconPosition === 'right'" class="disabled">content_copy</mat-icon>
  <!-- Open in new tab icon -->
  <mat-icon *ngIf="showOpenIcon" class="disabled">open_in_new</mat-icon>
</ng-template>
