import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ValidateShortnameActions from '../actions/validate-shortname.actions';
import { GridsService } from '../../../services/api/grids.service';



@Injectable()
export class ValidateShortnameEffects {

  validateShortname$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValidateShortnameActions.validateShortname),
      concatMap((payload) =>
        this.gridsService.validateShortname(payload.data).pipe(
          map(data => ValidateShortnameActions.validateShortnameSuccess({ data })),
          catchError(error => of(ValidateShortnameActions.validateShortnameFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private gridsService: GridsService) {}

}
