import { createAction, props } from '@ngrx/store';

export const deleteDestination = createAction(
  '[DeleteDestination] Delete Destination',
  props<{ data: any }>()
);

export const deleteDestinationSuccess = createAction(
  '[DeleteDestination] Delete Destination Success',
  props<{ data: any }>()
);

export const deleteDestinationFailure = createAction(
  '[DeleteDestination] Delete Destination Failure',
  props<{ error: any }>()
);

export const clearDeleteDestinationState = createAction(
  '[DeleteDestination] clear Delete Destination State',
);
