import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {AppMatTableDataSource} from '../../modules/material/app-table-datasource';


@Component({
  selector: 'app-empty-table-message',
  templateUrl: './empty-table-message.component.html',
  styleUrls: ['./empty-table-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyTableMessageComponent implements OnChanges {
  @Input() itemsCount: number = null;
  @Input() rows: any = [];
  @Input() loading: boolean;
  @Input() isBordered = true;
  isEmptyData = true;

  public ngOnChanges(): void {
    if (this.itemsCount) {
      this.isEmptyData = this.itemsCount === 0;
      return;
    }

    if (Array.isArray(this.rows)) {
      this.isEmptyData = this.rows.length === 0;
    } else if (this.rows instanceof MatTableDataSource || this.rows instanceof AppMatTableDataSource) {
      this.isEmptyData = this.rows.data.length === 0;
    }
  }
}
