import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeleteOrganization from '../reducers/delete-organization.reducer';

export const selectDeleteOrganizationState = createFeatureSelector<fromDeleteOrganization.State>(
  fromDeleteOrganization.deleteOrganizationFeatureKey
);

export const getDeleteOrganizationRecord = createSelector(
  selectDeleteOrganizationState,
  (state) => state.record
);

export const getDeleteOrganizationLoaded = createSelector(
  selectDeleteOrganizationState,
  (state) => state.loaded
);

export const getDeleteOrganizationLoading = createSelector(
  selectDeleteOrganizationState,
  (state) => state.loading
);

export const getDeleteOrganizationError = createSelector(
  selectDeleteOrganizationState,
  (state) => state.error
);
