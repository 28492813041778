import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCreateOrganization from '../reducers/create-organization.reducer';

export const selectCreateOrganizationState = createFeatureSelector<fromCreateOrganization.State>(
  fromCreateOrganization.createOrganizationFeatureKey
);

export const getCreateOrganizationRecord = createSelector(
  selectCreateOrganizationState,
  (state) => state.record
);

export const getCreateOrganizationLoaded = createSelector(
  selectCreateOrganizationState,
  (state) => state.loaded
);

export const getCreateOrganizationLoading = createSelector(
  selectCreateOrganizationState,
  (state) => state.loading
);

export const getCreateOrganizationError = createSelector(
  selectCreateOrganizationState,
  (state) => state.error
);
