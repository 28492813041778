import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCreateCluster from '../reducers/create-cluster.reducer';

export const selectCreateClusterState = createFeatureSelector<fromCreateCluster.State>(
  fromCreateCluster.createClusterFeatureKey
);

export const getCreateClusterRecord = createSelector(
  selectCreateClusterState,
  (state) => state.record
);

export const getCreateClusterLoaded = createSelector(
  selectCreateClusterState,
  (state) => state.loaded
);

export const getCreateClusterLoading = createSelector(
  selectCreateClusterState,
  (state) => state.loading
);

export const getCreateClusterError = createSelector(
  selectCreateClusterState,
  (state) => state.error
);
