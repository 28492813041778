import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { User } from '../../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private apiService: ApiService) {}

  public updateAccount(data: FormData): Observable<User> {
    return this.apiService.put(`/user`, data);
  }

  updatePassword(data: any): Observable<any> {
    return this.apiService.put(`/user/password/reset`, data);
  }

  deleteAccount(): Observable<any> {
    return this.apiService.get(`/user/delete/me`);
  }
}
