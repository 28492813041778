import { NgModule } from '@angular/core';
import { EntityManageButtonContainerComponent } from './entity-manage-button.container.component';
import { EntityManageButtonComponent } from './components/entity-manage-button/entity-manage-button.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    EntityManageButtonContainerComponent,
    EntityManageButtonComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    EntityManageButtonComponent,
    EntityManageButtonContainerComponent,
  ]
})
export class EntityManageButtonModule { }
