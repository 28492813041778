import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreState} from '../../store.state.interface';
import {DestinationStatusUpdate} from '../../../services/api/destinations/interfaces/destination-status.update.interface';

export const destinationsStatusesUpdatesFeatureKey = 'destinations-statuses-updates';
export const featureSelector = createFeatureSelector<StoreState<DestinationStatusUpdate[]>>(destinationsStatusesUpdatesFeatureKey);

export const destinationsStatusesUpdatesSelector = createSelector(
  featureSelector,
  (state) => state.data
);
