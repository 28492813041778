// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'dev',
  baseUrl: 'https://dev-front-api.vvcr.tv',
  connectionsUrl: 'https://connections.dev.vvcr.tv',
  fileStorageUrl: 'https://storage.vvcr.tv',
  statisticsBaseUrl: 'https://statistics.dev.vvcr.tv',
  storageBaseUrl: 'https://statistics.dev.vvcr.tv',
  trimBaseUrl: 'https://trim-dev.vvcr.live',
  paymentApiV1Url: 'https://signup-api-dev.vvcr.tv/payment_api/v1',
  statisticsWS: 'wss://w71j23oxx3.execute-api.us-east-2.amazonaws.com/dev',
  stripe_pk_key: 'pk_test_51IpYnLD34JJwZCeQxLsYelP45INGEytXf9me5hzi5Jzk7ZiWxfkpl3EmO2vHtSBR9k071P8e0fA6P9011FFkJYD9001kd57yo6',
  collaboraAppId: '0081e9ac3a444fe2be8fa528f00e756e',
  mapBoxToken: 'pk.eyJ1IjoidnZjciIsImEiOiJjbHdqYTZ2dWMxMDhhMmpuOGRyZnR3OHA2In0.P1i2rcb91jFAjgnhjiLrqA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
