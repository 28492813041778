import { createAction, props } from '@ngrx/store';

export const validateDns = createAction(
  '[ValidateDns] Validate Dns',
  props<{ data: any }>()
);

export const validateDnsSuccess = createAction(
  '[ValidateDns] Validate Dns Success',
  props<{ data: any }>()
);

export const validateDnsFailure = createAction(
  '[ValidateDns] Validate Dns Failure',
  props<{ error: any }>()
);

export const clearValidateDnsState = createAction(
  '[ValidateDns] Clear Validate Dns State',
  props<{ error: any }>()
);
