import { createAction, props } from '@ngrx/store';

export const updateAccountPassword = createAction(
  '[UpdateAccountPassword] UpdateAccount Password',
  props<{ data: any }>()
);

export const updateAccountPasswordSuccess = createAction(
  '[UpdateAccountPassword] UpdateAccount Password Success',
  props<{ data: any }>()
);

export const updateAccountPasswordFailure = createAction(
  '[UpdateAccountPassword] UpdateAccount Password Failure',
  props<{ error: any }>()
);

export const clearUpdateAccountPasswordState = createAction(
  '[UpdateAccountPassword] clear Update Account State',
);
