import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UserUpdateActions from '../actions/user-update.actions';
import { UsersService } from '../../../services/api/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';



@Injectable()
export class UserUpdateEffects {

  userUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserUpdateActions.userUpdate),
      concatMap((payload) =>
        this.usersService.updateUser(payload.data).pipe(
          map(data => UserUpdateActions.userUpdateSuccess({ data })),
          catchError(error => of(UserUpdateActions.userUpdateFailure({ error }))))
      )
    );
  });

  userUpdateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserUpdateActions.userUpdateSuccess),
      tap(() => {
        this.snackBar.open('User successfully updated', 'Success', {duration: 3000});
        if (this.router.url.includes('/organizations/')) {
          this.router.navigate(['/organizations']);
        } else {
          this.router.navigate(['/organization']);
        }
      }),
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private usersService: UsersService,
              private router: Router,
              private snackBar: MatSnackBar) {}

}
