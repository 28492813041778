import { createReducer, on } from '@ngrx/store';
import * as StorageSettingsActions from '../actions/storage-settings.actions';
import { StorageSettings } from '../../../models/dashboard.interface';

export const storageSettingsFeatureKey = 'storageSettings';

export interface State {
  settings: StorageSettings;
}

export const initialState: State = {
  settings: {
    ALL: true,
    DVR: false,
    CLIP_POST: false,
  },
};

export const reducer = createReducer(
  initialState,

  on(StorageSettingsActions.setStorageSettings, (state: State, action: {data: StorageSettings}): {settings: StorageSettings} => ({
    settings: action.data,
  })),
  on(StorageSettingsActions.clearStorageSettingsState, () => ({
    settings: {
      ALL: true,
      DVR: false,
      CLIP_POST: false,
    },
  }))
);
