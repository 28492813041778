import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntitySelectFormContainerComponent } from './entity-select-form.container.component';
import { EntitySelectFormComponent } from './components/entity-select-form/entity-select-form.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { DestinationSelectComponent } from './components/destination-select/destination-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';



@NgModule({
  declarations: [
    EntitySelectFormContainerComponent,
    EntitySelectFormComponent,
    InputSelectComponent,
    DestinationSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [
    EntitySelectFormContainerComponent,
    EntitySelectFormComponent,
    InputSelectComponent,
    DestinationSelectComponent,
  ]
})
export class EntitySelectFormModule { }
