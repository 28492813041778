import { createAction, props } from '@ngrx/store';
import {TranscodingScenariosStatistic} from '../../../services/api/statistics/transcoding-scenarios/transcoding-scenarios.statistic.model';

export const loadTranscoders = createAction(
  '[Transcoders] Load Transcoders'
);

export const loadTranscodersSuccess = createAction(
  '[Transcoders] Load Transcoders Success',
  props<{ data: TranscodingScenariosStatistic }>()
);

export const loadTranscodersFailure = createAction(
  '[Transcoders] Load Transcoders Failure',
  props<{ error: any }>()
);

export const clearTranscodersState = createAction(
  '[Transcoders] clear Transcoders State',
);


