import {LiveMonitoring} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.interface';
import {createReducer, on} from '@ngrx/store';
import {StoreState} from '../../store.state.interface';
import {liveMonitoringInitialStateData} from './live-monitoring.initial-state.data';
import {loadLiveMonitoringSuccess, loadLiveMonitoring, filterLiveMonitoringByClusterId} from './live-monitoring.actions';

export const initialState: StoreState<LiveMonitoring> = {
  data: liveMonitoringInitialStateData,
  error: null,
  isLoaded: true
};

export const liveMonitoringReducer = createReducer(
  initialState,

  on(loadLiveMonitoring, (state) => ({
    ...state,
    isLoaded: false
  })),
  on(loadLiveMonitoringSuccess, (state, action) => ({
    ...state,
    data: action.data,
    isLoaded: true
  })),
  on(filterLiveMonitoringByClusterId, (state) => ({
    ...state,
    isLoaded: false
  }))
);
