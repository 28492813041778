import { createAction, props } from '@ngrx/store';

export const validateStreamkey = createAction(
  '[ValidateStreamkey] Validate Streamkey',
  props<{ data: any }>()
);

export const validateStreamkeySuccess = createAction(
  '[ValidateStreamkey] Validate Streamkey Success',
  props<{ data: any }>()
);

export const validateStreamkeyFailure = createAction(
  '[ValidateStreamkey] Validate Streamkey Failure',
  props<{ error: any }>()
);

export const clearValidateStreamkeyState = createAction(
  '[ValidateStreamkey] clear Validate Streamkey State',
);
