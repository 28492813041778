import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStatistics from '../reducers/statistics.reducer';

export const selectStatisticsState = createFeatureSelector<fromStatistics.State>(
  fromStatistics.statisticsFeatureKey
);

export const getStatisticsRecord = createSelector(
  selectStatisticsState,
  (state) => state.record
);

export const getStatisticsLoaded = createSelector(
  selectStatisticsState,
  (state) => state.loaded
);

export const getStatisticsLoading = createSelector(
  selectStatisticsState,
  (state) => state.loading
);

export const getStatisticsError = createSelector(
  selectStatisticsState,
  (state) => state.error
);
