import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPaymentInfo from '../reducers/payment-info.reducer';

export const selectPaymentInfoState = createFeatureSelector<fromPaymentInfo.State>(
  fromPaymentInfo.paymentInfoFeatureKey
);

export const getPaymentInfoRecord = createSelector(
  selectPaymentInfoState,
  (state) => state.record
);

export const getPaymentInfoLoaded = createSelector(
  selectPaymentInfoState,
  (state) => state.loaded
);

export const getPaymentInfoLoading = createSelector(
  selectPaymentInfoState,
  (state) => state.loading
);

export const getPaymentInfoError = createSelector(
  selectPaymentInfoState,
  (state) => state.error
);
