import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private apiService: ApiService) {}

  getStatistics(data): Observable<any> {
    return this.apiService.post(`/statistics/get-by-streams`, data);
  }

  getBillingPeriods(): Observable<any> {
    return this.apiService.get(`/statistics/billing-period`);
  }
}
