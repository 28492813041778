import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTranscoders from '../reducers/transcoders.reducer';

export const selectTranscodersState = createFeatureSelector<fromTranscoders.State>(
  fromTranscoders.transcodersFeatureKey
);

export const getTranscodersRecord = createSelector(
  selectTranscodersState,
  (state: fromTranscoders.State) => state.record
);

export const getTranscodersLoaded = createSelector(
  selectTranscodersState,
  (state: fromTranscoders.State) => state.loaded
);

export const getTranscodersLoading = createSelector(
  selectTranscodersState,
  (state: fromTranscoders.State) => state.loading
);

export const getTranscodersError = createSelector(
  selectTranscodersState,
  (state: fromTranscoders.State) => state.error
);
