import { Component, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Cluster } from '../../../models/cluster.interface';
import { getClustersLoaded, getClustersRecord } from '../../../store/clusters/selectors/clusters.selectors';
import { select, Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { loadClusters } from '../../../store/clusters/actions/clusters.actions';
import { InputInterface } from '../../../models/input.interface';
import { getInputsRecord } from '../../../store/inputs/selectors/inputs.selectors';
import { User } from '../../../models/user.interface';
import { getCurrentUserRecord } from '../../../store/users/selectors/current-user.selectors';

@Component({
  selector: 'app-entity-manage-button-container',
  template: `
    <app-entity-manage-button [clustersRecord]="clustersRecord$ | async"
                              [inputs]="inputs$ | async"
                              [currentUser]="currentUser$ | async"
                              [entity]="entity"
                              (clickEmit)="clickEmit.emit()"></app-entity-manage-button>
  `,
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityManageButtonContainerComponent implements OnDestroy {
  @Input() entity: string;
  @Output() clickEmit: EventEmitter<void> = new EventEmitter();
  inputs$: Observable<InputInterface[]>;
  currentUser$: Observable<User>;
  clustersRecord$: Observable<Cluster[]>;
  clustersSubscription: Subscription;

  constructor(private store: Store) {
    this.clustersRecord$ = store.select(getClustersRecord);
    this.inputs$ = this.store.select(getInputsRecord);
    this.currentUser$ = this.store.select(getCurrentUserRecord);
    this.clustersSubscription = this.store.pipe(select(getClustersLoaded)).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(loadClusters());
        }
      }),
      filter(loaded => loaded),
      take(1)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.clustersSubscription.unsubscribe();
  }
}
