import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {CollaboraUser} from './models/collabora-user.module';
import {CollaboraCredentials} from './models/collabora.credentials.model';

@Injectable({
  providedIn: 'root'
})
export class CollaboraService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  public authenticate(channelName: string): Observable<CollaboraCredentials> {
    return this.apiService.get<CollaboraCredentials>(`/collabora/authenticate/${channelName}`);
  }

  public getUsersList(): Observable<CollaboraUser[]> {
    return this.apiService.get<CollaboraUser[]>('/collabora/users');
  }
}
