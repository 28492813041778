import { createAction, props } from '@ngrx/store';

export const loadVmixServerStatus = createAction(
  '[VmixServerStatus] Load Vmix Server Status',
  props<{ data: any }>()
);

export const loadVmixServerStatusSuccess = createAction(
  '[VmixServerStatus] Load Vmix Server Status Success',
  props<{ data: any }>()
);

export const loadVmixServerStatusFailure = createAction(
  '[VmixServerStatus] Load Vmix Server Status Failure',
  props<{ error: any }>()
);

export const clearVmixServerStatusState = createAction(
  '[VmixServerStatus] Clear Vmix Server Status State',
);
