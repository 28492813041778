import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions} from '@angular/material/paginator';
import {Provider} from '@angular/core';

const paginatorOptions: MatPaginatorDefaultOptions = {
  formFieldAppearance: 'fill'
}

export const matPaginatorOptions: Provider = {
  provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
  useValue: paginatorOptions
}
