import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as StartDestinationActions from '../actions/start-destination.actions';
import { DestinationsService } from '../../../services/api/destinations/destinations.service';
import * as DestinationsActions from '../actions/destinations.actions';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class StartDestinationEffects {

  startDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StartDestinationActions.startDestination),
      concatMap((payload) =>
        this.destinationsService.startDestination(payload.data).pipe(
          map(data => StartDestinationActions.startDestinationSuccess({ data })),
          catchError(error => of(StartDestinationActions.startDestinationFailure({ error }))))
      )
    );
  });

  startDestinationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StartDestinationActions.startDestinationSuccess),
      tap(() => this.snackBar.open('Destination started successfully', 'Success', {duration: 3000})),
      map(() => DestinationsActions.loadDestinations()),
    );
  });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private destinationsService: DestinationsService) {}

}
