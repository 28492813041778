import {Injectable} from '@angular/core';
import {LoadedScripts, LoadScriptsResult, Script, ScriptName} from './script.model';

export const ScriptStore: Script[] = [
  {name: 'sldp-player', src: 'https://storage.vvcr.tv/sldp-player-v2.26.0.js'},
  {name: 'hlsjs', src: 'https://cdn.jsdelivr.net/npm/hls.js@latest'},
  {name: 'videojs', src: 'https://vjs.zencdn.net/7.10.2/video.min.js'},
  {name: 'videojs-markers', src: 'https://cdn.jsdelivr.net/npm/videojs-markers@1.0.1/dist/videojs-markers.min.js'}
];

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private scripts: LoadedScripts = {};

  constructor() {
    ScriptStore.forEach((script: Script) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  public loadScript(name: ScriptName): Promise<LoadScriptsResult> {
    return new Promise<LoadScriptsResult>(resolve => {
      if (!this.scripts[name].loaded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = () => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      }
    });
  }
}
