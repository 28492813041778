import { createAction, props } from '@ngrx/store';

export const createGrid = createAction(
  '[CreateGrid] Create Grid',
  props<{ data: any }>()
);

export const createGridSuccess = createAction(
  '[CreateGrid] Create Grid Success',
  props<{ data: any }>()
);

export const createGridFailure = createAction(
  '[CreateGrid] Create Grid Failure',
  props<{ error: any }>()
);

export const clearCreateGridState = createAction(
  '[CreateGrid] clear Create Grid State',
);
