import { createAction, props } from '@ngrx/store';

export const setStatisticsCluster = createAction(
  '[StatisticsCluster] Load Statistics Cluster',
  props<{ data: any }>()
);

export const clearStatisticsClusterState = createAction(
  '[StatisticsCluster] clear Statistics Cluster State',
);
