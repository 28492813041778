import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UpdateAccountPasswordActions from '../actions/update-account-password.actions';
import { AccountService } from '../../../services/api/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class UpdateAccountPasswordEffects {

  updateAccountPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateAccountPasswordActions.updateAccountPassword),
      concatMap((payload) =>
        this.accountService.updatePassword(payload.data).pipe(
          map(data => UpdateAccountPasswordActions.updateAccountPasswordSuccess({ data })),
          catchError(error => of(UpdateAccountPasswordActions.updateAccountPasswordFailure({ error }))))
      )
    );
  });

  updateAccountPasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateAccountPasswordActions.updateAccountPasswordSuccess),
      tap(() => this.snackBar.open('Password successfully updated', 'Success', {duration: 3000})),
    );
  }, {dispatch: false});



  constructor(private actions$: Actions,
              private accountService: AccountService,
              private snackBar: MatSnackBar) {}

}
