import { Injectable } from '@angular/core';
import { InputsTableConfig } from '../../../models/input.interface';

@Injectable({
  providedIn: 'root'
})
export class InputsTableService {
  tableConfig: InputsTableConfig;

  public getTableConfig() {
    return this.tableConfig;
  }

  setTableConfig(config: InputsTableConfig) {
    this.tableConfig = config;
  }
}


