import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DeleteInputActions from '../actions/delete-input.actions';
import { InputsService } from '../../../services/api/inputs.service';
import * as InputsActions from '../actions/inputs.actions';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class DeleteInputEffects {

  deleteInput$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteInputActions.deleteInput),
      concatMap((payload) =>
        this.inputsService.deleteInput(payload.data).pipe(
          map(data => DeleteInputActions.deleteInputSuccess({ data })),
          catchError(error => of(DeleteInputActions.deleteInputFailure({ error }))))
      )
    );
  });

  inputDeleteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteInputActions.deleteInputSuccess),
      tap(() => this.snackBar.open('Input successfully deleted', 'Success', {duration: 3000})),
      map(() => InputsActions.loadInputs()),
    );
  });



  constructor(private actions$: Actions,
              private inputsService: InputsService,
              private snackBar: MatSnackBar) {}

}
