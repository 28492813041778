import { createAction, props } from '@ngrx/store';

export const loadDestinations = createAction(
  '[Destinations] Load Destinations'
);

export const loadDestinationsSuccess = createAction(
  '[Destinations] Load Destinations Success',
  props<{ data: any }>()
);

export const loadDestinationsFailure = createAction(
  '[Destinations] Load Destinations Failure',
  props<{ error: any }>()
);

export const clearDestinationsState = createAction(
  '[Destinations] clear Destinations State',
);
