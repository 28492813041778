import { createAction, props } from '@ngrx/store';

export const createInput = createAction(
  '[CreateInput] Create Input',
  props<any>()
);

export const createInputSuccess = createAction(
  '[CreateInput] Create Input Success',
  props<{ data: any }>()
);

export const createInputFailure = createAction(
  '[CreateInput] Create Input Failure',
  props<{ error: any }>()
);

export const clearCreateInputState = createAction(
  '[CreateInput] clear Create Input State',
);
