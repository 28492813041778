import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUpdateAccountPassword from '../reducers/update-account-password.reducer';

export const selectUpdateAccountPasswordState = createFeatureSelector<fromUpdateAccountPassword.State>(
  fromUpdateAccountPassword.updateAccountPasswordFeatureKey
);

export const getUpdateAccountPasswordRecord = createSelector(
  selectUpdateAccountPasswordState,
  (state) => state.record
);

export const getUpdateAccountPasswordLoaded = createSelector(
  selectUpdateAccountPasswordState,
  (state) => state.loaded
);

export const getUpdateAccountPasswordLoading = createSelector(
  selectUpdateAccountPasswordState,
  (state) => state.loading
);

export const getUpdateAccountPasswordError = createSelector(
  selectUpdateAccountPasswordState,
  (state) => state.error
);
