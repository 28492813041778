<div class="container" [ngStyle]="{ 'visibility': playerVisibility}">
  <div id="player-{{index}}" #video [ngStyle]="{'width.px': sizes.width, 'height.px': sizes.height}"></div>
  <div [ngClass]="{ overlay: isShowOverlay(), 'empty-overlay': !currentInputData?.entityId}">
    <div class="info">
      <div *ngIf="gridOverlays?.inputNameOverlay" class="name">
        <span *ngIf="currentInputData?.inputName">{{currentInputData.inputName}}, </span>
        <span *ngIf="currentInputData?.clusterName">{{currentInputData.clusterName}}, </span>
        <span *ngIf="currentInputData?.role">{{currentInputData.role | titlecase}}</span>
      </div>
      <app-copy-link iconPosition="none" *ngIf="gridOverlays?.inputAddressOverlay && currentInputData?.inputUrl" [value]="currentInputData.inputUrl"></app-copy-link>
      <div class="empty-input" *ngIf="isShowNotConfiguredMessage()">
        Input is not configured
      </div>
    </div>
  </div>

  <!-- Mute/ Unmute / Sound level icon -->
  <div class="sound-icon"
       *ngIf="isDisplaySoundIcon && !isUseNativeControls && this.isConnected "
       (click)="toggleSound()"
       (mouseenter)="onMouseEnter()"
       (mouseleave)="onMouseLeave()"
  >
    <mat-icon color="primary" *ngIf="!isMuted">volume_up</mat-icon>
    <mat-icon color="primary" *ngIf="isMuted">volume_off</mat-icon>
  </div>

  <div class="bitrate" *ngIf="gridOverlays?.bitrateOverlay && currentStream?.bandwidth && currentInputData.entityId">
    {{formatBitrate()}} kb/s
  </div>

  <div class="expand"
       *ngIf="isDisplayExpandIcon && this.isConnected"
       (click)="onExpand()"
  >
    <mat-icon color="primary">crop_free</mat-icon>
  </div>

  <div *ngIf="gridOverlays?.vumetersOverlay && isEnabledVU">
    <div *ngFor="let item of VUIndicatorQty; let i = index">
      <app-sldp-player-vu-indicator [VUValues]="VUUpdatedValues"
                                    [VUHeight]="VUHeight"
                                    [index]="i"></app-sldp-player-vu-indicator>
    </div>
  </div>

  <div class="sound-slider"
       (mouseenter)="onMouseEnter()"
       (mouseleave)="onMouseLeave()"
       *ngIf="!isUseNativeControls" [ngStyle]="{ 'visibility': isShowSoundSlider ? 'visible' : 'hidden'}">
    <app-volume-slider [volume]="volume" (onVolumeChange)=onChangeVolume($event)></app-volume-slider>
  </div>
</div>
