import { createAction, props } from '@ngrx/store';

export const loadInstances = createAction(
  '[Instances] Load Instances'
);

export const loadInstancesSuccess = createAction(
  '[Instances] Load Instances Success',
  props<{ data: any }>()
);

export const loadInstancesFailure = createAction(
  '[Instances] Load Instances Failure',
  props<{ error: any }>()
);

export const clearInstancesState = createAction(
  '[Instances] clear Instances State',
);
