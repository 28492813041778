import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DeleteOrganizationActions from '../actions/delete-organization.actions';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as OrganizationsActions from '../actions/organizations.actions';



@Injectable()
export class DeleteOrganizationEffects {

  deleteOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteOrganizationActions.deleteOrganization),
      concatMap((payload) =>
        this.organizationsService.deleteOrganization(payload.data).pipe(
          map(data => DeleteOrganizationActions.deleteOrganizationSuccess({ data })),
          catchError(error => of(DeleteOrganizationActions.deleteOrganizationFailure({ error }))))
      )
    );
  });

  deleteOrganizationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteOrganizationActions.deleteOrganizationSuccess),
      tap(() => this.snackBar.open('Organization successfully deleted', 'Success', {duration: 3000})),
      map(() => OrganizationsActions.loadOrganizations()),
    );
  });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService,
              private snackBar: MatSnackBar) {}

}
