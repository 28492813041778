<button mat-icon-button class="toogle-btn" (click)="sidenavToggle.emit()">
  <mat-icon>menu</mat-icon>
</button>
<div class="sidenav">
  <div class="logo-box">
    <div class="logo" routerLink="/"></div>
  </div>
</div>

<mat-list class="list">
  <ng-container *ngFor="let menuItem of menus" class="menu-item">
    <ng-container *ngIf="isShowMenuItem(menuItem)">
      <mat-list-item class="menu-item" *ngIf="!menuItem.submenus"
                     routerLink="{{menuItem.path}}" routerLinkActive="active" #rla="routerLinkActive">
        <div class="menu-item-content">
          <img class="sidenav-icon" [ngClass]="{'active-icon': rla.isActive}"
               [ngSrc]="getIconPath(menuItem)" width="24" height="24" alt="">
          <h3>{{menuItem.label}}</h3>
        </div>
      </mat-list-item>

      <!--Submenus-->
      <mat-accordion class="sidenav-accordion" *ngIf="menuItem.submenus">
        <mat-expansion-panel [expanded]="isOpenSubmenu">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-list-item class="submenu">
                <div class="menu-item-content">
                  <img class="sidenav-icon"
                       [ngSrc]="getIconPath(menuItem)" width="24" height="24" alt="">
                  <h3>{{menuItem.label}}</h3>
                </div>
              </mat-list-item>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-list>
            <div *ngFor="let submenu of menuItem.submenus">
              <mat-list-item class="menu-item"
                             *ngIf="submenu.access.includes(currentUser.role.value) &&
                                    submenu.allowedLicenses.includes(currentUser.currentOrganization.licence_id)"
                             routerLink="{{submenu.path}}" routerLinkActive="active" #rla="routerLinkActive">
                <div class="menu-item-content">
                  <img class="sidenav-icon" [ngClass]="{'active-icon': rla.isActive}"
                       [ngSrc]="getIconPath(submenu)" width="24" height="24" alt="">
                  <h3>{{submenu.label}}</h3>
                </div>
              </mat-list-item>
            </div>
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </ng-container>
</mat-list>
