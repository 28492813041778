import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UpdeteGridActions from '../actions/update-grid.actions';
import { GridsService } from '../../../services/api/grids.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';



@Injectable()
export class UpdateGridEffects {

  updeteGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdeteGridActions.updeteGrid),
      concatMap((payload) =>
        this.gridsService.updateGrid(payload.data).pipe(
          map(data => UpdeteGridActions.updeteGridSuccess({ data })),
          catchError(error => of(UpdeteGridActions.updeteGridFailure({ error }))))
      )
    );
  });

  updeteGridSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdeteGridActions.updeteGridSuccess),
      tap(() => this.snackBar.open('Grid successfully updated', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/multiview']))
    );
  }, { dispatch: false });


  constructor(private actions$: Actions,
              private gridsService: GridsService,
              private snackBar: MatSnackBar,
              private router: Router) {}

}
