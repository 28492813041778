import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {HttpClientModule} from '@angular/common/http';
import {EntityStoreModule} from './store/entity-store.module';
import {modules} from './modules';
import {SharedModule} from './shared/shared.module';
import {AppLayoutComponent} from './app-layout.component';
import {FooterComponent} from './components/footer/footer.component';
import {interceptors} from './services/interceptors';
import {materialComponentsOptions} from './material-options/material-options';
import {NgOptimizedImage} from '@angular/common';
import {CollaboraModule} from './shared/components/collabora/collabora.module';

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    NavbarComponent,
    SidenavComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    EntityStoreModule,
    SharedModule,
    CollaboraModule,
    NgOptimizedImage,
    ...modules,
  ],
  providers: [
    ...interceptors,
    ...materialComponentsOptions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
