import { createAction, props } from '@ngrx/store';

export const updeteGrid = createAction(
  '[UpdeteGrid] Load UpdeteGrids',
  props<{ data: any }>()
);

export const updeteGridSuccess = createAction(
  '[UpdeteGrid] Updete Grid Success',
  props<{ data: any }>()
);

export const updeteGridFailure = createAction(
  '[UpdeteGrid] Updete Grid Failure',
  props<{ error: any }>()
);

export const clearUpdeteGridState = createAction(
  '[UpdeteGrid] clear Updete Grid State',
);
