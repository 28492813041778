import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as PaymentInfoActions from '../actions/payment-info.actions';
import { PaymentService } from '../../../services/api/payment.service';



@Injectable()
export class PaymentInfoEffects {

  loadPaymentInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PaymentInfoActions.loadPaymentInfo),
      concatMap(() =>
        this.paymentService.getPaymentInfo().pipe(
          map(data => PaymentInfoActions.loadPaymentInfoSuccess({ data })),
          catchError(error => of(PaymentInfoActions.loadPaymentInfoFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private paymentService: PaymentService) {}

}
