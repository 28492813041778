import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as RetryProductionInstanceActions from '../actions/retry-production-instance.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ProductionInstancesService} from '../../../services/api/production-instances/production-instances.service';



@Injectable()
export class RetryProductionInstanceEffects {

  constructor(
    private readonly actions: Actions,
    private readonly productionInstancesService: ProductionInstancesService,
    private readonly snackBar: MatSnackBar
  ) {}

  retryProductionInstance = createEffect(() => {
    return this.actions.pipe(
      ofType(RetryProductionInstanceActions.retryProductionInstance),
      concatMap((payload) =>
        this.productionInstancesService.retry(payload.data)
          .pipe(
            map(data => RetryProductionInstanceActions.retryProductionInstanceSuccess({ data })),
            catchError(error => of(RetryProductionInstanceActions.retryProductionInstanceFailure({ error }))))
      )
    );
  });

  retryProductionInstanceSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(RetryProductionInstanceActions.retryProductionInstanceSuccess),
      tap(() => this.snackBar.open('Production instance retry action successfully started', 'Success', {duration: 3000})),
    );
  }, {dispatch: false});

}
