import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DeleteDestinationActions from '../actions/delete-destination.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DestinationsService } from '../../../services/api/destinations/destinations.service';
import * as DestinationsActions from '../actions/destinations.actions';



@Injectable()
export class DeleteDestinationEffects {

  deleteDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteDestinationActions.deleteDestination),
      concatMap((payload) =>
        this.destinationsService.deleteDestination(payload.data).pipe(
          map(data => DeleteDestinationActions.deleteDestinationSuccess({ data })),
          catchError(error => of(DeleteDestinationActions.deleteDestinationFailure({ error }))))
      )
    );
  });

  deleteDestinationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteDestinationActions.deleteDestinationSuccess),
      tap(() => this.snackBar.open('Destination successfully deleted', 'Success', {duration: 3000})),
      map(() => DestinationsActions.loadDestinations()),
    );
  });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private destinationsService: DestinationsService) {}

}
