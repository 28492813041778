import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from '../../models/user.interface';
import { Store } from '@ngrx/store';
import * as fromLogin from '../../store/auth/login/reducers/login.reducer';
import { getCurrentUserRecord } from '../../store/users/selectors/current-user.selectors';

@Directive({
  selector: '[appPermissionRole]'
})
export class PermissionRoleDirective {
  @Input() roleValue: string;
  currentUser: User;

  constructor(private element: ElementRef,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private store: Store<fromLogin.State>) {
    this.store.select(getCurrentUserRecord).subscribe(
      user => this.currentUser = user
    );
  }

  @Input()
  set appPermissionRole(val) {
    if (this.currentUser.role.value >= val) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
