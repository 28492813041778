import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CreateOrganizationActions from '../actions/create-organization.actions';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class CreateOrganizationEffects {

  createOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateOrganizationActions.createOrganization),
      concatMap((payload) =>
        this.organizationsService.createOrganization(payload.data).pipe(
          map(data => CreateOrganizationActions.createOrganizationSuccess({ data })),
          catchError(error => of(CreateOrganizationActions.createOrganizationFailure({ error }))))
      )
    );
  });

  createOrganizationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateOrganizationActions.createOrganizationSuccess),
      tap(() => this.snackBar.open('Organization successfully created', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/organizations']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService,
              private snackBar: MatSnackBar,
              private router: Router) {}

}
