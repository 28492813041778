import { createReducer, on } from '@ngrx/store';
import * as CreateVmixServerActions from '../actions/create-vmix-server.actions';
import {Production} from '../../../models/production.interface';

export const createVmixServerFeatureKey = 'createVmixServer';

export interface State {
  record: Production;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(CreateVmixServerActions.createVmixServerSuccess, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(CreateVmixServerActions.createVmixServerSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(CreateVmixServerActions.createVmixServerFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(CreateVmixServerActions.clearCreateVmixServerState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
