import { createReducer, on } from '@ngrx/store';
import * as OrganizationLicensesActions from '../actions/organization-licenses.actions';

export const organizationLicensesFeatureKey = 'organizationLicenses';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(OrganizationLicensesActions.loadOrganizationLicenses, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(OrganizationLicensesActions.loadOrganizationLicensesSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(OrganizationLicensesActions.loadOrganizationLicensesFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(OrganizationLicensesActions.clearOrganizationLicensesState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

