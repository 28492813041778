import {ActionReducer, createReducer, on} from '@ngrx/store';
import * as TranscodersActions from '../actions/transcoders.actions';
import {TranscodingScenariosStatistic} from '../../../services/api/statistics/transcoding-scenarios/transcoding-scenarios.statistic.model';

export const transcodersFeatureKey = 'transcoders';

export interface State {
  record: TranscodingScenariosStatistic | null
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};

export const reducer: ActionReducer<State> = createReducer(
  initialState,

  on(TranscodersActions.loadTranscoders, (): State => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(TranscodersActions.loadTranscodersSuccess, (state: State, action: {data: TranscodingScenariosStatistic}): State => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(TranscodersActions.loadTranscodersFailure, (state: State, action): State => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(TranscodersActions.clearTranscodersState, (): State => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),

);
