import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as InputUpdateActions from '../actions/input-update.actions';
import { Router } from '@angular/router';
import { InputsService } from '../../../services/api/inputs.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class InputUpdateEffects {

  inputUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InputUpdateActions.inputUpdate),
      concatMap((payload) =>
        this.inputsService.updateInput(payload.data).pipe(
          map(data => InputUpdateActions.inputUpdateSuccess({ data })),
          catchError(error => of(InputUpdateActions.inputUpdateFailure({ error }))))
      )
    );
  });

  inputUpdateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InputUpdateActions.inputUpdateSuccess),
      tap(() => this.snackBar.open('Input successfully updated', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/streams']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private router: Router,
              private inputsService: InputsService,
              private snackBar: MatSnackBar) {}

}
