import { createAction, props } from '@ngrx/store';

export const userUpdate = createAction(
  '[UserUpdate] User Update',
  props<{ data: any }>()
);

export const userUpdateSuccess = createAction(
  '[UserUpdate] User Update Success',
  props<{ data: any }>()
);

export const userUpdateFailure = createAction(
  '[UserUpdate] User Update Failure',
  props<{ error: any }>()
);

export const clearUserUpdateState = createAction(
  '[UserUpdate] clear User Update State',
);
