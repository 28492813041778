import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {Observable} from 'rxjs';
import {DestinationUpdate} from '../../../models/destinations/destination.update.interface';

@Injectable({
  providedIn: 'root'
})
export class DestinationsService {

  constructor(private apiService: ApiService) {}

  getDestinations(): Observable<any> {
    return this.apiService.get(`/destinations`);
  }

  getDestination(destinationId): Observable<any> {
    return this.apiService.get(`/destinations/${destinationId}`);
  }

  createDestination(data): Observable<any> {
    return this.apiService.post(`/destinations`, data);
  }

  updateDestination(data: DestinationUpdate): Observable<any> {
    return this.apiService.put(`/destinations/${data.id}`, data);
  }

  deleteDestination(destinationId): Observable<any> {
    return this.apiService.delete(`/destinations/${destinationId}`);
  }

  startDestination(destinationId): Observable<any> {
    return this.apiService.get(`/destinations/resume/${destinationId}`);
  }

  stopDestination(destinationId): Observable<any> {
    return this.apiService.get(`/destinations/stop/${destinationId}`);
  }
}
