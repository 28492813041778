import { createReducer, on } from '@ngrx/store';
import * as ActivateInvitationActions from '../actions/activate-invitation.actions';

export const activateInvitationFeatureKey = 'activateInvitation';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(ActivateInvitationActions.activateInvitation, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ActivateInvitationActions.activateInvitationSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ActivateInvitationActions.activateInvitationFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ActivateInvitationActions.clearActivateInvitationState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

