import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CutVideoActions from '../actions/cut-video.actions';
import { ClipsService } from '../../../services/api/clips.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';



@Injectable()
export class CutVideoEffects {

  cutVideo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CutVideoActions.cutVideo),
      concatMap((payload) =>
        this.clipsService.cutVideo(payload.data).pipe(
          map(data => CutVideoActions.cutVideoSuccess({ data })),
          catchError(error => of(CutVideoActions.cutVideoFailure({ error }))))
      )
    );
  });

  cutVideoSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CutVideoActions.cutVideoSuccess),
      tap(() => this.snackBar.open('Clip successfully created', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/clips']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private router: Router,
              private clipsService: ClipsService) {}

}
