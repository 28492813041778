import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

type DeviceType = 'mobile' | 'desktop';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private deviceSubject$: BehaviorSubject<DeviceType>;
  private device$: Observable<DeviceType>;

  constructor() {
    this.deviceSubject$ = new BehaviorSubject('desktop' as DeviceType);
    this.device$ = this.deviceSubject$.asObservable();
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(500),
        map((event: Event) => (event.target as Window).innerWidth),
        startWith(window.innerWidth),
        map((width: number) => width <= 768 ? 'mobile' as DeviceType : 'desktop' as DeviceType),
        distinctUntilChanged()
      )
      .subscribe((device: DeviceType) => this.deviceSubject$.next(device));
  }

  getDevice(): Observable<DeviceType> {
    return this.device$;
  }
}
