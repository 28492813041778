import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInstances from '../reducers/instances.reducer';

export const selectInstancesState = createFeatureSelector<fromInstances.State>(
  fromInstances.instancesFeatureKey
);

export const getInstancesRecord = createSelector(
  selectInstancesState,
  (state) => state.record
);

export const getInstancesLoaded = createSelector(
  selectInstancesState,
  (state) => state.loaded
);

export const getInstancesLoading = createSelector(
  selectInstancesState,
  (state) => state.loading
);

export const getInstancesError = createSelector(
  selectInstancesState,
  (state) => state.error
);
