import { createReducer, on } from '@ngrx/store';
import * as DeleteOrganizationActions from '../actions/delete-organization.actions';

export const deleteOrganizationFeatureKey = 'deleteOrganization';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(DeleteOrganizationActions.deleteOrganization, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(DeleteOrganizationActions.deleteOrganizationSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(DeleteOrganizationActions.deleteOrganizationFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(DeleteOrganizationActions.clearDeleteOrganizationState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
