import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stream-url',
  template: `
    <div *ngIf="protocol === 'RTMP'">
      <div *ngIf="isEnableSecureLinkSwitcher" class="rtmp-dns">
        <mat-icon class="lock-icon" *ngIf="isShowSecureLink" color="primary" (click)="toggleSecureLinkDisplay()">https</mat-icon>
        <mat-icon class="lock-icon" *ngIf="!isShowSecureLink" color="primary" (click)="toggleSecureLinkDisplay()">lock_open</mat-icon>
        <app-copy-link [value]="url"></app-copy-link>
      </div>
      <div *ngIf="!isEnableSecureLinkSwitcher" class="rtmp-dns">
        <app-copy-link [value]="url"></app-copy-link>
      </div>
    </div>

    <!-- HLS output -->
    <ng-container *ngIf="protocol === 'HLS'">
      <app-copy-link [value]="url"></app-copy-link>
    </ng-container>

    <!-- SRT output -->
    <ng-container *ngIf="protocol === 'SRT'">
      <app-copy-link [value]="url"></app-copy-link>
    </ng-container>

    <div *ngIf="protocol === 'RTMP' && !hideStreamKey" class="rtmp-url">
      <div>Stream Key:</div>
      <app-copy-link
        class="url"
        iconPosition="none"
        [value]="streamKey"
      >
      </app-copy-link>
    </div>

    <div *ngIf="protocol === 'SRT' && srtOutputPassphrase" class="rtmp-url">
      <div>Passphrase:</div>
      <app-copy-link
        iconPosition="none"
        class="url"
        [value]="srtOutputPassphrase"
      >
      </app-copy-link>
    </div>
  `,
  styles: [
    `
      .rtmp-url {
        display: flex;
        flex-direction: row;
      }

      .url {
        margin-left: 4px;
      }

      .lock-icon {
        cursor: pointer;
        margin-right: 3px;
      }

      .rtmp-dns {
        display: flex;
        flex-direction: row;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamUrlComponent implements OnInit {
  @Input() url: string;
  @Input() streamUrl: string | undefined;
  @Input() secureStreamUrl: string | undefined;
  @Input() width = '100%';
  @Input() hideStreamKey;
  @Input() isEnableSecureLinkSwitcher;
  @Input() inputUrl;
  @Input() srtOutputPassphrase: string | null | undefined;
  public protocol: 'SRT' | 'RTMP' | 'HLS' | null = null;
  public isShowSecureLink = false;
  public streamKey: string;

  private identifyProtocol(): 'SRT' | 'RTMP' | 'HLS' | null {
    /* Check is SRT */
    const isSrt: boolean = this.url.startsWith('srt');
    if (isSrt === true) {
      return 'SRT';
    }

    /* Check is RTMP */
    const isRtmp: boolean = this.url.startsWith('rtmp');
    if (isRtmp === true) {
      return 'RTMP';
    }

    /* Check is HLS */
    const isHls: boolean = this.url.startsWith('http') && this.url.endsWith('.m3u8');
    if (isHls === true) {
      return 'HLS';
    }

    return null;
  }

  private separateStreamUrlAndStreamKey(link: string): { streamUrl: string, streamKey: string } {
    const lastSlashPosition = link.lastIndexOf('/') + 1;
    const streamUrl = link.slice(0, lastSlashPosition);
    const streamKey = link.slice(lastSlashPosition, link.length);
    return {streamUrl, streamKey};
  }

  private generateSecureUrl(): { streamUrl: string, streamKey: string | undefined } {
    const link = this.inputUrl?.ssl || this.secureStreamUrl || this.url;

    if (this.protocol === 'HLS') {
      return {
        streamUrl: link,
        streamKey: undefined
      };
    }

    if (this.protocol === 'RTMP') {
      return this.separateStreamUrlAndStreamKey(link);
    }
  }

  private generateUnsecureUrl(): { streamUrl: string, streamKey: string | undefined } {
    const link = this.inputUrl?.dns || this.streamUrl || this.url;

    switch (this.protocol) {
      case 'SRT':
      case 'HLS':
        return {
          streamUrl: link,
          streamKey: undefined
        };
      case 'RTMP':
        return this.separateStreamUrlAndStreamKey(link);
      default:
        console.error('Unknown protocol', this.protocol);
        return {
          streamUrl: link,
          streamKey: undefined
        };
    }
  }

  private getUrlValue(): { streamUrl: string, streamKey: string | undefined } {
    if (this.protocol === 'HLS') {
      return this.generateSecureUrl();
    }

    if (this.isShowSecureLink === true) {
      return this.generateSecureUrl();
    }

    if (this.isShowSecureLink === false) {
      return this.generateUnsecureUrl();
    }
  }

  private setUrl(): void {
    const url: { streamUrl: string, streamKey: string | undefined } = this.getUrlValue();
    this.url = url.streamUrl;
    this.streamKey = url.streamKey;
  }

  public toggleSecureLinkDisplay(): void {
    this.isShowSecureLink = !this.isShowSecureLink;
    this.setUrl();
  }

  public ngOnInit(): void {
    if (this.streamUrl) {
      this.url = this.streamUrl.toLowerCase();
    }
    this.protocol = this.identifyProtocol();
    this.setUrl();
  }
}
