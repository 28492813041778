import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UpdateOrganizationActions from '../actions/update-organization.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';
import { Router } from '@angular/router';



@Injectable()
export class UpdateOrganizationEffects {

  updateOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateOrganizationActions.updateOrganization),
      concatMap((payload) =>
        this.organizationsService.updateOrganization(payload.data).pipe(
          map(data => UpdateOrganizationActions.updateOrganizationSuccess({ data })),
          catchError(error => of(UpdateOrganizationActions.updateOrganizationFailure({ error }))))
      )
    );
  });

  updateOrganizationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateOrganizationActions.updateOrganizationSuccess),
      tap(() => {
        this.snackBar.open('Organization successfully updated', 'Success', {duration: 3000});
        if (this.router.url.includes('/organizations/')) {
          this.router.navigate(['/organizations']);
        } else {
          this.router.navigate(['/organization']);
        }
      }),
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService,
              private router: Router,
              private snackBar: MatSnackBar) {}

}
