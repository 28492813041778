import { createAction, props } from '@ngrx/store';
import {DeleteDvrRecordPayload} from '../../../services/api/dvr/interfaces/delete.dvr-record.payload.interface';

export const deleteRecord = createAction(
  '[DeleteRecord] Delete Record',
  props<{ data: DeleteDvrRecordPayload }>()
);

export const deleteRecordSuccess = createAction(
  '[DeleteRecord] Load DeleteRecords Success',
  props<{ data: any }>()
);

export const deleteRecordFailure = createAction(
  '[DeleteRecord] Delete Record Failure',
  props<{ error: any }>()
);

export const clearDeleteRecordState = createAction(
  '[DeleteRecord] clear Delete Record State',
);
