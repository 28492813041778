import { Flag } from './flags.dictionary';
import { Element } from '../interfaces/element.interface';

export const ELEMENTS: Element[] = [
  {value: 'a', flags: [Flag.VOWEL]},
  {value: 'ae', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'ah', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'ai', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'b', flags: [Flag.CONSONANT]},
  {value: 'c', flags: [Flag.CONSONANT]},
  {value: 'ch', flags: [Flag.CONSONANT, Flag.DIPTHONG]},
  {value: 'd', flags: [Flag.CONSONANT]},
  {value: 'e', flags: [Flag.VOWEL]},
  {value: 'ee', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'ei', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'f', flags: [Flag.CONSONANT]},
  {value: 'g', flags: [Flag.CONSONANT]},
  {value: 'gh', flags: [Flag.CONSONANT, Flag.DIPTHONG, Flag.NOT_FIRST]},
  {value: 'h', flags: [Flag.CONSONANT]},
  {value: 'i', flags: [Flag.VOWEL]},
  {value: 'ie', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'j', flags: [Flag.CONSONANT]},
  {value: 'k', flags: [Flag.CONSONANT]},
  {value: 'l', flags: [Flag.CONSONANT]},
  {value: 'm', flags: [Flag.CONSONANT]},
  {value: 'n', flags: [Flag.CONSONANT]},
  {value: 'ng', flags: [Flag.CONSONANT, Flag.DIPTHONG, Flag.NOT_FIRST]},
  {value: 'o', flags: [Flag.VOWEL]},
  {value: 'oh', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'oo', flags: [Flag.VOWEL, Flag.DIPTHONG]},
  {value: 'p', flags: [Flag.CONSONANT]},
  {value: 'ph', flags: [Flag.CONSONANT, Flag.DIPTHONG]},
  {value: 'qu', flags: [Flag.CONSONANT, Flag.DIPTHONG]},
  {value: 'r', flags: [Flag.CONSONANT]},
  {value: 's', flags: [Flag.CONSONANT]},
  {value: 'sh', flags: [Flag.CONSONANT, Flag.DIPTHONG]},
  {value: 't', flags: [Flag.CONSONANT]},
  {value: 'th', flags: [Flag.CONSONANT, Flag.DIPTHONG]},
  {value: 'u', flags: [Flag.VOWEL]},
  {value: 'v', flags: [Flag.CONSONANT]},
  {value: 'w', flags: [Flag.CONSONANT]},
  {value: 'x', flags: [Flag.CONSONANT]},
  {value: 'y', flags: [Flag.CONSONANT]},
  {value: 'z', flags: [Flag.CONSONANT]}
];
