import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCreateGrid from '../reducers/create-grid.reducer';

export const selectCreateGridState = createFeatureSelector<fromCreateGrid.State>(
  fromCreateGrid.createGridFeatureKey
);

export const getCreateGridRecord = createSelector(
  selectCreateGridState,
  (state) => state.record
);

export const getCreateGridLoaded = createSelector(
  selectCreateGridState,
  (state) => state.loaded
);

export const getCreateGridLoading = createSelector(
  selectCreateGridState,
  (state) => state.loading
);

export const getCreateGridError = createSelector(
  selectCreateGridState,
  (state) => state.error
);
