import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import {ProductionInstanceStatus} from '../../../../../services/api/production-instances/production.instance.status.type';

@Component({
  selector: 'app-production-status',
  templateUrl: './production-status.component.html',
  styleUrls: ['./production-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionStatusComponent {
  @Input() status: ProductionInstanceStatus;
}
