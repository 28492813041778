import { createReducer, on } from '@ngrx/store';
import * as BillingPeriodsActions from '../actions/billing-periods.actions';

export const billingPeriodsFeatureKey = 'billingPeriods';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(BillingPeriodsActions.loadBillingPeriods, (state) => ({
    record: state.record,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(BillingPeriodsActions.loadBillingPeriodsSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(BillingPeriodsActions.loadBillingPeriodsFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(BillingPeriodsActions.clearBillingPeriodsState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
