import {Component, ChangeDetectionStrategy, Input, OnInit} from '@angular/core';
import {DestinationOption} from '../../../models/destination.interface';

@Component({
  selector: 'app-destination-url',
  templateUrl: './destination-url.component.html',
  styleUrls: ['./destination-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationUrlComponent implements OnInit {
  @Input() data: DestinationOption | undefined;
  @Input() url: string | undefined;
  @Input() width: string;
  private parseUrl(): string {
    if (this.url) {
      return this.url;
    }

    if (this.data) {
      const protocol: string = this.data.protocol.toLowerCase();
      if (protocol === 'srt') {
        return `${protocol}://${this.data.address}:${this.data.port}`;
      }

      return `${protocol}://${this.data.address}:${this.data.port}/${this.data.application}/${this.data.stream}`;
    }

    return '';
  }

  public ngOnInit(): void {
    this.url = this.parseUrl();
  }
}
