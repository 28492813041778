import { createReducer, on } from '@ngrx/store';
import * as RtmpAuthSchemeActions from '../actions/rtmp-auth-scheme.actions';
import {RtmpAuthScheme} from '../../../services/api/rtmp-auth-schemes/interfaces/rtmp-auth-scheme.interface';

export const rtmpAuthSchemeFeatureKey = 'rtmpAuthScheme';

export interface State {
  record: RtmpAuthScheme[] | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(RtmpAuthSchemeActions.loadRtmpAuthScheme, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(RtmpAuthSchemeActions.loadRtmpAuthSchemeSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(RtmpAuthSchemeActions.loadRtmpAuthSchemeFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(RtmpAuthSchemeActions.clearRtmpAuthSchemeState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
