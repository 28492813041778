import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Destination} from '../../../../../models/destination.interface';
import {FormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SelectedEntity} from '../entity-select-form/entity-select-form.component';

@Component({
  selector: 'app-destination-select',
  templateUrl: './destination-select.component.html',
  styleUrls: ['./destination-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationSelectComponent implements OnChanges {
  @Input() entityControl: any;
  @Input() entity: any;
  @Input() index: number;
  @Input() validationRequired: boolean;
  @Input() destinations: Destination[];
  @Input() filterdDestinationsId;
  @Input() lableForUsedDestination;
  @Output() public changeEntity: EventEmitter<SelectedEntity> = new EventEmitter<SelectedEntity>();
  @Output() mouseEnter: EventEmitter<any> = new EventEmitter();
  showDestiantionDetails = false;
  filteredOptions: Observable<Destination[]>;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['entityControl'] && this.entityControl) {
      this.entityControl.addControl('type', new FormControl('destination'));
      this.entityControl.addControl('order', new FormControl(this.index));
      this.entityControl.addControl('entityId', new FormControl(''));
      this.entityControl.addControl('currentEntity', new FormControl(''));
      this.setValidators();
    }

    if (this.destinations) {
      this.filteredOptions = this.entityControl.get('currentEntity').valueChanges
        .pipe(
          startWith(''),
          map((value: any) => {
            if (!value) {
              return '';
            }

            const isString: boolean = typeof value === 'string';
            if (isString) {
              return value;
            }

            return value.name;
          }),
          map((name: string): Destination[] => name ? this._filter(name) : this.destinations.slice())
        );
    }

    if ((changes['entity'] || changes['destinations']) && this.entity && this.destinations) {
      const savedEntity = this.destinations.filter(input => input.id === this.entity.entityId)[0];
      this.entityControl.get('currentEntity').setValue(savedEntity);
      if (savedEntity) {
        this.onSelectDestination();
      }
    }
  }

  setValidators() {
    if (this.validationRequired) {
      this.entityControl.get('currentEntity').setValidators([Validators.required]);
    }
  }

  onTypingDestination() {
    this.entityControl.controls['currentEntity'].setErrors({incorrect: true});
    this.showDestiantionDetails = false;
  }

  onCancelIconClick(event: Event) {
    event.stopPropagation();
    this.entityControl.get('entityId').setValue('');
    this.entityControl.get('currentEntity').setValue('');
    this.showDestiantionDetails = false;
  }

  public onSelectDestination(): void {
    const currentEntity = this.entityControl.controls['currentEntity'].value;
    this.entityControl.get('entityId').setValue(currentEntity.id);
    this.entityControl.controls['currentEntity'].setErrors(null);
    this.showDestiantionDetails = true;
    this.emitChangeEntity();
  }

  displayFn(destination: Destination): string {
    return destination && destination.name ? destination.name : '';
  }

  private _filter(name: string): Destination[] {
    const filterValue: string = name.toLowerCase();

    return this.destinations.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private emitChangeEntity(): void {
    const currentEntity = this.entityControl.controls['currentEntity'].value;
    const entityId: string = currentEntity.id;
    const inputId: string = currentEntity.inputId;
    const clusterId: string = currentEntity.clusterId;
    const instanceRole = currentEntity.role;
    const selectedEntity: SelectedEntity = {
      type: 'destination',
      entityId: entityId,
      inputId: inputId,
      clusterId: clusterId,
      instanceRole: instanceRole
    };
    this.changeEntity.emit(selectedEntity);
  }
}
