import { createReducer, on } from '@ngrx/store';
import * as UpdateClusterActions from '../actions/update-cluster.actions';

export const updateClusterFeatureKey = 'updateCluster';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(UpdateClusterActions.updateCluster, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(UpdateClusterActions.updateClusterSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(UpdateClusterActions.updateClusterFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(UpdateClusterActions.clearUpdateClusterrState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

