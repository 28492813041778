import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInputUpdate from '../reducers/input-update.reducer';

export const selectInputUpdateState = createFeatureSelector<fromInputUpdate.State>(
  fromInputUpdate.inputUpdateFeatureKey
);
export const getInputUpdateRecord = createSelector(
  selectInputUpdateState,
  (state) => state.record
);

export const getInputUpdateLoaded = createSelector(
  selectInputUpdateState,
  (state) => state.loaded
);

export const getInputUpdateLoading = createSelector(
  selectInputUpdateState,
  (state) => state.loading
);

export const getInputUpdateError = createSelector(
  selectInputUpdateState,
  (state) => state.error
);
