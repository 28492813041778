import {createAction, props} from '@ngrx/store';
import {DestinationStatusUpdate} from '../../../services/api/destinations/interfaces/destination-status.update.interface';

export const updateDestinationsStatuses = createAction(
  '[Destinations Statuses] Update Destinations Statuses',
  props<{ data: DestinationStatusUpdate[] }>()
);

export const updateDestinationsStatusesSuccess = createAction(
  '[Destinations Statuses] Update Destinations Statuses Success',
  props<{ data: DestinationStatusUpdate[] }>()
);
