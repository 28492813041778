import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StatisticsApiService} from './statistics.api.service';
import {TranscodingScenariosStatisticsService} from './statistics/transcoding-scenarios/transcoding-scenarios.statistics.service';
import {Time} from './statistics/types/time.statistics.parameter.type';
import {ClusterID} from './statistics/types/cluster-id.statistics.parameter.type';
import {TranscodingScenariosStatistic} from './statistics/transcoding-scenarios/transcoding-scenarios.statistic.model';
import {StoragesStatisticsService} from './statistics/storages/storages.statistics.service';
import {StorageStatistics} from './statistics/storages/storages.statistic.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private readonly statisticsApiService: StatisticsApiService,
    private readonly transcodingScenarios: TranscodingScenariosStatisticsService,
    private readonly storages: StoragesStatisticsService
  ) {
  }

  getIncomeBandwidth(time, clusterId): Observable<any> {
    return this.statisticsApiService.get(`/income-bandwidth`, {time, clusterId});
  }

  getOutcomeBandwidth(time, clusterId): Observable<any> {
    return this.statisticsApiService.get(`/outcome-bandwidth`, {time, clusterId});
  }

  public getTranscodingScenarios(time: Time, clusterId: ClusterID): Observable<TranscodingScenariosStatistic> {
    return this.transcodingScenarios.get(time, clusterId);
  }

  public getStorage(time: Time, clusterId: ClusterID): Observable<StorageStatistics> {
    return this.storages.get(time, clusterId);
  }
}
