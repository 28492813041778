import { createReducer, on } from '@ngrx/store';
import * as VmixServerStatusActions from '../actions/vmix-server-status.actions';

export const vmixServerStatusFeatureKey = 'vmixServerStatus';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(VmixServerStatusActions.loadVmixServerStatus, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(VmixServerStatusActions.loadVmixServerStatusSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(VmixServerStatusActions.loadVmixServerStatusFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(VmixServerStatusActions.clearVmixServerStatusState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

