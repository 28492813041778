import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCutVideo from '../reducers/cut-video.reducer';

export const selectCutVideoState = createFeatureSelector<fromCutVideo.State>(
  fromCutVideo.cutVideoFeatureKey
);

export const getCutVideoRecord = createSelector(
  selectCutVideoState,
  (state) => state.record
);

export const getCutVideoLoaded = createSelector(
  selectCutVideoState,
  (state) => state.loaded
);

export const getCutVideoLoading = createSelector(
  selectCutVideoState,
  (state) => state.loading
);

export const getCutVideoError = createSelector(
  selectCutVideoState,
  (state) => state.error
);
