import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUpdateCluster from '../reducers/update-cluster.reducer';

export const selectUpdateClusterState = createFeatureSelector<fromUpdateCluster.State>(
  fromUpdateCluster.updateClusterFeatureKey
);

export const getUpdateClusterRecord = createSelector(
  selectUpdateClusterState,
  (state) => state.record
);

export const getUpdateClusterLoaded = createSelector(
  selectUpdateClusterState,
  (state) => state.loaded
);

export const getUpdateClusterLoading = createSelector(
  selectUpdateClusterState,
  (state) => state.loading
);

export const getUpdateClusterError = createSelector(
  selectUpdateClusterState,
  (state) => state.error
);
