import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCurrentUser from '../../store/users/reducers/current-user.reducer';
import { getCurrentUserRecord } from '../../store/users/selectors/current-user.selectors';
import { Subscription } from 'rxjs';
import { User } from '../../models/user.interface';

@Directive({
  selector: '[appLicenceFilter]'
})
export class LicenceFilterDirective implements OnDestroy, AfterViewInit {
  @Input() licensesId: number[];
  currentUser: User;
  userSubscription: Subscription;

  constructor(private elementRef: ElementRef,
              private store: Store<fromCurrentUser.State>) {
    this.userSubscription = this.store.select(getCurrentUserRecord).subscribe(
      user => this.currentUser = user
    );
  }

  ngAfterViewInit(): void {
    if (!this.licensesId.includes(this.currentUser.currentOrganization.licence_id)) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
