import { createAction, props } from '@ngrx/store';
import {Production} from '../../../models/production.interface';

export const loadVmixServers = createAction(
  '[VmixServers] Load Vmix Servers'
);

export const loadVmixServersSuccess = createAction(
  '[VmixServers] Load Vmix Servers Success',
  props<{ data: Production[] }>()
);

export const updateProductionInstanceSuccess = createAction(
  '[VmixServers] Update Production Instance Success',
  props<{ data: Production }>()
);

export const deleteProductionInstanceSuccess = createAction(
  '[VmixServers] Delete Production Instance Success',
  props<{ data: string }>()
);

export const loadVmixServersFailure = createAction(
  '[VmixServers] Load Vmix Servers Failure',
  props<{ error: any }>()
);

export const clearVmixServersState = createAction(
  '[VmixServers] Clear Vmix Servers State',
);
