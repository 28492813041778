import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as BillingPeriodsActions from '../actions/billing-periods.actions';
import { StatisticsService } from '../../../services/api/statistics.service';



@Injectable()
export class BillingPeriodsEffects {

  loadBillingPeriods$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BillingPeriodsActions.loadBillingPeriods),
      concatMap(() =>
        this.statisticsService.getBillingPeriods().pipe(
          map(data => BillingPeriodsActions.loadBillingPeriodsSuccess({ data })),
          catchError(error => of(BillingPeriodsActions.loadBillingPeriodsFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private statisticsService: StatisticsService) {}

}
