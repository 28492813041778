import { createReducer, on } from '@ngrx/store';
import * as StorageActions from '../actions/storage.actions';
import {StorageStatistics} from '../../../services/api/statistics/storages/storages.statistic.model';

export const storageFeatureKey = 'storage';

export interface State {
  record: StorageStatistics | null;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(StorageActions.loadStorage, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(StorageActions.loadStorageSuccess, (state: State, action: {data: StorageStatistics}) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(StorageActions.loadStorageFailure, (state: State, action: {error: any}) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(StorageActions.clearStorageState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
