import { createReducer, on } from '@ngrx/store';
import * as MonitoringClusterActions from '../actions/monitoring-cluster.actions';

export const monitoringClusterFeatureKey = 'monitoringCluster';

export interface State {
  clusterId: string;
}

export const initialState: State = {
  clusterId: 'all',
};

export const reducer = createReducer(
  initialState,

  on(MonitoringClusterActions.setMonitoringCluster, (state, action) => ({
    clusterId: action.data,
  })),
  on(MonitoringClusterActions.clearMonitoringClusterState, () => ({
    clusterId: 'all',
  })),
);
