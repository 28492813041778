import {ActionReducer, createReducer, on} from '@ngrx/store';
import { TranscodersSettings } from '../../../models/dashboard.interface';
import * as TranscodersSettingsActions from '../actions/transcoders-settings.actions';

export const transcodersSettingsFeatureKey = 'transcodersSettings';

export interface State {
  settings: TranscodersSettings;
}

export const initialState: State = {
  settings: {
    ALL: true,
    NDI_INPUT: false,
    NDI_OUTPUT: false,
    SLDP: false,
    DESTINATION: false,
  },
};

export const reducer: ActionReducer<State> = createReducer(
  initialState,

  on(TranscodersSettingsActions.setTranscodersSettings, (state: State, action: {data: TranscodersSettings}): {settings: TranscodersSettings} => ({
    settings: action.data,
  })),
  on(TranscodersSettingsActions.clearTranscodersSettingsState, (): {settings: TranscodersSettings} => ({
    settings: {
      ALL: true,
      NDI_INPUT: false,
      NDI_OUTPUT: false,
      SLDP: false,
      DESTINATION: false,
    },
  })),
);
