import { createAction, props } from '@ngrx/store';

export const stopDestination = createAction(
  '[StopDestination] Load Stop Destination',
  props<{ data: any }>()
);

export const stopDestinationSuccess = createAction(
  '[StopDestination] Load Stop Destination Success',
  props<{ data: any }>()
);

export const stopDestinationFailure = createAction(
  '[StopDestination] Load Stop Destination Failure',
  props<{ error: any }>()
);

export const clearStopDestinationState = createAction(
  '[StopDestination] clear Stop Destination State',
);
