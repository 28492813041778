import { createAction, props } from '@ngrx/store';

export const loadRtmpAuthScheme = createAction(
  '[RtmpAuthScheme] Load RtmpAuthScheme'
);

export const loadRtmpAuthSchemeSuccess = createAction(
  '[RtmpAuthScheme] Load RtmpAuthScheme Success',
  props<{ data: any }>()
);

export const loadRtmpAuthSchemeFailure = createAction(
  '[RtmpAuthScheme] Load RtmpAuthScheme Failure',
  props<{ error: any }>()
);

export const clearRtmpAuthSchemeState = createAction(
  '[RtmpAuthScheme] clear RtmpAuthScheme State',
);
