import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClusterStatus} from '../../../services/api/clusters/types/cluster.statuses.type';

export interface StatusConfiguration {
  status: string;
  tooltip: string;
  color: string;
  isFlickering: boolean;
}

export type ColorName = 'red' | 'yellow' | 'green' | 'grey';

export const colors: { [color in ColorName]: string } = {
  red: '#ED323A',
  yellow: '#DEC24D',
  green: '#3BC458',
  grey: '#A7A7AE'
};

const clusterColors: { [status in ClusterStatus]: string } = {
  active: colors.green,
  stopped: colors.grey,
  deleted: colors.grey,
  creating: colors.yellow,
  deleting: colors.yellow,
  starting: colors.yellow,
  stopping: colors.yellow,
  failed: colors.red
};

const clusterTooltips: { [status in ClusterStatus]: string } = {
  active: 'Active',
  stopped: 'Stopped',
  deleted: 'Deleted',
  creating: 'Creating',
  deleting: 'Deleting',
  starting: 'Starting',
  stopping: 'Stopping',
  failed: 'Failed'
};

const persistentIpColors: { [status in 'persistedIpEnabled' | 'persistedIpDisabled']: string } = {
  persistedIpEnabled: colors.green,
  persistedIpDisabled: colors.grey
};

const persistentIpTooltips: { [status in 'persistedIpEnabled' | 'persistedIpDisabled']: string } = {
  persistedIpEnabled: 'Enabled',
  persistedIpDisabled: 'Disabled'
};

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent implements OnInit, OnChanges {
  @Input() status: string;
  @Input() tooltip: string = undefined;
  public tooltipText: string;
  public isFlickeringDot = false;
  public color: string = colors.grey;

  colors = {
    deleted: colors.red,
    failed: colors.red,
    disabled: colors.red,
    progress: colors.red,
    waiting: colors.yellow,
    creating: colors.yellow,
    deleting: colors.yellow,
    complete: colors.green,
    active: colors.green,
    banned: colors.yellow,
    transcodingOn: colors.green,
    transcodingOff: colors.grey,

    failedDVR: colors.red,
    waitingDVR: colors.yellow,
    startingDVR: colors.yellow,
    recordingDVR: colors.red,
    stoppingDVR: colors.yellow,
    completeDVR: colors.green,
    completedDVR: colors.green,

    activeOrg: colors.green,
    suspendedOrg: colors.red,

    all: colors.green,
    some: colors.yellow,
    none: colors.grey,

    online: colors.green,
    offline: colors.red,

    launching: colors.yellow,
    available: colors.green,
    error: colors.red,
    'pending-associate': colors.yellow,

    ...clusterColors,
    ...persistentIpColors
  };

  private tooltips = {
    deleted: 'Deleted',
    failed: 'Failed',
    disabled: 'Disabled',
    progress: 'In progress',
    waiting: 'Waiting',
    creating: 'Creating',
    deleting: 'Deleting',
    complete: 'Completed',
    active: 'Active',
    banned: 'Banned',

    failedDVR: 'Record failed',
    waitingDVR: 'Waiting for incoming stream',
    startingDVR: 'Record starting',
    recordingDVR: 'Recording',
    stoppingDVR: 'Record completing',
    completedDVR: 'Completed',
    completeDVR: 'Completed',

    activeOrg: 'Active',
    suspendedOrg: 'Suspended',

    transcodingOn: 'On',
    transcodingOff: 'Off',

    launching: 'Launching',
    available: 'Available',
    error: 'Error',
    'pending-associate': 'Pending Associate',
    'no-associate': 'No Associate',

    ...clusterTooltips,
    ...persistentIpTooltips
  };

  public setColor(): void {
    this.color = this.colors[this.status];
  }

  public setIsFlickeringDot(): void {
    this.isFlickeringDot =
      this.status === 'waitingDVR' ||
      this.status === 'startingDVR' ||
      this.status === 'recordingDVR' ||
      this.status === 'stoppingDVR' ||
      this.status === 'launching' ||
      this.status === 'pending-associate';
  }

  public ngOnInit(): void {
    this.setTooltipText();
    this.setColor();
    this.setIsFlickeringDot();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['tooltip']) {
      this.setTooltipText();
    }
    if (changes['status']) {
      this.setTooltipText();
      this.setColor();
      this.setIsFlickeringDot();
    }
  }

  private setTooltipText(): void {
    if (this.tooltip) {
      this.tooltipText = this.tooltip;
    } else {
      this.tooltipText = this.tooltips[this.status];
    }
  }
}
