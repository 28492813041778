import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UpdateClusterActions from '../actions/update-cluster.actions';
import { ClustersService } from '../../../services/api/clusters.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class UpdateClusterEffects {

  updateCluster$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateClusterActions.updateCluster),
      concatMap((payload) =>
        this.clustersService.updateCluster(payload.data).pipe(
          map(data => UpdateClusterActions.updateClusterSuccess({ data })),
          catchError(error => of(UpdateClusterActions.updateClusterFailure({ error }))))
      )
    );
  });

  updateClusterSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateClusterActions.updateClusterSuccess),
      tap(() => this.snackBar.open('Cluster successfully updated', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/clusters']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private clustersService: ClustersService,
              private router: Router,
              private snackBar: MatSnackBar) {}

}
