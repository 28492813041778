import { createAction, props } from '@ngrx/store';

export const updateOrganization = createAction(
  '[UpdateOrganization] Update Organization',
  props<{ data: any }>()
);

export const updateOrganizationSuccess = createAction(
  '[UpdateOrganization] Update Organization Success',
  props<{ data: any }>()
);

export const updateOrganizationFailure = createAction(
  '[UpdateOrganization] Update Organization Failure',
  props<{ error: any }>()
);

export const clearUpdateOrganizationState = createAction(
  '[UpdateOrganization] Clear Update Organization State',
);
