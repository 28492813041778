import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DeleteGridActions from '../actions/delete-grid.actions';
import { GridsService } from '../../../services/api/grids.service';
import * as GridsActions from '../../grids/actions/grids.actions';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class DeleteGridEffects {

  deleteGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteGridActions.deleteGrid),
      concatMap((payload) =>
        this.gridsService.deleteGrid(payload.data).pipe(
          map(data => DeleteGridActions.deleteGridSuccess({ data })),
          catchError(error => of(DeleteGridActions.deleteGridFailure({ error }))))
      )
    );
  });

  deleteGridSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteGridActions.deleteGridSuccess),
      tap(() => this.snackBar.open('Grid successfully deleted', 'Success', {duration: 3000})),
      map(() => GridsActions.loadGrids()),
    );
  });


  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private gridsService: GridsService) {}

}
