import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeleteVmixServer from '../reducers/delete-vmix-server.reducer';

export const selectDeleteVmixServerState = createFeatureSelector<fromDeleteVmixServer.State>(
  fromDeleteVmixServer.deleteVmixServerFeatureKey
);

export const getDeleteVmixServerRecord = createSelector(
  selectDeleteVmixServerState,
  (state) => state.record
);

export const getDeleteVmixServerLoaded = createSelector(
  selectDeleteVmixServerState,
  (state) => state.loaded
);

export const getDeleteVmixServerLoading = createSelector(
  selectDeleteVmixServerState,
  (state) => state.loading
);

export const getDeleteVmixServerError = createSelector(
  selectDeleteVmixServerState,
  (state) => state.error
);
