import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as StatisticsActions from '../actions/statistics.actions';
import { StatisticsService } from '../../../services/api/statistics.service';



@Injectable()
export class StatisticsEffects {

  loadStatistics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StatisticsActions.loadStatistics),
      concatMap((payload) =>
        this.statisticsService.getStatistics(payload.data).pipe(
          map(data => StatisticsActions.loadStatisticsSuccess({ data })),
          catchError(error => of(StatisticsActions.loadStatisticsFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private statisticsService: StatisticsService) {}

}
