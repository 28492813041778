import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAllInstanceTypes from '../reducers/all-instance-types.reducer';

export const selectAllInstanceTypesState = createFeatureSelector<fromAllInstanceTypes.State>(
  fromAllInstanceTypes.allInstanceTypesFeatureKey
);

export const getAllInstanceTypesRecord = createSelector(
  selectAllInstanceTypesState,
  (state) => state.record
);

export const getAllInstanceTypesLoaded = createSelector(
  selectAllInstanceTypesState,
  (state) => state.loaded
);

export const getAllInstanceTypesLoading = createSelector(
  selectAllInstanceTypesState,
  (state) => state.loading
);

export const getAllInstanceTypesError = createSelector(
  selectAllInstanceTypesState,
  (state) => state.error
);
