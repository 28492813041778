import { createAction, props } from '@ngrx/store';

export const setChartTime = createAction(
  '[ChartTime] set Chart Time',
  props<{ data: any }>()
);

export const clearChartTimeState = createAction(
  '[ChartTime] clear Chart Time State',
);
