import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOrganizationLicenses from '../reducers/organization-licenses.reducer';

export const selectOrganizationLicensesState = createFeatureSelector<fromOrganizationLicenses.State>(
  fromOrganizationLicenses.organizationLicensesFeatureKey
);

export const getOrganizationLicensesRecord = createSelector(
  selectOrganizationLicensesState,
  (state) => state.record
);

export const getOrganizationLicensesLoaded = createSelector(
  selectOrganizationLicensesState,
  (state) => state.loaded
);

export const getOrganizationLicensesLoading = createSelector(
  selectOrganizationLicensesState,
  (state) => state.loading
);

export const getOrganizationLicensesError = createSelector(
  selectOrganizationLicensesState,
  (state) => state.error
);
