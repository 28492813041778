import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-dvr-status',
  templateUrl: './dvr-status.component.html',
  styleUrls: ['./dvr-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DvrStatusComponent {
  @Input() status: string;

  colors = {
    failed: '#ED323A',
    waiting: '#DEC24D',
    starting: '#DEC24D',
    recording: '#ED323A',
    stopping: '#DEC24D',
    complete: '#3BC458',
    completed: '#3BC458',
    deleting: '#DEC24D',
  };

  tooltips = {
    failed: 'Record failed',
    waiting: 'Waiting for incoming stream',
    starting: 'Recording Starting',
    recording: 'Recording',
    stopping: 'Record Completing',
    completed: 'Completed',
    deleting: 'Record Deleting',
  };
}
