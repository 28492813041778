import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ClipsService {

  constructor(
    private readonly apiService: ApiService
  ) {}

  public getClips(): Observable<any> {
    return this.apiService.get(`/clip-post/trim-processes`);
  }

  cutVideo(data: any): Observable<any> {
    return this.apiService.post('/clip-post/cut-video', data);
  }

  updateClip({data, clipId}): Observable<any> {
    return this.apiService.put(`/clip-post/update-process?process_id=${clipId}`, data);
  }

  public deleteClip(clipId: number): Observable<any> {
    return this.apiService.get(`/clip-post/delete-process?process_id=${clipId}`);
  }

  public getClipSignedLink(clipId: string): Observable<any> {
    return this.apiService.get(`/clip-post/link/${clipId}`);
  }
}
