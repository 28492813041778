import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {StartDvrRecordPayload} from './interfaces/start-dvr-record-payload.interface';
import { map } from 'rxjs/operators';
import {DeleteDvrRecordPayload} from './interfaces/delete.dvr-record.payload.interface';

@Injectable({
  providedIn: 'root'
})
export class DvrService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  getRecords(): Observable<any> {
    return this.apiService.get(`/dvr`);
  }

  startRecord(payload: StartDvrRecordPayload) {
    return this.apiService.post(`/dvr/start/`, payload);
  }

  stopRecord(recordId) {
    return this.apiService.get(`/dvr/stop/${recordId}`);
  }

  public deleteRecord(data: DeleteDvrRecordPayload) {
    return this.apiService.delete(`/dvr/${data.recordId}`, {shouldRemoveFile: data.shouldRemoveFile});
  }

  public getDownloadRecordLink(recordId: string): Observable<string> {
    return this.apiService.get(`/dvr/download/${recordId}`)
      .pipe(map((response: any) => response.data));
  }

  public getRecordLink(recordId: string): Observable<any> {
    return this.apiService.get(`/dvr/link/${recordId}`);
  }
}
