import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UpdateClipActions from '../actions/update-clip.actions';
import { ClipsService } from '../../../services/api/clips.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';



@Injectable()
export class UpdateClipEffects {

  updateClip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateClipActions.updateClip),
      concatMap((payload) =>
        this.clipsService.updateClip(payload.data).pipe(
          map(data => UpdateClipActions.updateClipSuccess({ data })),
          catchError(error => of(UpdateClipActions.updateClipFailure({ error }))))
      )
    );
  });

  updateClipSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateClipActions.updateClipSuccess),
      tap(() => this.snackBar.open('Clip successfully updated', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/clips']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private router: Router,
              private clipsService: ClipsService) {}

}
