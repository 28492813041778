import { createReducer, on } from '@ngrx/store';
import * as UserInviteActions from '../actions/user-invite.actions';

export const userInviteFeatureKey = 'userInvite';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(UserInviteActions.sendUserInvite, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(UserInviteActions.sendUserInviteSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(UserInviteActions.sendUserInviteFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(UserInviteActions.clearUserInviteState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
