import {ApiService} from '../api.service';
import {Observable} from 'rxjs';
import {ProductionInstancePayload} from './production-instance.payload.model';
import {Injectable} from '@angular/core';
import {ProductionInstanceCreate} from './production.instance.create.model';
import {map} from 'rxjs/operators';
import {Production} from '../../../models/production.interface';

@Injectable({providedIn: 'root'})
export class ProductionInstancesService {
  constructor(private apiService: ApiService) {}

  private static payloadToModel(instance: ProductionInstancePayload): Production {
    return {
      id: instance.uuid,
      name: instance.name,
      clusterId: instance.clusterId,
      instanceType: null,
      instanceTypeId: instance.instanceTypeId,
      username: 'Administrator',
      password: instance.password,
      createdAt: instance.createdAt,
      hostname: instance.dnsName,
      privateIp: instance.privateIpAddress,
      status: instance.status,
      errorMessage: instance.errorMessage
    };
  }

  public getProductionInstances(): Observable<Production[]> {
    return this.apiService.get<ProductionInstancePayload[]>('/production-instances')
      .pipe(
        map(payload => payload.map(instance => ProductionInstancesService.payloadToModel(instance))),
      );
  }

  public create(data: ProductionInstanceCreate): Observable<Production> {
    return this.apiService.post<ProductionInstancePayload>(`/production-instances`, data)
      .pipe(
        map(payload => ProductionInstancesService.payloadToModel(payload)),
      );
  }

  public stop(id: string): Observable<'OK'> {
    return this.apiService.get<'OK'>(`/production-instances/${id}/stop`);
  }

  public start(id: string): Observable<'OK'> {
    return this.apiService.get<'OK'>(`/production-instances/${id}/start`);
  }

  public retry(id: string): Observable<'OK'> {
    return this.apiService.put<'OK'>(`/production-instances/${id}/retry`);
  }

  public delete(id: string): Observable<'OK'> {
    return this.apiService.delete<'OK'>(`/production-instances/${id}`);
  }

}
