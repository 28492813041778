import { createAction, props } from '@ngrx/store';

export const loadClips = createAction(
  '[Clips] Load Clips'
);

export const loadClipsSuccess = createAction(
  '[Clips] Load Clips Success',
  props<{ data: any }>()
);

export const loadClipsFailure = createAction(
  '[Clips] Load Clips Failure',
  props<{ error: any }>()
);

export const updateClipStatus = createAction(
  '[Clips] Update Clips Status',
  props<{ data: any }>()
);

export const clearClipsState = createAction(
  '[Clips] clear Clips State',
);
