import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { User } from '../../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private apiService: ApiService) {}

  getUser(): Observable<any> {
    return this.apiService.get(`/user`);
  }

  getUserById({userId, organizationId}): Observable<any> {
    return this.apiService.get(`/user/${organizationId}/user/${userId}`);
  }

  getUsers(): Observable<any> {
    return this.apiService.get(`/users`);
  }

  updateUser(user: User): Observable<any> {
    return this.apiService.put(`/user/${user.id}`, user);
  }

  createUser(user: User): Observable<any> {
    return this.apiService.post(`/user`, user);
  }

  deleteUser({userId, organizationId}): Observable<any> {
    return this.apiService.delete(`/user/${organizationId}/user/${userId}`);
  }

  getUsersRoles(): Observable<any> {
    return this.apiService.get(`/roles`);
  }
}
