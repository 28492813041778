import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-required-field',
  template: `
    <span *ngIf="isRequiredField()"> </span>
  `,
  styles: [
    `
      span {
        color: #ED323A;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IsRequiredFieldComponent {
  @Input() validator: any;

  isRequiredField() {
    if (!this.validator) {
      return false;
    }

    const validatorControl = this.validator({} as AbstractControl);
    return (validatorControl && validatorControl.required);
  }

}
