import {MAT_SELECT_CONFIG, MatSelectConfig} from '@angular/material/select';
import {Provider} from '@angular/core';

const selectOptions: MatSelectConfig = {
  hideSingleSelectionIndicator: true
};

export const matSelectOptions: Provider = {
  provide: MAT_SELECT_CONFIG,
  useValue: selectOptions
};
