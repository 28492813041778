import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {StatisticsApiService} from '../../statistics.api.service';
import {Time} from '../types/time.statistics.parameter.type';
import {ClusterID} from '../types/cluster-id.statistics.parameter.type';
import {TranscodingScenariosStatistic, TranscodingScenariosStatisticResponse} from './transcoding-scenarios.statistic.model';

@Injectable({
  providedIn: 'root'
})
export class TranscodingScenariosStatisticsService {

  constructor(
    private readonly api: StatisticsApiService
  ) {
  }

  public get(time: Time, clusterId: ClusterID): Observable<TranscodingScenariosStatistic> {
    return this.api
      .get(`/transcode`, {time, clusterId})
      .pipe(
        map((response: TranscodingScenariosStatisticResponse) => response.data)
      );
  }
}
