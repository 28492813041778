import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUpdateOrganization from '../reducers/update-organization.reducer';

export const selectUpdateOrganizationState = createFeatureSelector<fromUpdateOrganization.State>(
  fromUpdateOrganization.updateOrganizationFeatureKey
);

export const getUpdateOrganizationRecord = createSelector(
  selectUpdateOrganizationState,
  (state) => state.record
);

export const getUpdateOrganizationLoaded = createSelector(
  selectUpdateOrganizationState,
  (state) => state.loaded
);

export const getUpdateOrganizationLoading = createSelector(
  selectUpdateOrganizationState,
  (state) => state.loading
);

export const getUpdateOrganizationError = createSelector(
  selectUpdateOrganizationState,
  (state) => state.error
);
