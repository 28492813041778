import {Provider} from '@angular/core';
import {MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, MatSlideToggleDefaultOptions} from '@angular/material/slide-toggle';

const slideOptions: MatSlideToggleDefaultOptions = {
  hideIcon: true
};

export const matSlideOptions: Provider = {
  provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
  useValue: slideOptions
};
