import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromCurrentUser from '../store/users/reducers/current-user.reducer';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {loadClusters} from '../store/clusters/actions/clusters.actions';
import {getClustersLoaded, getClustersRecord} from '../store/clusters/selectors/clusters.selectors';
import {Cluster} from '../models/cluster.interface';

@Injectable({
  providedIn: 'root'
})
export class RequiredClustersGuard implements CanActivate {

  constructor(
    private readonly store: Store<fromCurrentUser.State>,
    private readonly router: Router
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkClusters()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }

  private checkClusters(): Observable<Cluster[]> {
    return this.store.select(getClustersLoaded)
      .pipe(
        tap(loaded => {
          if (!loaded) {
            this.store.dispatch(loadClusters());
          }
        }),
        filter(loaded => loaded),
        take(1),
        switchMap(() =>
          this.store.select(getClustersRecord)
            .pipe(
              tap((clusters: Cluster[]) => {
                if (clusters.length > 0) {
                  return of(true);
                } else {
                  this.router.navigate(['/']);
                  return of(false);
                }
              })
            )
        )
      );
  }
}
