import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeleteDestination from '../reducers/delete-destination.reducer';

export const selectDeleteDestinationState = createFeatureSelector<fromDeleteDestination.State>(
  fromDeleteDestination.deleteDestinationFeatureKey
);

export const getDeleteDestinationRecord = createSelector(
  selectDeleteDestinationState,
  (state) => state.record
);

export const getDeleteDestinationLoaded = createSelector(
  selectDeleteDestinationState,
  (state) => state.loaded
);

export const getDeleteDestinationLoading = createSelector(
  selectDeleteDestinationState,
  (state) => state.loading
);

export const getDeleteDestinationError = createSelector(
  selectDeleteDestinationState,
  (state) => state.error
);
