import { createAction, props } from '@ngrx/store';

export const deleteClip = createAction(
  '[DeleteClip] Delete Clip',
  props<{ data: number }>()
);

export const deleteClipSuccess = createAction(
  '[DeleteClip] Delete Clip Success',
  props<{ data: any }>()
);

export const deleteClipFailure = createAction(
  '[DeleteClip] Delete Clip Failure',
  props<{ error: any }>()
);

export const clearDeleteClipState = createAction(
  '[DeleteClip] clear Cut Video State',
);
