import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  ViewChildren, QueryList, SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-sldp-player-vu-indicator',
  templateUrl: './sldp-player-vu-indicator.component.html',
  styleUrls: ['./sldp-player-vu-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SldpPlayerVuIndicatorComponent implements OnChanges {
  @ViewChildren('indicator') indicator: QueryList<any>;
  @Input() index: number;
  @Input() VUHeight: number;
  @Input() VUValues: Array<number>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['VUValues'] && this.VUValues && this.indicator) {
      this.indicator.first.nativeElement.style.height = 100 + this.VUValues[this.index] + '%';
    }
  }
}
