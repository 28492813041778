import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import * as RtmpAuthSchemeActions from '../actions/rtmp-auth-scheme.actions';
import {RtmpAuthSchemesService} from '../../../services/api/rtmp-auth-schemes/rtmp-auth-schemes.service';


@Injectable()
export class RtmpAuthSchemeEffects {

  // noinspection JSUnusedGlobalSymbols
  loadRtmpAuthScheme$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RtmpAuthSchemeActions.loadRtmpAuthScheme),
      concatMap(() =>
        this.rtmpAuthSchemesService.getAll()
          .pipe(
            map(data => RtmpAuthSchemeActions.loadRtmpAuthSchemeSuccess({data})),
            catchError(error => of(RtmpAuthSchemeActions.loadRtmpAuthSchemeFailure({error})))
          )
      )
    );
  });


  constructor(
    private actions$: Actions,
    private readonly rtmpAuthSchemesService: RtmpAuthSchemesService
  ) {
  }

}
