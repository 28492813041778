import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CollaboraService} from '../../../services/api/collabora/collabora.service';
import {CollaboraLogLevel, CollaboraService as CollaboraSettingsService, ICollaboraUser} from '@teaminua/collabsdk';
import {CollaboraCredentials} from '../../../services/api/collabora/models/collabora.credentials.model';
import {filter, firstValueFrom, Observable, Subscription} from 'rxjs';
import {CurrentOrganization, User} from '../../../models/user.interface';
import {environment} from '../../../../environments/environment';
import {ColorGenerator} from '../../../services/utils/color-generator.util';
import {CollaboraUser} from '../../../services/api/collabora/models/collabora-user.module';
import {AuthService} from '../../../services/api/auth.service';
import {Store} from '@ngrx/store';
import {getCurrentUserRecord} from '../../../store/users/selectors/current-user.selectors';
import {loadCurrentUser} from '../../../store/users/actions/current-user.actions';
import {ConnectionState} from 'agora-rtc-sdk-ng';

@Component({
  selector: 'app-collabora',
  templateUrl: './collabora.component.html',
  styleUrls: ['./collabora.component.scss']
})
export class CollaboraComponent implements OnInit, OnDestroy {
  @Input() public layoutMode: 'navbar' | 'sidebar' | undefined = undefined;
  @Input() public channelName: string | undefined = undefined;
  @Input() public connect: EventEmitter<any> = new EventEmitter<void>();
  @Input() public disconnect: EventEmitter<any> = new EventEmitter<void>();
  @Output() public connected: EventEmitter<void> = new EventEmitter<void>();
  @Output() public disconnected: EventEmitter<void> = new EventEmitter<void>();
  public isInitialized: boolean = false;
  private readonly colorGenerator: ColorGenerator = new ColorGenerator();
  private currentUser: User | undefined = undefined;
  private logoutSubscription: Subscription | undefined = undefined;
  private connectSubscription: Subscription | undefined = undefined;
  private disconnectSubscription: Subscription | undefined = undefined;
  private connectionStateSubscription: Subscription | undefined = undefined;
  private authToken: string | undefined = undefined;
  private usersList: ICollaboraUser[] = [];

  constructor(
    private readonly collaboraSettingsService: CollaboraSettingsService,
    private readonly collaboraService: CollaboraService,
    private readonly authService: AuthService,
    private readonly store: Store
  ) {
  }

  public getUserImageUrl(filename: string): string | undefined {
    const storageUrl: string = environment.fileStorageUrl;
    return `${storageUrl}/users/${filename}`;
  }

  public ngOnInit(): void {
    this.collaboraSettingsService.logLevel = CollaboraLogLevel.WARNING;
    this.logoutSubscription = this.authService.onLogout.subscribe(() => this.onDisconnect());
    this.connectSubscription = this.connect.subscribe(() => void this.connectToCollabora());
    this.disconnectSubscription = this.disconnect.subscribe(() => this.onDisconnect());
    this.subscribeToConnectionStates();
    this.setCurrentUser();
  }

  private subscribeToConnectionStates(): void {
    this.connectionStateSubscription = this.collaboraSettingsService.connectionState
      .pipe(
        filter((state: ConnectionState) => state === 'CONNECTED' || state === 'DISCONNECTED')
      )
      .subscribe((state: ConnectionState) => {
        if (state === 'CONNECTED') {
          this.connected.emit();
        }

        if (state === 'DISCONNECTED') {
          this.disconnected.emit();
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }

    if (this.connectSubscription) {
      this.connectSubscription.unsubscribe();
    }

    if (this.disconnectSubscription) {
      this.disconnectSubscription.unsubscribe();
    }

    if (this.connectionStateSubscription) {
      this.connectionStateSubscription.unsubscribe();
    }
  }

  public async initialize(): Promise<void> {
    const currentOrganization: CurrentOrganization = this.currentUser.currentOrganization;
    const channelName: string = this.channelName || currentOrganization.uuid;

    if (!this.authToken) {
      const credentials: CollaboraCredentials = await this.getCredentials(channelName);
      this.authToken = credentials.token;
    }

    if (this.usersList.length === 0) {
      this.usersList = await this.getUsersList();

      const userId: number = this.currentUser.id as unknown as number;
      let currentUser: ICollaboraUser | undefined = this.usersList.find((user: ICollaboraUser): boolean => user.uid === userId);
      if (currentUser === undefined) {
        currentUser = this.generateCollaboraCurrentUser();
        this.usersList.push(currentUser);
      }
    }

    this.collaboraSettingsService.init();
    this.isInitialized = true;
    this.collaboraSettingsService.agoraAppId = environment.collaboraAppId;
    this.collaboraSettingsService.agoraToken = this.authToken;
    this.collaboraSettingsService.agoraChannel = channelName;
    this.collaboraSettingsService.userId = this.currentUser.id;
    this.collaboraSettingsService.usersInfo = this.usersList;
  }

  private async connectToCollabora(): Promise<void> {
    try {
      await this.initialize();
      await this.collaboraSettingsService.join();
    } catch (error) {
      console.error('Collabora client connection error:', error);
    }
  }

  private onDisconnect(): void {
    void this.collaboraSettingsService.disconnect();
    this.isInitialized = false;
  }

  private onUserChanged(): void {
    if (this.isInitialized === false) {
      this.initialize()
        .catch(error => console.error('Collabora client initialization error:', error));
    }

    if (this.isInitialized === true) {
      this.updateCurrentUser();
    }
  }

  private setCurrentUser(): void {
    this.store.dispatch(loadCurrentUser());
    this.store.select(getCurrentUserRecord)
      .subscribe((user: User | null): void => {
        if (user) {
          this.currentUser = user;
          this.onUserChanged();
        }
      });
  }

  private updateCurrentUser(): void {
    const userId: number = this.currentUser.id as unknown as number;

    const usersList: ICollaboraUser[] = this.collaboraSettingsService.usersInfo;

    let currentUserIndex: number = usersList.findIndex((user: ICollaboraUser): boolean => user.uid === userId);
    const update: ICollaboraUser = this.generateCollaboraCurrentUser();

    if (currentUserIndex === -1) {
      usersList.push(update);
    }

    if (currentUserIndex !== -1) {
      usersList[currentUserIndex] = update;
    }

    this.collaboraSettingsService.usersInfo = usersList;
  }

  private generateCollaboraCurrentUser(): ICollaboraUser {
    const collaboraUser: CollaboraUser = {
      id: this.currentUser.id as unknown as number,
      firstName: this.currentUser.fname,
      lastName: this.currentUser.lname,
      picture: this.currentUser.picture
    };

    return this.generateUserData(collaboraUser);
  }

  private generateUserData(user: CollaboraUser): ICollaboraUser {
    const id: number = user.id;
    const name: string = user.firstName + ' ' + user.lastName;
    const backgroundColor: string = this.colorGenerator.fromString(name);
    const result: ICollaboraUser = {
      uid: id,
      name: name,
      backgroundColor: backgroundColor
    };

    const userPictureFileName: string | null = user.picture;
    if (userPictureFileName) {
      result.image = this.getUserImageUrl(userPictureFileName);
    }

    return result;
  }

  private async getCredentials(channelName: string): Promise<CollaboraCredentials> {
    const credentialsSource: Observable<CollaboraCredentials> = this.collaboraService.authenticate(channelName);
    return firstValueFrom(credentialsSource);
  }

  private async getUsersList(): Promise<ICollaboraUser[]> {
    const usersListSource: Observable<CollaboraUser[]> = this.collaboraService.getUsersList();
    const usersList: CollaboraUser[] = await firstValueFrom(usersListSource);
    return usersList.map((user: CollaboraUser): ICollaboraUser => this.generateUserData(user));
  }
}
