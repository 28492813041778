import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import {Cluster} from '../../models/cluster.interface';
import {DeleteClusterRequest} from './clusters/models/cluster.create.model';

@Injectable({
  providedIn: 'root'
})
export class ClustersService {

  constructor(private apiService: ApiService) {}

  getClusters(): Observable<any> {
    return this.apiService.get(`/cluster`);
  }

  getCluster(clusterId): Observable<any> {
    return this.apiService.get(`/cluster/${clusterId}`);
  }

  createCluster(data): Observable<any> {
    return this.apiService.post(`/cluster`, data);
  }

  updateCluster(data): Observable<any> {
    return this.apiService.put(`/cluster/${data.id}`, data);
  }

  public deleteCluster(data: DeleteClusterRequest): Observable<any> {
    return this.apiService.delete(`/cluster/${data.clusterId}`,
      {isDeleteDvrBucket: data.isDeleteDvrBucket, isDeleteClipsBucket: data.isDeleteClipsBucket});
  }

  getClusterRegions(): Observable<any> {
    return this.apiService.get(`/organizations/cluster-regions`);
  }

  getAllClusterRegions(): Observable<any> {
    return this.apiService.get(`/voc/cluster-regions`);
  }

  public stopCluster(clusterId: string): Observable<Cluster> {
    return this.apiService.get(`/cluster/${clusterId}/stop`);
  }

  public startCluster(clusterId: string): Observable<Cluster> {
    return this.apiService.get(`/cluster/${clusterId}/start`);
  }
}
