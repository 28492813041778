import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CreateInputActions from '../actions/create-input.actions';
import { InputsService } from '../../../services/api/inputs.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as InputsActions from '../actions/inputs.actions';



@Injectable()
export class CreateInputEffects {

  createInput$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateInputActions.createInput),
      concatMap((payload) =>
        this.inputsService.createInput(payload.data).pipe(
          map(data => CreateInputActions.createInputSuccess({ data })),
          catchError(error => of(CreateInputActions.createInputFailure({ error }))))
      )
    );
  });

  createInputSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateInputActions.createInputSuccess),
      tap(() => this.snackBar.open('Input successfully created', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/streams']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private router: Router,
              private inputsService: InputsService,
              private snackBar: MatSnackBar) {}

}
