import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, concatMap, first, map, switchMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';

import * as TranscodersActions from '../actions/transcoders.actions';
import {Store} from '@ngrx/store';
import {DashboardService} from '../../../services/api/dashboard.service';
import {getChartTimeRecord} from '../selectors/chart-time.selectors';
import {getStatisticsClusterRecord} from '../selectors/statistics-cluster.selectors';
import {TranscodingScenariosStatistic} from '../../../services/api/statistics/transcoding-scenarios/transcoding-scenarios.statistic.model';


@Injectable()
export class TranscodersEffects {

  // noinspection JSUnusedGlobalSymbols
  loadTranscodingScenarios$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TranscodersActions.loadTranscoders),
      switchMap(() =>
        forkJoin(
          this.store.select(getChartTimeRecord).pipe(first()),
          this.store.select(getStatisticsClusterRecord).pipe(first())
        ).pipe(
          first(),
          concatMap(([time, clusterId]) =>
            this.dashboardService.getTranscodingScenarios(time, clusterId)
              .pipe(
                map((data: TranscodingScenariosStatistic) => TranscodersActions.loadTranscodersSuccess({data})),
                catchError(error => of(TranscodersActions.loadTranscodersFailure({error}))))
          )
        )
      )
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly dashboardService: DashboardService
  ) {
  }

}
