import { createAction, props } from '@ngrx/store';

export const updateClip = createAction(
  '[UpdateClip] Update Clip',
  props<{ data: any }>()
);

export const updateClipSuccess = createAction(
  '[UpdateClip] Update Clip Success',
  props<{ data: any }>()
);

export const updateClipFailure = createAction(
  '[UpdateClip] Update Clip Failure',
  props<{ error: any }>()
);

export const clearUpdateClipState = createAction(
  '[UpdateClip] Clear Update Clip State',
);
