import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromClips from '../reducers/clips.reducer';

export const selectClipsState = createFeatureSelector<fromClips.State>(
  fromClips.clipsFeatureKey
);

export const getClipsRecord = createSelector(
  selectClipsState,
  (state) => state.record
);

export const getClipsLoaded = createSelector(
  selectClipsState,
  (state) => state.loaded
);

export const getClipsLoading = createSelector(
  selectClipsState,
  (state) => state.loading
);

export const getClipsError = createSelector(
  selectClipsState,
  (state) => state.error
);
