import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUpdateAccount from '../reducers/update-account.reducer';

export const selectUpdateAccountState = createFeatureSelector<fromUpdateAccount.State>(
  fromUpdateAccount.updateAccountFeatureKey
);

export const getUpdateAccountRecord = createSelector(
  selectUpdateAccountState,
  (state) => state.record
);

export const getUpdateAccountLoaded = createSelector(
  selectUpdateAccountState,
  (state) => state.loaded
);

export const getUpdateAccountLoading = createSelector(
  selectUpdateAccountState,
  (state) => state.loading
);

export const getUpdateAccountError = createSelector(
  selectUpdateAccountState,
  (state) => state.error
);
