import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UserInviteActions from '../actions/user-invite.actions';
import { UsersService } from '../../../services/api/users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';



@Injectable()
export class UserInviteEffects {

  userInvite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserInviteActions.sendUserInvite),
      concatMap((action) =>
        this.usersService.createUser(action.data).pipe(
          map(data => UserInviteActions.sendUserInviteSuccess({ data })),
          catchError(error => of(UserInviteActions.sendUserInviteFailure({ error }))))
      )
    );
  });

  userInviteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserInviteActions.sendUserInviteSuccess),
      tap(() => {
        this.snackBar.open('User successfully created', 'Success', {duration: 3000});
        if (this.router.url.includes('/organizations/')) {
          this.router.navigate(['/organizations']);
        } else {
          this.router.navigate(['/organization']);
        }
      }),
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private usersService: UsersService,
              private snackBar: MatSnackBar,
              private router: Router) {}

}
