import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as RecordsActions from '../actions/records.actions';
import { DvrService } from '../../../services/api/dvr/dvr.service';



@Injectable()
export class RecordsEffects {

  loadRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RecordsActions.loadRecords),
      concatMap(() =>
        this.dvrService.getRecords().pipe(
          map(data => RecordsActions.loadRecordsSuccess({ data })),
          catchError(error => of(RecordsActions.loadRecordsFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private dvrService: DvrService) {}

}
