import { createAction, props } from '@ngrx/store';

export const loadAllInstanceTypes = createAction(
  '[AllInstanceTypes] Load All Instance Types'
);

export const loadAllInstanceTypesSuccess = createAction(
  '[AllInstanceTypes] Load All Instance Types Success',
  props<{ data: any }>()
);

export const loadAllInstanceTypesFailure = createAction(
  '[AllInstanceTypes] Load All Instance Types Failure',
  props<{ error: any }>()
);

export const clearAllInstanceTypesState = createAction(
  '[AllInstanceTypes] clear All Instance Types State',
);
