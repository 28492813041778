import { createAction, props } from '@ngrx/store';

export const loadGrid = createAction(
  '[Grid] Load Grid',
  props<{ data: any }>()
);

export const loadGridSuccess = createAction(
  '[Grid] Load Grid Success',
  props<{ data: any }>()
);

export const loadUpdatedGridSuccess = createAction(
  '[Grid] Load Updated Grid Success',
  props<{ data: any }>()
);

export const loadGridFailure = createAction(
  '[Grid] Load Grid Failure',
  props<{ error: any }>()
);

export const updateVisibility = createAction(
  '[Grid] Update Visibility',
);

export const clearGridState = createAction(
  '[Grid] clear Grid State',
);
