import { createAction, props } from '@ngrx/store';

export const loadAllClusterRegions = createAction(
  '[AllClusterRegions] Load All Cluster Regions'
);

export const loadAllClusterRegionsSuccess = createAction(
  '[AllClusterRegions] Load All Cluster Regions Success',
  props<{ data: any }>()
);

export const loadAllClusterRegionsFailure = createAction(
  '[AllClusterRegions] Load All Cluster Regions Failure',
  props<{ error: any }>()
);

export const clearAllClusterRegionsState = createAction(
  '[AllClusterRegions] clear All Cluster Regions State',
);
