import { createReducer, on } from '@ngrx/store';
import * as TranscodingProfilesActions from '../actions/transcoding-profiles.actions';
import { Utils } from '../../../services/Utils';

export const transcodingProfilesFeatureKey = 'transcodingProfiles';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(TranscodingProfilesActions.loadTranscodingProfiles, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(TranscodingProfilesActions.loadTranscodingProfilesSuccess, (state, action) => ({
    record: Utils.naturalSortArrayByKey(action.data.data, 'label'),
    error: null,
    loaded: true,
    loading: false,
  })),
  on(TranscodingProfilesActions.loadTranscodingProfilesFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(TranscodingProfilesActions.clearTranscodingProfilesState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  }))
);

