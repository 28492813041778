import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

export class AppMatTableDataSource<T> extends MatTableDataSource<T> {

  compareFn = new Intl.Collator('pl', {sensitivity: 'base', numeric: true}).compare;

  sortData: ((data: T[], sort: MatSort) => T[]) = (data: T[], sort: MatSort): T[] => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction === '') { return data; }

    return data.sort((a, b) => {
      const valueA = this.sortingDataAccessor(a, active);
      const valueB = this.sortingDataAccessor(b, active);

      const comparatorResult = this.compareFn(valueA as string, valueB as string);

      return comparatorResult * (direction === 'asc' ? 1 : -1);
    });
  }
}
