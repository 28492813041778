import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Cluster} from '../../../models/cluster.interface';
import {Instance} from '../../../models/instances.interface';
import {FormGroup} from '@angular/forms';
import {InputFormItem} from './interfaces/input-form.item.interface';

@Component({
  selector: 'app-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFormComponent implements OnChanges {
  @Input() inputControl: FormGroup;
  @Input() inputRecords: InputFormItem[];

  showInputDetails = false;
  public filteredInputs: Observable<InputFormItem[]>;
  inputClusters: Cluster[];
  currentClusterInstances: Instance[];
  currentInstance: Instance;

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.inputRecords) {
      this.filteredInputs = this.inputControl.get('currentInput').valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value.name),
          map((name: string) => this.filterInputsRecord(name))
        );
    }
    if (this.inputControl.get('currentInput').value) {
      this.inputClusters = this.inputControl.get('currentInput').value.clusters;
      this.showInputDetails = true;
      this.onChangeCluster();
    }
  }

  displayFn(input: InputFormItem): string {
    return input && input.inputName ? input.inputName : '';
  }

  onSelectInput() {
    this.inputClusters = this.inputControl.get('currentInput').value.clusters;
    this.inputControl.get('input').setValue(this.inputControl.get('currentInput').value.id);
    this.inputControl.get('inputName').setValue(this.inputControl.get('currentInput').value.inputName);
    this.inputControl.get('clusterId').setValue(this.inputClusters[0].id);
    this.inputControl.get('clusterName').setValue(this.inputClusters[0].name);
    this.inputControl.get('inputUrl').setValue(this.inputClusters[0]['instances'][0].inputUrl.public);
    this.inputControl.get('sldp').setValue(this.inputClusters[0]['instances'][0].sldp.public);
    this.inputControl.get('role').setValue(this.inputClusters[0]['instances'][0].role);
    this.inputControl.controls['currentInput'].setErrors(null);
    this.showInputDetails = true;
    this.onChangeCluster();
  }

  onTypingInput() {
    this.inputControl.controls['currentInput'].setErrors({incorrect: true});
    this.showInputDetails = false;
  }

  onInputCancelClick(event: Event) {
    event.stopPropagation();
    this.inputControl.get('input').setValue('');
    this.inputControl.get('inputName').setValue('');
    this.inputControl.get('clusterId').setValue('');
    this.inputControl.get('clusterName').setValue('');
    this.inputControl.get('inputUrl').setValue('');
    this.inputControl.get('sldp').setValue('');
    this.inputControl.get('role').setValue('');
    this.inputControl.get('currentInput').setValue('');
    this.showInputDetails = false;
  }

  public onChangeCluster(): void {
    const currentCluster = this.inputClusters
      .filter(cluster => cluster.id === this.inputControl.get('clusterId').value)[0];
    this.currentClusterInstances = currentCluster.instances;

    this.currentInstance = this.currentClusterInstances
      .filter((instance: Instance) => instance.role === this.inputControl.get('role').value)[0];
    this.inputControl.get('inputUrl').setValue(this.currentInstance.inputUrl.public);
    this.inputControl.get('sldp').setValue(this.currentInstance.sldp.public);
    this.inputControl.get('clusterName').setValue(currentCluster.name);
  }

  public isBlocked(input: InputFormItem): boolean {
    return !!input.blockReason;
  }

  private filterInputsRecord(value: string): InputFormItem[] {
    if (!value) {
      return this.inputRecords;
    }

    const lowerCaseValue: string = value.toLowerCase();
    return this.inputRecords.filter(
      item => item.inputName.toLowerCase().indexOf(lowerCaseValue) === 0
    );
  }

}
