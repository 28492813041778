import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getClustersLoading } from '../../store/clusters/selectors/clusters.selectors';
import { getInputsLoading } from '../../store/inputs/selectors/inputs.selectors';
import { getCreateInputLoading } from '../../store/inputs/selectors/create-input.selectors';
import { getInputUpdateLoading } from '../../store/inputs/selectors/input-update.selectors';
import { getDestinationsLoading } from '../../store/destinations/selectors/destinations.selectors';
import { getCreateDestinationLoading } from '../../store/destinations/selectors/create-destination.selectors';
import { getUpdateDestinationLoading } from '../../store/destinations/selectors/update-destination.selectors';
import { getDeleteDestinationLoading } from '../../store/destinations/selectors/delete-destination.selectors';
import { getInstancesLoading } from '../../store/instances/selectors/instances.selectors';
import { getDeleteInputLoading } from '../../store/inputs/selectors/delete-input.selectors';
import { getClusterRegionsLoading } from '../../store/clusters/selectors/cluster-regions.selectors';
import { getUpdateClusterLoading } from '../../store/clusters/selectors/update-cluster.selectors';
import { getCreateClusterLoading } from '../../store/clusters/selectors/create-cluster.selectors';
import { getGridsLoading } from '../../store/grids/selectors/grids.selectors';
import { getDeleteGridLoading } from '../../store/grids/selectors/delete-grid.selectors';
import { getGridLoading } from '../../store/grids/selectors/grid.selectors';
import { getCreateGridLoading } from '../../store/grids/selectors/create-grid.selectors';
import { getUpdeteGridLoading } from '../../store/grids/selectors/update-grid.selectors';
import { getDeleteRecordLoading } from '../../store/dvr/selectors/delete-record.selectors';
import { getRecordsLoading } from '../../store/dvr/selectors/records.selectors';
import { getStopRecordLoading } from '../../store/dvr/selectors/stop-record.selectors';
import { getStartRecordLoading } from '../../store/dvr/selectors/start-record.selectors';
import { getOrganizationsLoading } from '../../store/organizations/selectors/organizations.selectors';
import { getCreateOrganizationLoading } from '../../store/organizations/selectors/create-organization.selectors';
import { getDeleteOrganizationLoading } from '../../store/organizations/selectors/delete-organization.selectors';
import { getOrganizationLoading } from '../../store/organizations/selectors/organization.selectors';
import { getUpdateOrganizationLoading } from '../../store/organizations/selectors/update-organization.selectors';
import { getUsersRolesLoading } from '../../store/users/selectors/users-roles.selectors';
import { getAllInstanceTypesLoading } from '../../store/instances/selectors/all-instance-types.selectors';
import { getAllClusterRegionsLoading } from '../../store/clusters/selectors/all-cluster-regions.selectors';
import { getOrganizationLicensesLoading } from '../../store/organizations/selectors/organization-licenses.selectors';
import { getUserInviteLoading } from '../../store/users/selectors/user-invite.selectors';
import { getUserUpdateLoading } from '../../store/users/selectors/user-update.selectors';
import { getUserDeleteLoading } from '../../store/users/selectors/user-delete.selectors';
import { getUpdateAccountLoading } from '../../store/account/selectors/update-account.selectors';
import { getResetPasswordLoading } from '../../store/auth/reset-password/selectors/reset-password.selectors';
import { getClipsLoading } from '../../store/clips/selectors/clips.selectors';
import { getCutVideoLoading } from '../../store/clips/selectors/cut-video.selectors';
import { getDeleteClipLoading } from '../../store/clips/selectors/delete-clip.selectors';
import { getUpdateAccountPasswordLoading } from '../../store/account/selectors/update-account-password.selectors';
import { getCreateVmixServerLoading } from '../../store/production/selectors/create-vmix-server.selectors';
import { getStartVmixServerLoading } from '../../store/production/selectors/start-vmix-server.selectors';
import { getDeleteVmixServerLoading } from '../../store/production/selectors/delete-vmix-server.selectors';
import { getStopVmixServerLoading } from '../../store/production/selectors/stop-vmix-server.selectors';
import { getVmixServersLoading } from '../../store/production/selectors/vmix-servers.selectors';
import { getStatisticsLoading } from 'src/app/store/statistics/selectors/statistics.selectors';
import { getPaymentInfoLoading } from '../../store/payment/selectors/payment-info.selectors';
import { getStartDestinationLoading } from '../../store/destinations/selectors/start-destination.selectors';
import { getStopDestinationLoading } from '../../store/destinations/selectors/stop-destination.selectors';
import { getCurrentUserLoading } from '../../store/users/selectors/current-user.selectors';
import { getCurrentOrganizationLoading } from '../../store/organizations/selectors/current-organization.selectors';
import { getIncomeBandwidthLoading } from '../../store/dashboard/selectors/income-bandwidth.selectors';
import { getOutcomeBandwidthLoading } from '../../store/dashboard/selectors/outcome-bandwidth.selectors';
import { getTranscodersLoading } from '../../store/dashboard/selectors/transcoders.selectors';
import { getStorageLoading } from '../../store/dashboard/selectors/storage.selectors';

@Component({
  selector: 'app-progress-bar-container',
  template: `
    <mat-progress-bar mode="indeterminate" class="progress"
                      *ngIf="(allLoadingValues$ | async).includes(true)"></mat-progress-bar>
  `,
  styles: [`
    .progress {
      height: 100%;
      z-index: 1071;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarContainerComponent implements OnInit {
  allLoadingValues$: Observable<boolean[]>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.allLoadingValues$ = combineLatest([
      this.store.select(getClustersLoading),
      this.store.select(getInputsLoading),
      this.store.select(getCreateInputLoading),
      this.store.select(getInputUpdateLoading),
      this.store.select(getDestinationsLoading),
      this.store.select(getCreateDestinationLoading),
      this.store.select(getUpdateDestinationLoading),
      this.store.select(getDeleteDestinationLoading),
      this.store.select(getInstancesLoading),
      this.store.select(getDeleteInputLoading),
      this.store.select(getClusterRegionsLoading),
      this.store.select(getUpdateClusterLoading),
      this.store.select(getCreateClusterLoading),
      this.store.select(getClustersLoading),
      this.store.select(getGridsLoading),
      this.store.select(getDeleteGridLoading),
      this.store.select(getGridLoading),
      this.store.select(getCreateGridLoading),
      this.store.select(getUpdeteGridLoading),
      this.store.select(getRecordsLoading),
      this.store.select(getStopRecordLoading),
      this.store.select(getStartRecordLoading),
      this.store.select(getDeleteRecordLoading),
      this.store.select(getOrganizationsLoading),
      this.store.select(getCreateOrganizationLoading),
      this.store.select(getDeleteOrganizationLoading),
      this.store.select(getOrganizationLoading),
      this.store.select(getUpdateOrganizationLoading),
      this.store.select(getUsersRolesLoading),
      this.store.select(getAllInstanceTypesLoading),
      this.store.select(getAllClusterRegionsLoading),
      this.store.select(getOrganizationLicensesLoading),
      this.store.select(getUserInviteLoading),
      this.store.select(getUserUpdateLoading),
      this.store.select(getUserDeleteLoading),
      this.store.select(getUpdateAccountLoading),
      this.store.select(getResetPasswordLoading),
      this.store.select(getClipsLoading),
      this.store.select(getCutVideoLoading),
      this.store.select(getDeleteClipLoading),
      this.store.select(getUpdateAccountPasswordLoading),
      this.store.select(getCreateVmixServerLoading),
      this.store.select(getDeleteVmixServerLoading),
      this.store.select(getStartVmixServerLoading),
      this.store.select(getStopVmixServerLoading),
      this.store.select(getVmixServersLoading),
      this.store.select(getStatisticsLoading),
      this.store.select(getPaymentInfoLoading),
      this.store.select(getStartDestinationLoading),
      this.store.select(getStopDestinationLoading),
      this.store.select(getCurrentUserLoading),
      this.store.select(getCurrentOrganizationLoading),
      this.store.select(getIncomeBandwidthLoading),
      this.store.select(getOutcomeBandwidthLoading),
      this.store.select(getTranscodersLoading),
      this.store.select(getTranscodersLoading),
      this.store.select(getStorageLoading)
      ]
    );
  }
}
