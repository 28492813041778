import { createAction, props } from '@ngrx/store';

export const loadStatistics = createAction(
  '[Statistics] Load Statistics',
  props<{ data: any }>()
);

export const loadStatisticsSuccess = createAction(
  '[Statistics] Load Statistics Success',
  props<{ data: any }>()
);

export const loadStatisticsFailure = createAction(
  '[Statistics] Load Statistics Failure',
  props<{ error: any }>()
);

export const clearStatisticsState = createAction(
  '[Statistics] clear Load Statistics State',
);
