import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-stream-timer',
  template: `
    <span>{{duration | async}}</span>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamTimerComponent implements OnInit, OnDestroy {
  @Input() startTime: number;
  @Input() endTime: number;
  public duration: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private intervalSubscription: Subscription;

  constructor() {
  }

  public ngOnInit(): void {
    this.updateDuration();
    this.intervalSubscription = interval(1000)
      .subscribe(() => this.updateDuration());
  }

  public ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.duration.complete();
  }

  private updateDuration(): void {
    const endDate: number = this.endTime || new Date().getTime();
    const startDate: number = this.startTime || new Date().getTime();
    if (startDate >= endDate || !startDate) {
      this.duration.next('00:00:00');
      return;
    }

    const durationInSeconds: number = Math.trunc((endDate - startDate) / 1000);

    const seconds: number = Math.floor(durationInSeconds % 60);
    const secondsString: string = String(seconds).padStart(2, '0');

    const minutes: number = Math.floor((durationInSeconds / 60) % 60);
    const minutesString: string = String(minutes).padStart(2, '0');

    const hours: number = Math.floor(durationInSeconds / (60 * 60));
    const hoursString: string = String(hours).padStart(2, '0');

    const duration = `${hoursString}:${minutesString}:${secondsString}`;
    this.duration.next(duration);
  }

}
