import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CurrentOrganizationActions from '../actions/current-organization.actions';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';



@Injectable()
export class CurrentOrganizationEffects {

  loadCurrentOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CurrentOrganizationActions.loadCurrentOrganization),
      concatMap(() =>
        this.organizationsService.getCurrentOrganization().pipe(
          map(data => CurrentOrganizationActions.loadCurrentOrganizationSuccess({ data })),
          catchError(error => of(CurrentOrganizationActions.loadCurrentOrganizationFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService) {}

}
