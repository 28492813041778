import { createReducer, on } from '@ngrx/store';
import * as DeleteClipActions from '../actions/delete-clip.actions';

export const deleteClipFeatureKey = 'deleteClip';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(DeleteClipActions.deleteClip, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(DeleteClipActions.deleteClipSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(DeleteClipActions.deleteClipFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(DeleteClipActions.clearDeleteClipState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

