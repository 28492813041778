import { createAction, props } from '@ngrx/store';

export const createOrganization = createAction(
  '[CreateOrganization] Create Organization',
  props<{ data: any }>()
);

export const createOrganizationSuccess = createAction(
  '[CreateOrganization] Create Organization Success',
  props<{ data: any }>()
);

export const createOrganizationFailure = createAction(
  '[CreateOrganization] Create Organization Failure',
  props<{ error: any }>()
);

export const clearCreateOrganizationState = createAction(
  '[CreateOrganization] Clear Create Organization State',
);
