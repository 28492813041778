import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCreateInput from '../reducers/create-input.reducer';

export const selectCreateInputState = createFeatureSelector<fromCreateInput.State>(
  fromCreateInput.createInputFeatureKey
);

export const getCreateInputRecord = createSelector(
  selectCreateInputState,
  (state) => state.record
);

export const getCreateInputLoaded = createSelector(
  selectCreateInputState,
  (state) => state.loaded
);

export const getCreateInputLoading = createSelector(
  selectCreateInputState,
  (state) => state.loading
);

export const getCreateInputError = createSelector(
  selectCreateInputState,
  (state) => state.error
);
