import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChartTime from '../reducers/chart-time.reducer';

export const selectChartTimeState = createFeatureSelector<fromChartTime.State>(
  fromChartTime.chartTimeFeatureKey
);

export const getChartTimeRecord = createSelector(
  selectChartTimeState,
  (state) => state.time
);
