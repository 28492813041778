import { createReducer, on } from '@ngrx/store';
import * as ClipsActions from '../actions/clips.actions';
import { DataTransform } from '../../../services/DataTransform';

export const clipsFeatureKey = 'clips';

export interface State {
  record: any[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(ClipsActions.loadClips, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ClipsActions.loadClipsSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ClipsActions.loadClipsFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ClipsActions.updateClipStatus, (state, action) => {
    let updatedData;
    action.data.data
      .map((clip) => updatedData = DataTransform
          .findObjInArrayByKeyAndReplaceIfKeyChanged(state.record, clip, 'id', 'status')
      );
    updatedData = updatedData ? updatedData : state.record;
    return ({
      record: updatedData,
      error: null,
      loaded: true,
      loading: false,
    });
  }),
  on(ClipsActions.clearClipsState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  })),
);
