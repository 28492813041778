import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as StopVmixServerActions from '../actions/stop-vmix-server.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ProductionInstancesService} from '../../../services/api/production-instances/production-instances.service';



@Injectable()
export class StopVmixServerEffects {

  constructor(
    private readonly actions: Actions,
    private readonly productionInstancesService: ProductionInstancesService,
    private readonly snackBar: MatSnackBar
  ) {}

  stopProductionInstance = createEffect(() => {
    return this.actions.pipe(
      ofType(StopVmixServerActions.stopVmixServer),
      concatMap((payload) =>
        this.productionInstancesService.stop(payload.data).pipe(
          map(data => StopVmixServerActions.stopVmixServerSuccess({ data })),
          catchError(error => of(StopVmixServerActions.stopVmixServerFailure({ error }))))
      )
    );
  });

  stopProductionInstanceSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(StopVmixServerActions.stopVmixServerSuccess),
      tap(() => this.snackBar.open('Production instance successfully started stopping', 'Success', {duration: 3000})),
    );
  }, {dispatch: false});

}
