import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CreateGridActions from '../actions/create-grid.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GridsService } from '../../../services/api/grids.service';



@Injectable()
export class CreateGridEffects {

  createGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateGridActions.createGrid),
      concatMap((payload) =>
        this.gridsService.createGrid(payload.data).pipe(
          map(data => CreateGridActions.createGridSuccess({ data })),
          catchError(error => of(CreateGridActions.createGridFailure({ error }))))
      )
    );
  });

  createGridSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateGridActions.createGridSuccess),
      tap(() => this.snackBar.open('Grid successfully created', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/multiview']))
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private router: Router,
              private gridsService: GridsService) {}

}
