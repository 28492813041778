import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable, of } from 'rxjs';
import {InstanceEntity} from './interfaces/instance.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class InstancesService {

  constructor(private apiService: ApiService) {}

  getInstances(): Observable<any> {
    return this.apiService.get(`/instance`);
  }

  public getInstanceByDocumentId(documentId: string): Observable<InstanceEntity> {
    return this.apiService.get(`/instance/${documentId}`);
  }

  getAllInstanceTypes(): Observable<any> {
    return this.apiService.get(`/voc/instance-types`);
  }
}
