import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as OrganizationActions from '../actions/organization.actions';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';



@Injectable()
export class OrganizationEffects {

  loadOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationActions.loadOrganization),
      concatMap((payload) =>
        this.organizationsService.getOrganization(payload.data).pipe(
          map(data => OrganizationActions.loadOrganizationSuccess({ data })),
          catchError(error => of(OrganizationActions.loadOrganizationFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService) {}

}
