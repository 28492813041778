import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAllClusterRegions from '../reducers/all-cluster-regions.reducer';

export const selectAllClusterRegionsState = createFeatureSelector<fromAllClusterRegions.State>(
  fromAllClusterRegions.allClusterRegionsFeatureKey
);

export const getAllClusterRegionsRecord = createSelector(
  selectAllClusterRegionsState,
  (state) => state.record
);

export const getAllClusterRegionsLoaded = createSelector(
  selectAllClusterRegionsState,
  (state) => state.loaded
);

export const getAllClusterRegionsLoading = createSelector(
  selectAllClusterRegionsState,
  (state) => state.loading
);

export const getAllClusterRegionsError = createSelector(
  selectAllClusterRegionsState,
  (state) => state.error
);
