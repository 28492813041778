import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as InputActions from '../actions/input.actions';
import { InputsService } from '../../../services/api/inputs.service';



@Injectable()
export class InputEffects {

  loadInput$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InputActions.loadInput),
      concatMap((payload) =>
        this.inputsService.getInput(payload.data).pipe(
          map(data => InputActions.loadInputSuccess({ data })),
          catchError(error => of(InputActions.loadInputFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private inputsService: InputsService) {}

}
