<div class="clock" [style.transform]="'scale(' + size + ')'">
  <!-- Hours -->
  <ng-container *ngFor="let digit of hours.split('')">
    <div class="digit">
      <div class="seven-segment">
        <div
          class="segment"
          *ngFor="let s of [0,1,2,3,4,5,6]; let i = index"
          [ngClass]="{'on': isSegmentOn(digit, i)}"
        ></div>
      </div>
    </div>
  </ng-container>

  <div class="colon">:</div>

  <!-- Minutes -->
  <ng-container *ngFor="let digit of minutes.split('')">
    <div class="digit">
      <div class="seven-segment">
        <div
          class="segment"
          *ngFor="let s of [0,1,2,3,4,5,6]; let i = index"
          [ngClass]="{'on': isSegmentOn(digit, i)}"
        ></div>
      </div>
    </div>
  </ng-container>

  <div class="colon">:</div>

  <!-- Seconds -->
  <ng-container *ngFor="let digit of seconds.split('')">
    <div class="digit">
      <div class="seven-segment">
        <div
          class="segment"
          *ngFor="let s of [0,1,2,3,4,5,6]; let i = index"
          [ngClass]="{'on': isSegmentOn(digit, i)}"
        ></div>
      </div>
    </div>
  </ng-container>

  <div class="colon">:</div>

  <!-- Milliseconds -->
  <ng-container *ngFor="let digit of milliseconds.split('')">
    <div class="digit">
      <div class="seven-segment">
        <div
          class="segment"
          *ngFor="let s of [0,1,2,3,4,5,6]; let i = index"
          [ngClass]="{'on': isSegmentOn(digit, i)}"
        ></div>
      </div>
    </div>
  </ng-container>
</div>
