import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Router} from '@angular/router';
import {WebStorageService} from '../web-storage.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  public onLogout: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly storageService: WebStorageService
  ) {
  }

  public signup(data: any): Observable<any> {
    return this.apiService.post(`/auth/signup`, data);
  }

  public login(data: any): Observable<any> {
    return this.apiService.post(`/auth/login`, data);
  }

  public resetPassword(data: { email: string }): Observable<any> {
    return this.apiService.post(`/auth/reset-password`, data);
  }

  public setNewPassword(data: any): Observable<any> {
    return this.apiService.post(`/auth/new-password`, data);
  }

  public logout(): void {
    this.storageService.deleteItem('token');
    this.storageService.deleteItem('organizationId');
    this.onLogout.emit();
    this.router.navigate(['/login']);
  }

  public activateInvitation(data: any): Observable<any> {
    return this.apiService.post(`/new-password`, data);
  }
}
