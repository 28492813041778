import { createAction, props } from '@ngrx/store';

export const deleteGrid = createAction(
  '[DeleteGrid] Delete Grid',
  props<{ data: any }>()
);

export const deleteGridSuccess = createAction(
  '[DeleteGrid] Delete Grid Success',
  props<{ data: any }>()
);

export const deleteGridFailure = createAction(
  '[DeleteGrid] Delete Grid Failure',
  props<{ error: any }>()
);

export const clearDeleteGridState = createAction(
  '[DeleteGrid] clear Delete Grid State',
);
