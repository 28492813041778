import {NgModule} from '@angular/core';
import {CollaboraComponent} from './collabora.component';
import {CollaboraModule as CollaboraSDK} from '@teaminua/collabsdk';
import {NgIf} from '@angular/common';

@NgModule({
  declarations: [CollaboraComponent],
  imports: [
    CollaboraSDK,
    NgIf
  ],
  exports: [CollaboraComponent]
})
export class CollaboraModule {
}
