import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCreateDestination from '../reducers/create-destination.reducer';

export const selectCreateDestinationState = createFeatureSelector<fromCreateDestination.State>(
  fromCreateDestination.createDestinationFeatureKey
);

export const getCreateDestinationRecord = createSelector(
  selectCreateDestinationState,
  (state) => state.record
);

export const getCreateDestinationLoaded = createSelector(
  selectCreateDestinationState,
  (state) => state.loaded
);

export const getCreateDestinationLoading = createSelector(
  selectCreateDestinationState,
  (state) => state.loading
);

export const getCreateDestinationError = createSelector(
  selectCreateDestinationState,
  (state) => state.error
);
