import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLogin from '../../store/auth/login/reducers/login.reducer';
import { getCurrentUserRecord } from '../../store/users/selectors/current-user.selectors';
import { User } from '../../models/user.interface';

@Directive({
  selector: '[appPermissions]'
})
export class PermissionsDirective implements AfterViewInit {
  @Input() feature: string;
  @Input() mode: string;
  currentUser: User;

  constructor(private elementRef: ElementRef,
              private store: Store<fromLogin.State>) {
     this.store.select(getCurrentUserRecord).subscribe(
       user => this.currentUser = user
     );
  }

  ngAfterViewInit(): void {
    if (!this.currentUser.permissions ||
      !this.currentUser.permissions[this.feature] ||
      !this.currentUser.permissions[this.feature][[this.mode]]) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

}
