import { createAction, props } from '@ngrx/store';

export const inputUpdate = createAction(
  '[InputUpdate] Input Update',
  props<{ data: any }>()
);

export const inputUpdateSuccess = createAction(
  '[InputUpdate] Input Update Success',
  props<{ data: any }>()
);

export const inputUpdateFailure = createAction(
  '[InputUpdate] Input Update Failure',
  props<{ error: any }>()
);

export const clearInputUpdateState = createAction(
  '[InputUpdate] clear Input Update State',
);
