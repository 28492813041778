import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as DeleteVmixServerActions from '../actions/delete-vmix-server.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as VmixServersActions from '../actions/vmix-servers.actions';
import {ProductionInstancesService} from '../../../services/api/production-instances/production-instances.service';


@Injectable()
export class DeleteVmixServerEffects {

  // noinspection JSUnusedGlobalSymbols
  deleteProductionInstance = createEffect(() => {
    return this.actions.pipe(
      ofType(DeleteVmixServerActions.deleteVmixServer),
      concatMap((payload) =>
        this.productionInstancesService.delete(payload.data).pipe(
          map(data => DeleteVmixServerActions.deleteVmixServerSuccess({data})),
          catchError(error => of(DeleteVmixServerActions.deleteVmixServerFailure({error}))))
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  deleteProductionInstanceSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(DeleteVmixServerActions.deleteVmixServerSuccess),
      tap(() => this.snackBar.open('Production Instance deletion successfully started', 'Success', {duration: 3000})),
      map(() => VmixServersActions.loadVmixServers())
    );
  });

  constructor(
    private readonly actions: Actions,
    private readonly productionInstancesService: ProductionInstancesService,
    private readonly snackBar: MatSnackBar
  ) {
  }

}
