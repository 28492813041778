import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { DynamicScriptLoaderService } from '../../../services/dynamic-script-loader/dynamic-script-loader.service';

declare var videojs;

@Component({
  selector: 'app-videojs-player',
  templateUrl: './videojs-player.component.html',
  styleUrls: ['./videojs-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideojsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;
  @Input() url: string;
  @Input() options: any;
  player: any;
  isScriptLoaded = false;


  constructor(private dynamicScriptLoader: DynamicScriptLoaderService) { }

  ngOnInit() {
    if (!this.isScriptLoaded) {
      this.loadScripts();
    }
  }

  private loadScripts() {
    this.dynamicScriptLoader.loadScript('videojs')
      .then( () => {
        this.isScriptLoaded = true;
        this.initPlayer();
      })
      .catch(error => console.error(error));
  }

  initPlayer() {
    this.player = videojs(this.target.nativeElement, this.options);
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

}
