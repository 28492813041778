import { createAction, props } from '@ngrx/store';

export const loadClusterRegions = createAction(
  '[ClusterRegions] Load Cluster Regions'
);

export const loadClusterRegionsSuccess = createAction(
  '[ClusterRegions] Load Cluster Regions Success',
  props<{ data: any }>()
);

export const loadClusterRegionsFailure = createAction(
  '[ClusterRegions] Load Cluster Regions Failure',
  props<{ error: any }>()
);

export const clearClusterRegionsState = createAction(
  '[ClusterRegions] clear Cluster Regions State',
);
