export interface NavbarMenuItem {
  code: string;
  label: string;
  path: string | null;
  icon: string;
  access: number[];
  handler?: () => void;
}

export const NavbarMenusConfig: NavbarMenuItem[] = [
  {
    code: 'organizations',
    label: 'Organizations',
    path: '/organizations',
    icon: 'Organizations',
    access: [10]
  },
  {
    code: 'organization',
    label: 'Organization',
    path: '/organization',
    icon: 'Organization',
    access: [5, 10]
  },
  {
    code: 'account',
    label: 'Account',
    path: '/account',
    icon: 'Account',
    access: [1, 5, 10]
  },
  {
    code: 'logout',
    label: 'Logout',
    path: null,
    icon: 'Logout',
    access: [1, 5, 10],
    handler: () => {
    }
  }
];
