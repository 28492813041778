import { createReducer, on } from '@ngrx/store';
import * as ValidateDnsActions from '../actions/validate-dns.actions';

export const validateDnsFeatureKey = 'validateDns';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(ValidateDnsActions.validateDns, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ValidateDnsActions.validateDnsSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ValidateDnsActions.validateDnsFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ValidateDnsActions.clearValidateDnsState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
