import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {payloadToModel} from './helpers/payload-to-model.helper';
import {RtmpAuthScheme} from './interfaces/rtmp-auth-scheme.interface';
import {RtmpAuthSchemePayload} from './interfaces/rtmp-auth-scheme.payload.interface';

@Injectable({
  providedIn: 'root'
})
export class RtmpAuthSchemesService {

  constructor(private apiService: ApiService) {}

  public getAll(): Observable<RtmpAuthScheme[]> {
    return this.apiService.get<RtmpAuthSchemePayload[]>(`/rtmp-auth-schemes`)
      .pipe(
        map((payload: RtmpAuthSchemePayload[]) => payloadToModel(payload)),
        catchError(error => {
          console.error(error);
          return throwError(error.message || 'Internal server error');
        })
      );
  }
}
