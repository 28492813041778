import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStopDestination from '../reducers/stop-destination.reducer';

export const selectStopDestinationState = createFeatureSelector<fromStopDestination.State>(
  fromStopDestination.stopDestinationFeatureKey
);

export const getStopDestinationRecord = createSelector(
  selectStopDestinationState,
  (state) => state.record
);

export const getStopDestinationLoaded = createSelector(
  selectStopDestinationState,
  (state) => state.loaded
);

export const getStopDestinationLoading = createSelector(
  selectStopDestinationState,
  (state) => state.loading
);

export const getStopDestinationError = createSelector(
  selectStopDestinationState,
  (state) => state.error
);
