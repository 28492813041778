import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-volume-slider',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './volume-slider.component.html',
  styleUrls: ['./volume-slider.component.scss']
})
export class VolumeSliderComponent {
  @Input() public volume: number;
  @Output() public onVolumeChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  public onSliderChange(event: Event): void {
    const volumeAsString: string = (event.target as HTMLInputElement).value;
    const volume: number = parseInt(volumeAsString, 10);
    this.onVolumeChange.emit(volume);
  }
}
