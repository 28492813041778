/* tslint:disable:no-bitwise */
import { ELEMENTS } from './dictionaries/elements.dictionary';
import { Flag } from './dictionaries/flags.dictionary';
import { IncludeOption } from './dictionaries/include-options.dictionary';


export class PWGen {
  public maxLength = 8;
  public includeCapitalLetter = true;
  public includeNumber = true;
  public includeSpecial = true;

  constructor(
    maxLength: number,
    includeCapitalLetter: boolean,
    includeNumber: boolean,
    includeSpecial: boolean
  ) {
    this.maxLength = maxLength;
    this.includeCapitalLetter = includeCapitalLetter;
    this.includeNumber = includeNumber;
    this.includeSpecial = includeSpecial;
  }

  private generateOptionsSum(): number {
    let sum = 0;
    if (this.includeNumber) {
      sum = sum + IncludeOption.INCLUDE_NUMBER;
    }

    if (this.includeCapitalLetter) {
      sum = sum + IncludeOption.INCLUDE_CAPITAL_LETTER;
    }

    if (this.includeSpecial) {
      sum = sum + IncludeOption.INCLUDE_SPECIAL;
    }

    return sum;
  }

  generate0() {
    let result = '';
    let prev: Flag[] | null = null;
    let isFirst = true;

    let requested: number = this.generateOptionsSum();

    let shouldBe = (Math.random() < 0.5) ? Flag.VOWEL : Flag.CONSONANT;

    while (result.length < this.maxLength) {
      const i: number = Math.floor((ELEMENTS.length - 1) * Math.random());
      const element = ELEMENTS[i];
      let value = element.value;
      const flags: Flag[] = element.flags;
      const isDipthongFlag: boolean = flags.find( flag => flag === Flag.DIPTHONG ) !== undefined;
      const isVowelFlag: boolean = flags.find( flag => flag === Flag.VOWEL ) !== undefined;
      const isNotFirstFlag: boolean = flags.find( flag => flag === Flag.NOT_FIRST ) !== undefined;
      const isConsonantFlag: boolean = flags.find( flag => flag === Flag.CONSONANT ) !== undefined;

      const isShouldBeFlag: boolean = flags.find( flag => flag === shouldBe ) !== undefined;

      const isPrevVowel: boolean = prev?.find(flag => flag === Flag.VOWEL) !== undefined;

      /* Filter on the basic type of the next element */
      if (!isShouldBeFlag) {
        continue;
      }
      /* Handle the NOT_FIRST flag */
      if (isFirst && isNotFirstFlag) {
        continue;
      }
      /* Don't allow VOWEL followed a Vowel / Dipthong pair */
      if (isPrevVowel === true && isVowelFlag === true && isDipthongFlag === true) {
        continue;
      }
      /* Don't allow us to overflow the buffer */
      if ((result.length + value.length) > this.maxLength) {
        continue;
      }


      if (requested & IncludeOption.INCLUDE_CAPITAL_LETTER) {
        if ((isFirst || isConsonantFlag) && (Math.random() > 0.3)) {
          value = value.slice(0, 1).toUpperCase() + value.slice(1, value.length);
          requested &= ~IncludeOption.INCLUDE_CAPITAL_LETTER;
        }
      }

      /*
       * OK, we found an element which matches our criteria,
       * let's do it!
       */
      result += value;


      if (requested & IncludeOption.INCLUDE_NUMBER) {
        if (!isFirst && (Math.random() < 0.3)) {
          if ((result.length + value.length) > this.maxLength) {
            result = result.slice(0, -1);
          }
          result += Math.floor(10 * Math.random()).toString();
          requested &= ~IncludeOption.INCLUDE_NUMBER;

          isFirst = true;
          prev = null;
          shouldBe = (Math.random() < 0.5) ? Flag.VOWEL : Flag.CONSONANT;
          continue;
        }
      }


      if (requested & IncludeOption.INCLUDE_SPECIAL) {
        if (!isFirst && (Math.random() < 0.3)) {
          if ((result.length + value.length) > this.maxLength) {
            result = result.slice(0, -1);
          }
          const possible = '!@()-_+?=/:\'';
          result += possible.charAt(Math.floor(Math.random() * possible.length));
          requested &= ~IncludeOption.INCLUDE_SPECIAL;

          isFirst = true;
          prev = null;
          shouldBe = (Math.random() < 0.5) ? Flag.VOWEL : Flag.CONSONANT;
          continue;
        }
      }

      /*
       * OK, figure out what the next element should be
       */
      if (shouldBe === Flag.CONSONANT) {
        shouldBe = Flag.VOWEL;
      } else { /* should_be == VOWEL */
        if (isPrevVowel || isDipthongFlag || (Math.random() > 0.3)) {
          shouldBe = Flag.CONSONANT;
        } else {
          shouldBe = Flag.VOWEL;
        }
      }
      prev = flags;
      isFirst = false;
    }

    if (requested & (IncludeOption.INCLUDE_NUMBER | IncludeOption.INCLUDE_SPECIAL | IncludeOption.INCLUDE_CAPITAL_LETTER)) {
      return null;
    }

    return result;
  }

  generate() {
    let result = null;
    while (!result) {
      result = this.generate0();
    }

    return result;
  }
}
