import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeleteGrid from '../reducers/delete-grid.reducer';

export const selectDeleteGridState = createFeatureSelector<fromDeleteGrid.State>(
  fromDeleteGrid.deleteGridFeatureKey
);

export const getDeleteGridRecord = createSelector(
  selectDeleteGridState,
  (state) => state.record
);

export const getDeleteGridLoaded = createSelector(
  selectDeleteGridState,
  (state) => state.loaded
);

export const getDeleteGridLoading = createSelector(
  selectDeleteGridState,
  (state) => state.loading
);

export const getDeleteGridError = createSelector(
  selectDeleteGridState,
  (state) => state.error
);
