import { MaterialModule } from './material/material.module';
import { EntityManageButtonModule } from './entity-manage-button/entity-manage-button.module';
import { EntitySelectFormModule } from './entity-select-form/entity-select-form.module';
import { ProductionStatusModule } from './production-status/production-status.module';

export const modules = [
  MaterialModule,
  EntityManageButtonModule,
  ProductionStatusModule,
  EntitySelectFormModule,
];
