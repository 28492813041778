import { createAction, props } from '@ngrx/store';

export const updateCluster = createAction(
  '[UpdateCluster] Update Cluster',
  props<{ data: any }>()
);

export const updateClusterSuccess = createAction(
  '[UpdateCluster] Update Clusters Success',
  props<{ data: any }>()
);

export const updateClusterFailure = createAction(
  '[UpdateCluster] Update Cluster Failure',
  props<{ error: any }>()
);

export const clearUpdateClusterrState = createAction(
  '[UpdateCluster] clear Update Cluster State',
);
