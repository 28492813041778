import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UserDeleteActions from '../actions/user-delete.actions';
import { UsersService } from '../../../services/api/users.service';
import * as OrganizationsActions from '../../organizations/actions/organizations.actions';
import * as CurrentOrganizationActions from '../../organizations/actions/current-organization.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';



@Injectable()
export class UserDeleteEffects {

  userDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDeleteActions.userDelete),
      concatMap((payload) =>
        this.usersService.deleteUser(payload.data).pipe(
          map(data => UserDeleteActions.userDeleteSuccess({ data })),
          catchError(error => of(UserDeleteActions.userDeleteFailure({ error }))))
      )
    );
  });

  userDeleteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserDeleteActions.userDeleteSuccess),
      tap(() => this.snackBar.open('User successfully deleted', 'Success', {duration: 3000})),
      map(() => {
        if (this.router.url.includes('/organizations')) {
          return OrganizationsActions.loadOrganizations();
        } else {
          return CurrentOrganizationActions.loadCurrentOrganization();
        }
      }),
    );
  });



  constructor(private actions$: Actions,
              private usersService: UsersService,
              private router: Router,
              private snackBar: MatSnackBar) {}

}
