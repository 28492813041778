import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ResetPasswordActions from '../actions/reset-password.actions';
import { AuthService } from '../../../../services/api/auth.service';

@Injectable()
export class ResetPasswordEffects {

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResetPasswordActions.resetPasswords),
      concatMap((payload) =>
        this.authService.setNewPassword(payload.data).pipe(
          map(data => ResetPasswordActions.resetPasswordsSuccess(data)),
          catchError(error => of(ResetPasswordActions.resetPasswordsFailure(error))))
      )
    );
  });

  constructor(private actions$: Actions,
              private authService: AuthService) {}

}
