import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUpdateDestination from '../reducers/update-destination.reducer';

export const selectUpdateDestinationState = createFeatureSelector<fromUpdateDestination.State>(
  fromUpdateDestination.updateDestinationFeatureKey
);

export const getUpdateDestinationRecord = createSelector(
  selectUpdateDestinationState,
  (state) => state.record
);

export const getUpdateDestinationLoaded = createSelector(
  selectUpdateDestinationState,
  (state) => state.loaded
);

export const getUpdateDestinationLoading = createSelector(
  selectUpdateDestinationState,
  (state) => state.loading
);

export const getUpdateDestinationError = createSelector(
  selectUpdateDestinationState,
  (state) => state.error
);
