import {ActionReducer, createReducer, on} from '@ngrx/store';
import * as ChartTimeActions from '../actions/chart-time.actions';
import { Time } from 'src/app/services/api/statistics/types/time.statistics.parameter.type';

export const chartTimeFeatureKey = 'chartTime';

export interface State {
  time: Time;
}

export const initialState: State = {
  time: '1d',
};

export const reducer: ActionReducer<State> = createReducer(
  initialState,

  on(ChartTimeActions.setChartTime, (state: State, action): {time: Time} => ({
    time: action.data,
  })),
  on(ChartTimeActions.clearChartTimeState, (): {time: Time} => ({
    time: '1d',
  })),
);
