import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromResetPassword from '../reducers/reset-password.reducer';

export const selectResetPasswordState = createFeatureSelector<fromResetPassword.State>(
  fromResetPassword.resetPasswordFeatureKey
);

export const getResetPasswordRecord = createSelector(
  selectResetPasswordState,
  (state) => state.record
);

export const getResetPasswordLoaded = createSelector(
  selectResetPasswordState,
  (state) => state.loaded
);

export const getResetPasswordLoading = createSelector(
  selectResetPasswordState,
  (state) => state.loading
);

export const getResetPasswordError = createSelector(
  selectResetPasswordState,
  (state) => state.error
);
