import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as StopDestinationActions from '../actions/stop-destination.actions';
import * as DestinationsActions from '../actions/destinations.actions';
import { DestinationsService } from '../../../services/api/destinations/destinations.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class StopDestinationEffects {

  stopDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StopDestinationActions.stopDestination),
      concatMap((payload) =>
        this.destinationsService.stopDestination(payload.data).pipe(
          map(data => StopDestinationActions.stopDestinationSuccess({ data })),
          catchError(error => of(StopDestinationActions.stopDestinationFailure({ error }))))
      )
    );
  });

  startDestinationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StopDestinationActions.stopDestinationSuccess),
      tap(() => this.snackBar.open('Destination stopped successfully', 'Success', {duration: 3000})),
      map(() => DestinationsActions.loadDestinations()),
    );
  });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private destinationsService: DestinationsService) {}

}
