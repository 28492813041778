import { createAction, props } from '@ngrx/store';

export const loadIncomeBandwidth = createAction(
  '[IncomeBandwidth] Load Income Bandwidth'
);

export const loadIncomeBandwidthSuccess = createAction(
  '[IncomeBandwidth] Load Income Bandwidth Success',
  props<{ data: any }>()
);

export const loadIncomeBandwidthFailure = createAction(
  '[IncomeBandwidth] Load Income Bandwidth Failure',
  props<{ error: any }>()
);

export const clearIncomeBandwidthState = createAction(
  '[IncomeBandwidth] clear Income Bandwidth State',
);
