import { createReducer, on } from '@ngrx/store';
import * as UpdateDestinationActions from '../actions/update-destination.actions';

export const updateDestinationFeatureKey = 'updateDestination';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(UpdateDestinationActions.updateDestination, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(UpdateDestinationActions.updateDestinationSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(UpdateDestinationActions.updateDestinationFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(UpdateDestinationActions.clearUpdateDestinationState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

