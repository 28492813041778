import { createAction, props } from '@ngrx/store';

export const resetPasswords = createAction(
  '[ResetPassword] Reset Passwords',
  props<{ data: any }>()
);

export const resetPasswordsSuccess = createAction(
  '[ResetPassword] Reset Passwords Success',
  props<{ data: any }>()
);

export const resetPasswordsFailure = createAction(
  '[ResetPassword] Reset Passwords Failure',
  props<{ error: any }>()
);

export const clearState = createAction(
  '[ResetPassword] clear Reset Passwords State',
);
