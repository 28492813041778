import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ApiService {

  private readonly baseUrl: string;
  private options: { headers?: HttpHeaders, withCredentials: boolean } = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    withCredentials: false
  };

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
  }

  public get<T = any>(url: string, params = {}, options = {}): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {...this.options, ...options, params});
  }

  public post<T = any>(url: string, params = {}): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, params, this.options);
  }

  public put<T = any>(url: string, params: Record<string, any> | FormData = {}, options = this.options): Observable<T> {
    if (params instanceof FormData) {
      options = {
        headers: new HttpHeaders({}),
        withCredentials: false
      };
    }
    return this.http.put<T>(this.baseUrl + url, params, options);
  }

  delete<T = any>(url: string, params = {}): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, {...this.options, params});
  }

  public download(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(this.baseUrl + url, {observe: 'response', responseType: 'blob'});
  }
}
