import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromCurrentUser from '../store/users/reducers/current-user.reducer';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {getCurrentUserRecord} from '../store/users/selectors/current-user.selectors';
import {User} from '../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private readonly store: Store<fromCurrentUser.State>,
    private readonly router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const feature = route.data['feature'];
    const mode = route.data['permissionMode'];
    return this.checkPermission(feature, mode)
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }

  private checkPermission(feature: string, mode: string) {
    return this.store.select(getCurrentUserRecord)
      .pipe(
        tap((user: User) => {
          if (!user.permissions[feature][mode]) {
            if (user.permissions['streams']['read']) {
              this.router.navigate(['']);
            } else {
              this.router.navigate(['/account']);
            }
          }
        }),
        filter((user: User) => user.permissions[feature][mode]),
        take(1)
      );
  }
}
