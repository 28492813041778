import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AuthService} from '../../services/api/auth.service';
import {User} from '../../models/user.interface';
import {loadOrganizations} from '../../store/organizations/actions/organizations.actions';
import {Store} from '@ngrx/store';
import {Organization} from '../../models/organization.interface';
import {environment} from '../../../environments/environment';
import {NavbarMenuItem, NavbarMenusConfig} from './navbar.menus.config';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnChanges, OnInit {
  @Input() currentUser: User;
  @Input() organizationsRecord: Organization[];
  @Input() organizationId: string;
  @Output() sidenavToggle: EventEmitter<void> = new EventEmitter();
  @Output() chooseOrganization: EventEmitter<any> = new EventEmitter();
  public menusConfig: NavbarMenuItem[] = NavbarMenusConfig;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly router: Router,
  ) {
  }

  public get pictureUrl(): string | undefined {
    if (this.currentUser.picture === null) {
      return;
    }

    const fileName = this.currentUser.picture;
    const storageUrl = environment.fileStorageUrl;
    return `${storageUrl}/users/${fileName}`;
  }

  public ngOnInit(): void {
    this.menusConfig
      .map((menu: NavbarMenuItem): void => {
        const iconName: string = menu.icon;
        this.addMenuIcon(iconName);

        if (menu.code === 'logout') {
          menu.handler = () => this.authService.logout();
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentUser'] && this.currentUser) {
      if (this.currentUser.role.value === 10) {
        this.store.dispatch(loadOrganizations());
      }
    }
  }

  public onChangeOrganization(organizationId: number) {
    this.chooseOrganization.emit({organizationId});
  }

  private addMenuIcon(icon: string): void {
    const iconPath: string = `/assets/vvcr-icons/${icon}.svg`;
    const safeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iconPath);
    this.iconRegistry.addSvgIcon(icon, safeUrl);
  }

  public onMenuClick(menu: NavbarMenuItem): void {
    if (menu.path) {
      this.router.navigate([menu.path])
        .catch((error) => console.error(`Navigate to ${menu.path} error:`, error));
      return;
    }

    if (menu.handler) {
      menu.handler();
      return;
    }
  }

  public isShowMenuItem(menuItem: NavbarMenuItem): boolean {
    const roleId: number = this.currentUser.role.value;
    const accessRoleIds: number[] = menuItem.access;
    return accessRoleIds.includes(roleId);
  }
}
