import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DeleteAccountActions from '../actions/delete-account.actions';
import { AccountService } from '../../../services/api/account.service';
import { AuthService } from '../../../services/api/auth.service';



@Injectable()
export class DeleteAccountEffects {

  deleteAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteAccountActions.deleteAccount),
      concatMap(() =>
        this.accountService.deleteAccount().pipe(
          map(data => DeleteAccountActions.deleteAccountSuccess({ data })),
          catchError(error => of(DeleteAccountActions.deleteAccountFailure({ error }))))
      )
    );
  });

  deleteAccountSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteAccountActions.deleteAccountSuccess),
      tap(() => this.authService.logout()),
    );
  }, { dispatch: false });


  constructor(private actions$: Actions,
              private accountService: AccountService,
              private authService: AuthService) {}

}
