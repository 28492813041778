import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {InputInterface} from '../../../../../models/input.interface';
import {Destination} from '../../../../../models/destination.interface';
import {InstanceRole} from '../../../../../services/api/streams/streams.service';

export interface SelectedEntity {
  type: 'stream' | 'destination';
  entityId: string;
  inputId: string;
  clusterId: string;
  instanceRole: InstanceRole;
}


@Component({
  selector: 'app-entity-select-form',
  templateUrl: './entity-select-form.component.html',
  styleUrls: ['./entity-select-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySelectFormComponent {
  @Input() mode: string;
  @Input() index: number;
  @Input() entityControl: any;
  @Input() entity: any;
  @Input() validationRequired: boolean;
  @Input() inputsRecord: InputInterface[];
  @Input() inputsLoading: boolean;
  @Input() destinations: Destination[];
  @Input() destinationsLoading: boolean;
  @Output() changeEntity: EventEmitter<SelectedEntity> = new EventEmitter<SelectedEntity>();
  @Output() mouseEnter: EventEmitter<any> = new EventEmitter();

  public onChangeType(): void {
    this.entityControl.removeControl('type');
    this.entityControl.removeControl('order');
    this.entityControl.removeControl('entityId');
    this.entityControl.removeControl('clusterId');
    this.entityControl.removeControl('role');
    this.entityControl.removeControl('currentEntity');
  }

  public onEntityChange(entity: SelectedEntity): void {
    this.changeEntity.emit(entity);
  }
}
