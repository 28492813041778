import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class StatisticsApiService {

  private baseUrl: string;
  private options: { headers?: HttpHeaders, withCredentials: boolean } = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    withCredentials: false
  };

  constructor(private http: HttpClient) {
    this.baseUrl = environment.statisticsBaseUrl;
  }

  get(url: string, params = {}, options = {}): Observable<any> {
    return this.http.get(this.baseUrl + url, {...this.options, ...options, params});
  }
}
