import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DeviceService} from './services/device.service';
import {Store} from '@ngrx/store';
import * as fromCurrentUser from './store/users/reducers/current-user.reducer';
import {getCurrentUserRecord} from './store/users/selectors/current-user.selectors';
import {User} from './models/user.interface';
import {changeOrganization} from './store/organizations/actions/change-organization.actions';
import {WebStorageService} from './services/web-storage.service';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';
import {getOrganizationsRecord} from './store/organizations/selectors/organizations.selectors';
import {Organization} from './models/organization.interface';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLayoutComponent implements OnInit {
  currentUser$: Observable<User>;
  organizationsRecord$: Observable<Organization[]>;
  device$: Observable<string>;
  organizationId: string;

  constructor(
    private deviceService: DeviceService,
    private store: Store<fromCurrentUser.State>,
    private storageService: WebStorageService,
    @Inject(DOCUMENT) private doc: any
  ) {
    this.currentUser$ = this.store.select(getCurrentUserRecord);
    this.organizationsRecord$ = this.store.select(getOrganizationsRecord);
    this.device$ = this.deviceService.getDevice();
    this.setGA();
  }

  ngOnInit(): void {
    this.organizationId = this.storageService.getItem('organizationId');
  }

  onActivate() {
    document.querySelector('mat-sidenav-content').scrollTo(0, 0);
  }

  chooseOrganization(data) {
    this.store.dispatch(changeOrganization({data}));
  }

  private setGA() {
    if (environment.env === 'prod') {
      const head = this.doc.getElementsByTagName('head')[0];
      const s = this.doc.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://www.googletagmanager.com/gtag/js?id=G-YPTK4501VY';
      head.appendChild(s);

      const s2 = this.doc.createElement('script');
      s2.type = 'text/javascript';
      s2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-YPTK4501VY');
    `;
      head.appendChild(s2);
    }
  }
}

