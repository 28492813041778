import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromVmixServers from '../reducers/vmix-servers.reducer';

export const selectVmixServersState = createFeatureSelector<fromVmixServers.State>(
  fromVmixServers.vmixServersFeatureKey
);

export const getVmixServersRecord = createSelector(
  selectVmixServersState,
  (state) => state.record
);

export const getVmixServersLoaded = createSelector(
  selectVmixServersState,
  (state) => state.loaded
);

export const getVmixServersLoading = createSelector(
  selectVmixServersState,
  (state) => state.loading
);

export const getVmixServersError = createSelector(
  selectVmixServersState,
  (state) => state.error
);
