import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ProductionInstanceStatus } from '../../../services/api/production-instances/production.instance.status.type';

@Component({
  selector: 'app-production-status-container',
  template: `<app-production-status [status]="status"></app-production-status>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionStatusContainerComponent {
  @Input() status: ProductionInstanceStatus;
}
