import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ValidateStreamkeyActions from '../actions/validate-streamkey.actions';
import { InputsService } from '../../../services/api/inputs.service';



@Injectable()
export class ValidateStreamkeyEffects {

  validateStreamkey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValidateStreamkeyActions.validateStreamkey),
      concatMap((payload) =>
        this.inputsService.validateStreamkey(payload.data).pipe(
          map(data => ValidateStreamkeyActions.validateStreamkeySuccess({ data })),
          catchError(error => of(ValidateStreamkeyActions.validateStreamkeyFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private inputsService: InputsService) {}

}
