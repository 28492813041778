import { createAction, props } from '@ngrx/store';
import {DeleteClusterRequest} from '../../../services/api/clusters/models/cluster.create.model';

export const deleteCluster = createAction(
  '[DeleteCluster] Load DeleteClusters',
  props<{ data: DeleteClusterRequest }>()
);

export const deleteClusterSuccess = createAction(
  '[DeleteCluster] Delete Cluster Success',
  props<{ data: any }>()
);

export const deleteClusterFailure = createAction(
  '[DeleteCluster] Delete Cluster Failure',
  props<{ error: any }>()
);

export const clearDeleteClusterState = createAction(
  '[DeleteCluster] clear Delete Cluster State',
);
