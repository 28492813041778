import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeleteInput from '../reducers/delete-input.reducer';

export const selectDeleteInputState = createFeatureSelector<fromDeleteInput.State>(
  fromDeleteInput.deleteInputFeatureKey
);

export const getDeleteInputRecord = createSelector(
  selectDeleteInputState,
  (state) => state.record
);

export const getDeleteInputLoaded = createSelector(
  selectDeleteInputState,
  (state) => state.loaded
);

export const getDeleteInputLoading = createSelector(
  selectDeleteInputState,
  (state) => state.loading
);

export const getDeleteInputError = createSelector(
  selectDeleteInputState,
  (state) => state.error
);
