import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStatisticsCluster from '../reducers/statistics-cluster.reducer';

export const selectStatisticsClusterState = createFeatureSelector<fromStatisticsCluster.State>(
  fromStatisticsCluster.statisticsClusterFeatureKey
);

export const getStatisticsClusterRecord = createSelector(
  selectStatisticsClusterState,
  (state: fromStatisticsCluster.State) => state.clusterId
);
