import { createAction, props } from '@ngrx/store';

export const loadCurrentOrganization = createAction(
  '[CurrentOrganization] Load Current Organization'
);

export const loadCurrentOrganizationSuccess = createAction(
  '[CurrentOrganization] Load Current Organization Success',
  props<{ data: any }>()
);

export const loadCurrentOrganizationFailure = createAction(
  '[CurrentOrganization] Load Current Organization Failure',
  props<{ error: any }>()
);

export const clearCurrentOrganizationState = createAction(
  '[CurrentOrganization] Clear Current Organization State',
);
