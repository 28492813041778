import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromInputs from '../reducers/inputs.reducer';

export const selectInputsState = createFeatureSelector<fromInputs.State>(
  fromInputs.inputsFeatureKey
);

export const getInputsRecord = createSelector(
  selectInputsState,
  (state) => state.record
);

export const getInputsLoaded = createSelector(
  selectInputsState,
  (state) => state.loaded
);

export const getInputsLoading = createSelector(
  selectInputsState,
  (state) => state.loading
);

export const getInputsError = createSelector(
  selectInputsState,
  (state) => state.error
);
