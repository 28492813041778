import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUsersRoles from '../reducers/users-roles.reducer';

export const selectUsersRolesState = createFeatureSelector<fromUsersRoles.State>(
  fromUsersRoles.usersRolesFeatureKey
);

export const getUsersRolesRecord = createSelector(
  selectUsersRolesState,
  (state) => state.record
);

export const getUsersRolesLoaded = createSelector(
  selectUsersRolesState,
  (state) => state.loaded
);

export const getUsersRolesLoading = createSelector(
  selectUsersRolesState,
  (state) => state.loading
);

export const getUsersRolesError = createSelector(
  selectUsersRolesState,
  (state) => state.error
);
