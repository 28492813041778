import { createAction, props } from '@ngrx/store';
import {StorageSettings} from '../../../models/dashboard.interface';

export const setStorageSettings = createAction(
  '[StorageSettings] set Storage Settings',
  props<{ data: StorageSettings }>()
);

export const clearStorageSettingsState = createAction(
  '[StorageSettings] clear Storage Settings State',
);
