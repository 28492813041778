import { createAction, props } from '@ngrx/store';

export const loadPaymentInfo = createAction(
  '[PaymentInfo] Load Payment Info'
);

export const loadPaymentInfoSuccess = createAction(
  '[PaymentInfo] Load Payment Info Success',
  props<{ data: any }>()
);

export const loadPaymentInfoFailure = createAction(
  '[PaymentInfo] Load Payment Info Failure',
  props<{ error: any }>()
);

export const clearPaymentInfoState = createAction(
  '[PaymentInfo] clear Payment Info State',
);
