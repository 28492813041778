import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserDelete from '../reducers/user-delete.reducer';

export const selectUserDeleteState = createFeatureSelector<fromUserDelete.State>(
  fromUserDelete.userDeleteFeatureKey
);

export const getUserDeleteRecord = createSelector(
  selectUserDeleteState,
  (state) => state.record
);

export const getUserDeleteLoaded = createSelector(
  selectUserDeleteState,
  (state) => state.loaded
);

export const getUserDeleteLoading = createSelector(
  selectUserDeleteState,
  (state) => state.loading
);

export const getUserDeleteError = createSelector(
  selectUserDeleteState,
  (state) => state.error
);
