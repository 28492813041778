<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.subtitle}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close
          *ngIf="data.cancelBtnText">{{data.cancelBtnText | titlecase}}</button>
  <button mat-button mat-dialog-close
          *ngIf="data.okBtnText" (click)="onOkClick()">{{data.okBtnText | titlecase}}</button>
  <button mat-raised-button color="primary"
          *ngIf="data.primaryBtn" (click)="onOkClick()">{{data.primaryBtn}}</button>
</div>
