import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CurrentUserActions from '../actions/current-user.actions';
import { UsersService } from '../../../services/api/users.service';
import { WebStorageService } from '../../../services/web-storage.service';


@Injectable()
export class CurrentUserEffects {

  loadCurrentUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CurrentUserActions.loadCurrentUser),
      concatMap(() =>
        this.usersService.getUser().pipe(
          map(data => {
            if (data.data.organizations.length === 1) {
              this.webStorageService.setItem('organizationId', data.data.organizations[0].id);
            }
            return CurrentUserActions.loadCurrentUserSuccess({ data });
          }),
          catchError(error => of(CurrentUserActions.loadCurrentUserFailure({ error }))))
      )
    );
  });

  constructor(private actions$: Actions,
              private usersService: UsersService,
              private webStorageService: WebStorageService) {}

}
