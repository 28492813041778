import { createAction, props } from '@ngrx/store';

export const startDestination = createAction(
  '[StartDestination] Start Destination',
  props<{ data: any }>()
);

export const startDestinationSuccess = createAction(
  '[StartDestination] Start Destination Success',
  props<{ data: any }>()
);

export const startDestinationFailure = createAction(
  '[StartDestination] Start Destination Failure',
  props<{ error: any }>()
);

export const clearStartDestinationState = createAction(
  '[StartDestination] clear Start Destination State',
);
