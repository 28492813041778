import { createAction, props } from '@ngrx/store';

export const setBandwidthSettings = createAction(
  '[BandwidthSettings] Set Bandwidth Settings',
  props<{ data: any }>()
);

export const clearBandwidthSettingsState = createAction(
  '[BandwidthSettings] clear Bandwidth Settings State',
);
