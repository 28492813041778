import {
  Input,
  Output,
  EventEmitter,
  Component,
  ElementRef,
  ViewChild, OnDestroy, ChangeDetectionStrategy, OnChanges, SimpleChanges, HostListener,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var videojs;

@Component({
  selector: 'app-videojs-trimming-player',
  templateUrl: './videojs-trimming-player.component.html',
  styleUrls: ['./videojs-trimming-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideojsTrimmingPlayerComponent implements OnChanges, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;
  @Input() fps: number;
  @Input() options;
  @Input() startPoint;
  @Input() endPoint;
  @Output() startClick = new EventEmitter<number>();
  @Output() thumbClick = new EventEmitter<number>();
  @Output() endClick = new EventEmitter<number>();
  player: any;

  constructor(private snackBar: MatSnackBar,
              private elem: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && this.options) {
      if (this.player) {
        this.updatePlayer();
      } else {
        this.initPlayer();
      }
    }
  }

  initPlayer() {
    this.player = videojs(this.target.nativeElement, this.options);
    this.determinePlayerSize();
    this.createButton();
    this.player.markers({
      markerStyle: {
        width: '2px',
        'border-radius': '0%',
        'background-color': 'red',
      },
      markerTip: { display: false },
      breakOverlay: { display: false },
    });
    this.player.on('playing', () => {
      if (this.startPoint && this.endPoint) {
        this.addMarkersOnEditClip();
      }
    });
  }

  addMarkersOnEditClip() {
    this.player.markers.add([
      {
        time: this.startPoint,
        class: 'marker-start',
      },
      {
        time: this.endPoint,
        class: 'marker-end',
      },
    ]);
    this.addRangeMarker();
  }

  updatePlayer() {
    this.player.markers.removeAll();
    this.startPoint = '';
    this.endPoint = '';
    this.player.src({type: this.options.sources[0].type, src: this.options.sources[0].src});
  }

  createButton() {
    const customDiv = document.createElement('div');
    customDiv.className = 'custom-controls';
    customDiv.onmousedown = () => {
      return false;
    };
    customDiv.onselectstart = () => {
      return false;
    };

    const secBack = document.createElement('a');
    secBack.className = 'revind-back-sec video-ctr-custom-button';
    secBack.addEventListener('click', () => {
      self.handlePreviousSecond(self);
    });
    const backSpan = document.createElement('span');
    backSpan.className = 'material-icons mr';
    backSpan.innerHTML = 'fast_rewind';
    const backSpanText = document.createElement('span');
    backSpanText.innerHTML = '1 sec';
    secBack.appendChild(backSpan);
    secBack.appendChild(backSpanText);

    const secForward = document.createElement('a');
    secForward.className = 'revind-forward-sec video-ctr-custom-button';
    secForward.text = '1 sec';
    secForward.addEventListener('click', () => {
      self.handleNextSecond(self);
    });
    const forwardIcon = document.createElement('span');
    forwardIcon.className = 'material-icons ml';
    forwardIcon.innerHTML = 'fast_forward';
    secForward.appendChild(forwardIcon);

    const frameBack = document.createElement('a');
    frameBack.className = 'revind-back-frame video-ctr-custom-button';
    frameBack.addEventListener('click', () => {
      self.handlePreviousFrame(self);
    });

    const backSpanIcon = document.createElement('span');
    backSpanIcon.className = 'material-icons';
    backSpanIcon.innerHTML = 'arrow_left';
    backSpanIcon.style.fontSize = '29px';
    const backSpanTextFrame = document.createElement('span');
    backSpanTextFrame.innerHTML = '1 frame';
    frameBack.appendChild(backSpanIcon);
    frameBack.appendChild(backSpanTextFrame);

    const frameForward = document.createElement('a');
    frameForward.className = 'revind-forward-frame video-ctr-custom-button';
    frameForward.text = '1 frame';
    frameForward.addEventListener('click', () => {
      self.handleNextFrame(self);
    });
    const forwardSpanIcon = document.createElement('span');
    forwardSpanIcon.className = 'material-icons';
    forwardSpanIcon.innerHTML = 'arrow_right';
    forwardSpanIcon.style.fontSize = '29px';
    frameForward.appendChild(forwardSpanIcon);

    const self = this;

    const rewindToStart = document.createElement('div');
    const rewindToStartA = document.createElement('a');
    rewindToStart.appendChild(rewindToStartA);
    rewindToStart.className = 'player-action-btn rewind-to-start';
    rewindToStart.addEventListener('click', () => {
      self.rewindToStart(self);
    });

    const start = document.createElement('div');
    const startA = document.createElement('a');
    start.appendChild(startA);
    start.className = 'player-action-btn start';
    start.addEventListener('click', () => {
      self.handleSetStart(self);
    });

    const end = document.createElement('div');
    const endA = document.createElement('a');
    end.appendChild(endA);
    end.className = 'player-action-btn end';
    end.addEventListener('click', () => {
      self.handleSetEnd(self);
    });

    const rewindToEnd = document.createElement('div');
    const rewindToEndA = document.createElement('a');
    rewindToEnd.appendChild(rewindToEndA);
    rewindToEnd.className = 'player-action-btn rewind-to-end';
    rewindToEnd.addEventListener('click', () => {
      self.rewindToEnd(self);
    });

    const rewindBlock = document.createElement('div');
    rewindBlock.className = 'rewind-block';
    rewindBlock.appendChild(secBack);
    rewindBlock.appendChild(frameBack);
    rewindBlock.appendChild(frameForward);
    rewindBlock.appendChild(secForward);
    customDiv.appendChild(rewindBlock);

    const actionsBlock = document.createElement('div');
    actionsBlock.className = 'actions-block';

    actionsBlock.appendChild(rewindToStart);
    actionsBlock.appendChild(start);
    actionsBlock.appendChild(end);
    actionsBlock.appendChild(rewindToEnd);
    customDiv.appendChild(actionsBlock);

    this.player.controlBar
      .el()
      .insertBefore(
        customDiv,
        this.player.controlBar.el().lastChild.nextSibling
      );
  }

  determinePlayerSize() {
    const videoContainer = document.getElementsByClassName('app-select-segment-video')[0] as HTMLElement;
    const indexWidth = Math.trunc(videoContainer.clientWidth / 16);
    const indexHeight = Math.trunc((window.innerHeight - 300) / 9);
    let indexSize = Math.min(indexWidth, indexHeight);
    indexSize = indexSize > 50 ? indexSize : 50;
    videoContainer.style.minWidth =  (indexSize * 16) + 'px';
    videoContainer.style.width =  (indexSize * 16) + 'px';
    videoContainer.style.minHeight =  (indexSize * 9) + 'px';
    videoContainer.style.height =  (indexSize * 9) + 'px';
  }

  handleSetStart(self) {
    const time = self.player.currentTime();
    if (self.endPoint && time > self.endPoint) {
      self.snackBar.open('Start point should be less then the end point', '', {
        panelClass: 'error-snackbar',
        duration: 3000,
      });
      return;
    }
    if (self.endPoint !== undefined && time === self.endPoint) {
      self.snackBar.open(`Start point shouldn't be the same as end point`, '', {
        panelClass: 'error-snackbar',
        duration: 3000,
      });
      return;
    }
    const className = 'marker-start';
    const markers = self.player.markers.getMarkers();
    if (markers.length) {
      const indexes = [];
      markers.forEach((mark, index) => {
        if (mark.class === className) {
          indexes.push(index);
        }
        if (mark.class === 'marker-range') {
          indexes.push(index);
        }
      });
      self.player.markers.remove(indexes);
    }
    self.startPoint = time;
    self.player.markers.add([
      {
        time: self.startPoint,
        class: className,
      },
    ]);
    self.startClick.emit(self.startPoint);
    self.addRangeMarker();
  }

  handleSetEnd(self) {
    const time = self.player.currentTime();
    if (self.startPoint && self.startPoint > time) {
      self.snackBar.open('End point should be more then the start point', '', {
        panelClass: 'error-snackbar',
        duration: 3000,
      });
      return;
    }
    if (self.startPoint !== undefined && self.startPoint === time) {
      self.snackBar.open(`End point shouldn't be the same as start point`, '', {
        panelClass: 'error-snackbar',
        duration: 3000,
      });
      return;
    }
    const className = 'marker-end';
    const markers = self.player.markers.getMarkers();
    if (markers.length) {
      const indexes = [];
      markers.forEach((mark, index) => {
        if (mark.class === className) {
          indexes.push(index);
        }
        if (mark.class === 'marker-range') {
          indexes.push(index);
        }
      });
      self.player.markers.remove(indexes);
    }
    self.endPoint = time;
    self.player.markers.add([
      {
        time: self.endPoint,
        class: className,
      },
    ]);
    self.endClick.emit(self.endPoint);
    self.addRangeMarker();
  }

  addRangeMarker() {
    const markers = this.player.markers.getMarkers();
    if (markers.length < 2) {
      return;
    }

    const end = this.elem.nativeElement.querySelector('.marker-end');
    const start = this.elem.nativeElement.querySelector('.marker-start');
    if (!end || !start) {
      return;
    }

    const left = start['offsetLeft'];
    const right = end['offsetLeft'];
    const width = right - left;

    this.player.markers.add([
      {
        time: this.startPoint,
        class: 'marker-range',
      },
    ]);
    const range = this.elem.nativeElement.querySelector('.marker-range');
    const ofWidth = width + 'px';
    range.style.setProperty('width', ofWidth.toString());
  }

  handlePreviousFrame(self) {
    const timePerFrame = Math.round((100 / this.fps)) / 100;
    const decimal = timePerFrame < 0.1 ? 2 : 1;
    const fixed = parseFloat(timePerFrame.toFixed(decimal));
    self.player.currentTime(self.player.currentTime() - fixed);
  }

  handleNextFrame(self) {
    const timePerFrame = Math.round((100 / this.fps)) / 100;
    const decimal = timePerFrame < 0.1 ? 2 : 1;
    const fixed = parseFloat(timePerFrame.toFixed(decimal));
    self.player.currentTime(self.player.currentTime() + fixed);
  }

  handleNextSecond(self) {
    self.player.currentTime(self.player.currentTime() + 1);
  }

  handlePreviousSecond(self) {
    self.player.currentTime(self.player.currentTime() - 1);
  }

  rewindToStart(self) {
    self.player.currentTime(0);
  }

  rewindToEnd(self) {
    self.player.currentTime(self.player.duration());
  }

  @HostListener('document:keydown.space', ['$event'])
  onKeydownSpaceHandler(event: KeyboardEvent) {
    if (this.player) {
      if (this.player.paused()) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

}
