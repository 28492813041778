import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromClusters from '../reducers/clusters.reducer';

export const selectClustersState = createFeatureSelector<fromClusters.State>(
  fromClusters.clustersFeatureKey
);

export const getClustersRecord = createSelector(
  selectClustersState,
  (state) => state.record
);

export const getClustersLoaded = createSelector(
  selectClustersState,
  (state) => state.loaded
);

export const getClustersLoading = createSelector(
  selectClustersState,
  (state) => state.loading
);

export const getClustersError = createSelector(
  selectClustersState,
  (state) => state.error
);
