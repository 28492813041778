import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {
  createClipJobStatus, createDestinationStatus, createDvrRecordStatus, createStreamerHardwareUsage, createStreamStatus, deleteClipJobStatus,
  deleteDestinationStatus, deleteDvrRecordStatus, deleteStreamerHardwareUsage, deleteStreamStatus, filterLiveMonitoringByClusterId,
  loadLiveMonitoring, loadLiveMonitoringSuccess, updateClipJobStatus, updateDestinationStatus, updateDvrRecordStatus,
  updateStreamerHardwareUsage, updateStreamStatus
} from './live-monitoring.actions';
import {LiveMonitoringService} from '../../../services/api/live-monitoring/live-monitoring.service';
import {LiveMonitoring} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.interface';
import {LiveMonitoringStatuses} from '../../../services/api/live-monitoring/interfaces/live-monitoring.statuses.interface';
import {StreamStatus} from '../../../services/api/live-monitoring/streams-statuses/stream.status.interface';
import {
  LiveMonitoringStreamsStatistics
} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.streams.statistics.interface';
import {DestinationStatus} from '../../../services/api/live-monitoring/destination-statuses/destination.status.interface';
import {
  LiveMonitoringDestinationsStatistics
} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.destinations.statistics.interface';
import {
  StreamerHardwareUsageStatus
} from '../../../services/api/live-monitoring/streamer-hardware-usage/streamer-hardware-usage.status.interface';
import {
  LiveMonitoringHardwareUsageStatistics
} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.hardware-usage.statistics.interface';
import {ProductionInstanceStatus} from '../../../services/api/live-monitoring/interfaces/production-instance.status.interface';
import {ClipJobStatus} from '../../../services/api/live-monitoring/clip-jobs-statuses/clip-job.status.interface';
import {DvrRecordStatus} from '../../../services/api/live-monitoring/dvr-record-statuses/dvr-record.status.interface';
import {Observable} from 'rxjs';
import {TypedAction} from '@ngrx/store/src/models';

@Injectable()
export class LiveMonitoringEffects {
  public clusterId: string | 'all' = 'all';

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly service: LiveMonitoringService
  ) {
  }

  // noinspection JSUnusedGlobalSymbols
  loadLiveMonitoring$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(loadLiveMonitoring),
        concatMap(() => this.loadLiveMonitoringStatistics())
      )
  );

  // noinspection JSUnusedGlobalSymbols
  filterLiveMonitoringByClusterId$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(filterLiveMonitoringByClusterId),
        switchMap((action: { clusterId: string | 'all' }) => {
          this.clusterId = action.clusterId;
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  /* Streamer hardware usage */

  // noinspection JSUnusedGlobalSymbols
  createStreamerHardwareUsage$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(createStreamerHardwareUsage),
        switchMap((action: { data: StreamerHardwareUsageStatus }) => {
          this.service.createStreamerHardwareUsage(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  updateStreamerHardwareUsage$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(updateStreamerHardwareUsage),
        switchMap((action: { data: StreamerHardwareUsageStatus }) => {
          this.service.updateStreamerHardwareUsage(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  deleteStreamerHardwareUsage$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(deleteStreamerHardwareUsage),
        switchMap((action: { instanceId: string }) => {
          this.service.deleteStreamerHardwareUsage(action.instanceId);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  /* Stream statuses */

  // noinspection JSUnusedGlobalSymbols
  createStreamStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(createStreamStatus),
        switchMap((action: { data: StreamStatus }) => {
            this.service.createStreamStatus(action.data);
            return this.loadLiveMonitoringStatistics();
          }
        )
      )
  );

  // noinspection JSUnusedGlobalSymbols
  updateStreamStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(updateStreamStatus),
        switchMap((action: { data: StreamStatus }) => {
          this.service.updateStreamStatus(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  deleteStreamStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(deleteStreamStatus),
        switchMap((action: { inputId: string }) => {
          this.service.deleteStreamStatus(action.inputId);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  /* Destination statuses */

  // noinspection JSUnusedGlobalSymbols
  createDestinationStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(createDestinationStatus),
        switchMap((action: { data: DestinationStatus }) => {
          this.service.createDestinationStatus(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  updateDestinationStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(updateDestinationStatus),
        switchMap((action: { data: DestinationStatus }) => {
          this.service.updateDestinationStatus(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  deleteDestinationStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(deleteDestinationStatus),
        switchMap((action: { destinationId: string }) => {
          this.service.deleteDestinationStatus(action.destinationId);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  /* Clip job statuses */

  // noinspection JSUnusedGlobalSymbols
  createClipJobStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(createClipJobStatus),
        switchMap((action: { data: ClipJobStatus }) => {
          this.service.createClipJobStatus(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  updateClipJobStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(updateClipJobStatus),
        switchMap((action: { data: ClipJobStatus }) => {
          this.service.updateClipJobStatus(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  deleteClipJobStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(deleteClipJobStatus),
        switchMap((action: { clipJobId: string }) => {
          this.service.deleteClipJobStatus(action.clipJobId);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  /* DVR records statuses */

  // noinspection JSUnusedGlobalSymbols
  createDvrRecordStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(createDvrRecordStatus),
        switchMap((action: { data: DvrRecordStatus }) => {
          this.service.createDvrRecordStatus(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  updateDvrRecordStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(updateDvrRecordStatus),
        switchMap((action: { data: DvrRecordStatus }) => {
          this.service.updateDvrRecordStatus(action.data);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );

  // noinspection JSUnusedGlobalSymbols
  deleteDvrRecordStatus$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(deleteDvrRecordStatus),
        switchMap((action: { recordId: string }) => {
          this.service.deleteDvrRecordStatus(action.recordId);
          return this.loadLiveMonitoringStatistics();
        })
      )
  );


  private loadLiveMonitoringStatistics(): Observable<{ data: LiveMonitoring } & TypedAction<'[Live Monitoring] Load Monitoring Success'>> {
    return this.service.getStatistics()
      .pipe(
        map((response: LiveMonitoringStatuses) => {
          const liveMonitoring: LiveMonitoring = this.parseLiveMonitoringStatuses(response);
          return loadLiveMonitoringSuccess({data: liveMonitoring});
        })
      );
  }

  private filterResponseByClusterId(response: LiveMonitoringStatuses, clusterId: string): LiveMonitoringStatuses {
    const streams: StreamStatus[] =
      response.streams
        .filter(stream => stream.clusterId === clusterId);
    const destinations: DestinationStatus[] =
      response.destinations
        .filter(destination => destination.clusterId === clusterId);
    const productionInstances: ProductionInstanceStatus[] =
      response.productionInstances
        .filter(instance => instance.clusterId === clusterId);
    const clipJobs: ClipJobStatus[] =
      response.clipJobs
        .filter(clipJob => clipJob.clusterId === clusterId);
    const dvrRecords: DvrRecordStatus[] =
      response.dvrRecords
        .filter(dvrRecord => dvrRecord.clusterId === clusterId);
    const streamersHardwareUsage: StreamerHardwareUsageStatus[] =
      response.streamersHardwareUsage
        .filter(streamer => streamer.clusterId === clusterId);

    return {
      streams,
      destinations,
      productionInstances,
      clipJobs,
      dvrRecords,
      streamersHardwareUsage
    };
  }

  private parseStreamsStatuses(streamStatuses: StreamStatus[], isActive: boolean): LiveMonitoringStreamsStatistics {
    const statuses: StreamStatus[] = streamStatuses.filter(status => status.isActive === isActive);
    const streamsCount: number = statuses.length;

    /* Incoming streams */
    const incomingStreams: StreamStatus[] = statuses.filter(status => status.direction === 'incoming');
    const incomingStreamsCount: number = incomingStreams.length;
    const rtmpIn = incomingStreams.filter(status => status.protocol === 'RTMP').length;
    const srtIn = incomingStreams.filter(status => status.protocol === 'SRT').length;
    const ndiIn = incomingStreams.filter(status => status.protocol === 'NDI').length;

    /* Outgoing streams */
    const outgoingStreams: StreamStatus[] = statuses.filter(status => status.direction === 'outgoing');
    const rtmpOut = outgoingStreams.filter(status => status.protocol === 'RTMP').length;
    const srtOut = outgoingStreams.filter(status => status.protocol === 'SRT').length;
    const hlsOut = outgoingStreams.filter(status => status.protocol === 'HLS').length;

    const total: number = isActive ? streamsCount : incomingStreamsCount;
    return {
      total,
      rtmpIn,
      rtmpOut,
      hlsOut,
      srtIn,
      srtOut,
      ndiIn
    };
  }

  private parseDestinationsStatuses(destinationStatuses: DestinationStatus[], isActive: boolean): LiveMonitoringDestinationsStatistics {
    const statuses: DestinationStatus[] = destinationStatuses.filter(status => status.isActive === isActive);
    const total: number = statuses.length;
    const rtmp: number = statuses.filter(status => status.protocol === 'rtmp').length;
    const srt: number = statuses.filter(status => status.protocol === 'srt').length;
    const vvcrStream: number = statuses.filter(status => status.protocol === 'vvcr-stream').length;

    return {
      total,
      rtmp,
      srt,
      vvcrStream
    };
  }

  private parseHardwareUsageStatuses(statuses: StreamerHardwareUsageStatus[]): LiveMonitoringHardwareUsageStatistics[] {
    return statuses.map(streamer => {
      return {
        name: streamer.name,
        cpu: streamer.cpu,
        memory: streamer.memory
      };
    });
  }

  private parseLiveMonitoringStatuses(statuses: LiveMonitoringStatuses): LiveMonitoring {
    if (this.clusterId !== 'all') {
      statuses = this.filterResponseByClusterId(statuses, this.clusterId);
    }

    const activeStreams: LiveMonitoringStreamsStatistics =
      this.parseStreamsStatuses(statuses.streams, true);
    const inactiveStreams: LiveMonitoringStreamsStatistics =
      this.parseStreamsStatuses(statuses.streams, false);

    const activeDestinations: LiveMonitoringDestinationsStatistics =
      this.parseDestinationsStatuses(statuses.destinations, true);
    const inactiveDestinations: LiveMonitoringDestinationsStatistics =
      this.parseDestinationsStatuses(statuses.destinations, false);

    const streamersHardwareUsage: LiveMonitoringHardwareUsageStatistics[] =
      this.parseHardwareUsageStatuses(statuses.streamersHardwareUsage);

    const activeProductionInstances: number =
      statuses.productionInstances.filter(instance => instance.isActive === true).length;
    const inactiveProductionInstances: number =
      statuses.productionInstances.filter(instance => instance.isActive === false).length;

    const activeClipJobs: number =
      statuses.clipJobs.filter(job => job.isActive === true).length;
    const inactiveClipJobs: number =
      statuses.clipJobs.filter(job => job.isActive === false).length;

    const activeDvrRecords: number =
      statuses.dvrRecords.filter(record => record.isActive === true).length;
    const inactiveDvrRecords: number =
      statuses.dvrRecords.filter(record => record.isActive === false).length;

    return {
      active: {
        streams: activeStreams,
        destinations: activeDestinations,
        streamersHardwareUsage,
        productionInstances: activeProductionInstances,
        clipJobs: activeClipJobs,
        dvrRecords: activeDvrRecords
      },
      inactive: {
        streams: inactiveStreams,
        destinations: inactiveDestinations,
        productionInstances: inactiveProductionInstances,
        clipJobs: inactiveClipJobs,
        dvrRecords: inactiveDvrRecords
      }
    };
  }
}
