import { createAction, props } from '@ngrx/store';

export const validateShortname = createAction(
  '[ValidateShortname] Validate Shortname',
  props<{ data: any }>()
);

export const validateShortnameSuccess = createAction(
  '[ValidateShortname] Validate Shortname Success',
  props<{ data: any }>()
);

export const validateShortnameFailure = createAction(
  '[ValidateShortname] Validate Shortname Failure',
  props<{ error: any }>()
);

export const clearvalidateShortnameState = createAction(
  '[ValidateShortname] clear Validate Shortname State',
);
