import { createAction, props } from '@ngrx/store';

export const loadOutcomeBandwidth = createAction(
  '[OutcomeBandwidth] Load Outcome Bandwidth'
);

export const loadOutcomeBandwidthSuccess = createAction(
  '[OutcomeBandwidth] Load Outcome Bandwidth Success',
  props<{ data: any }>()
);

export const loadOutcomeBandwidthFailure = createAction(
  '[OutcomeBandwidth] Load Outcome Bandwidth Failure',
  props<{ error: any }>()
);

export const clearOutcomeBandwidthState = createAction(
  '[OutcomeBandwidth] clear Outcome Bandwidth State',
);
