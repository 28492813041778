import {ApiService} from '../api.service';
import {LiveMonitoringStatuses} from './interfaces/live-monitoring.statuses.interface';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {StreamerHardwareUsageStatus} from './streamer-hardware-usage/streamer-hardware-usage.status.interface';
import {StreamStatus} from './streams-statuses/stream.status.interface';
import {DestinationStatus} from './destination-statuses/destination.status.interface';
import {ClipJobStatus} from './clip-jobs-statuses/clip-job.status.interface';
import {DvrRecordStatus} from './dvr-record-statuses/dvr-record.status.interface';

@Injectable({providedIn: 'root'})
export class LiveMonitoringService {
  public $statuses: BehaviorSubject<LiveMonitoringStatuses | null> = new BehaviorSubject<LiveMonitoringStatuses | null>(null);

  constructor(
    private apiService: ApiService
  ) {
  }

  public getStatistics(): Observable<LiveMonitoringStatuses> {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      return of(statuses);
    }
    return this.apiService.get<LiveMonitoringStatuses>('/live-monitoring')
      .pipe(
        map((response: LiveMonitoringStatuses) => {
          this.$statuses.next(response);
          return response;
        })
      );
  }

  /* Streamer hardware usage */
  public createStreamerHardwareUsage(data: StreamerHardwareUsageStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.streamersHardwareUsage.push(data);
      this.$statuses.next(statuses);
    }
  }

  public updateStreamerHardwareUsage(data: StreamerHardwareUsageStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      const statusIndex: number = statuses.streamersHardwareUsage
        .findIndex(status => status.id === data.id);

      if (statusIndex !== -1) {
        /* Statuses object is read-only, so make writable copy */
        const statusesAsText: string = JSON.stringify(statuses);
        const statusesCopy: LiveMonitoringStatuses = JSON.parse(statusesAsText);

        const status: StreamerHardwareUsageStatus = statusesCopy.streamersHardwareUsage[statusIndex];
        status.cpu = data.cpu;
        status.memory = data.memory;
        this.$statuses.next(statusesCopy);
      }
    }
  }

  public deleteStreamerHardwareUsage(instanceId: string): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.streamersHardwareUsage = statuses.streamersHardwareUsage
        .filter((item: StreamerHardwareUsageStatus) => item.id !== instanceId);
      this.$statuses.next(statuses);
    }
  }

  /* Stream statuses */
  public createStreamStatus(data: StreamStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();

    if (statuses) {
      statuses.streams.push(data);
      this.$statuses.next(statuses);
    }
  }

  public updateStreamStatus(data: StreamStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();

    if (statuses) {
      const statusIndex: number = statuses.streams
        .findIndex((item: StreamStatus) =>
          item.id === data.id &&
          item.protocol.toLowerCase() === data.protocol.toLowerCase() &&
          item.direction === data.direction
        );

      if (statusIndex === -1) {
        console.error(
          'Stream status not found' +
          '\nIncoming status: ', data,
          '\nStatuses: ', statuses
        );
      }

      if (statusIndex !== -1) {
        /* Statuses object is read-only, so make writable copy */
        const statusesAsText: string = JSON.stringify(statuses);
        const statusesCopy: LiveMonitoringStatuses = JSON.parse(statusesAsText);

        const status: StreamStatus = statusesCopy.streams[statusIndex];
        status.isActive = data.isActive;

        if (data.updatedProtocol) {
          status.protocol = data.updatedProtocol;
        }

        this.$statuses.next(statusesCopy);
      }
    }
  }

  public deleteStreamStatus(inputId: string): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.streams = statuses.streams
        .filter((item: StreamStatus) => item.id !== inputId);
      this.$statuses.next(statuses);
    }
  }

  /* Destination statuses */
  public createDestinationStatus(data: DestinationStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.destinations.push(data);
      this.$statuses.next(statuses);
    }
  }

  public updateDestinationStatus(data: DestinationStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      const statusIndex: number = statuses.destinations
        .findIndex((item: DestinationStatus) => item.id === data.id);

      if (statusIndex === -1) {
        console.error(
          'Destination status not found' +
          '\nIncoming status: ', data,
          '\nStatuses: ', statuses
        );
      }

      if (statusIndex !== -1) {
        /* Statuses object is read-only, so make writable copy */
        const statusesAsText: string = JSON.stringify(statuses);
        const statusesCopy: LiveMonitoringStatuses = JSON.parse(statusesAsText);

        const status: DestinationStatus = statusesCopy.destinations[statusIndex];
        status.isActive = data.isActive;

        this.$statuses.next(statusesCopy);
      }
    }
  }

  public deleteDestinationStatus(destinationId: string): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.destinations = statuses.destinations
        .filter((item: DestinationStatus) => item.id !== destinationId);
      this.$statuses.next(statuses);
    }
  }

  /* Clip jobs statuses */
  public createClipJobStatus(data: ClipJobStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.clipJobs.push(data);
      this.$statuses.next(statuses);
    }
  }

  public updateClipJobStatus(data: ClipJobStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();

    if (statuses) {
      const statusIndex: number = statuses.clipJobs
        .findIndex(status => status.id === data.id);

      if (statusIndex !== -1) {
        /* Statuses object is read-only, so make writable copy */
        const statusesAsText: string = JSON.stringify(statuses);
        const statusesCopy: LiveMonitoringStatuses = JSON.parse(statusesAsText);

        const status: ClipJobStatus = statusesCopy.clipJobs[statusIndex];
        status.isActive = data.isActive;
        this.$statuses.next(statusesCopy);
      }
    }
  }

  public deleteClipJobStatus(clipJobId: string): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.clipJobs = statuses.clipJobs
        .filter((item: ClipJobStatus) => item.id !== clipJobId);
      this.$statuses.next(statuses);
    }
  }

  /* DVR records statuses */
  public createDvrRecordStatus(data: DvrRecordStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.dvrRecords.push(data);
      this.$statuses.next(statuses);
    }
  }

  public updateDvrRecordStatus(data: DvrRecordStatus): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      const statusIndex: number = statuses.dvrRecords
        .findIndex(status => status.id === data.id);

      if (statusIndex !== -1) {
        /* Statuses object is read-only, so make writable copy */
        const statusesAsText: string = JSON.stringify(statuses);
        const statusesCopy: LiveMonitoringStatuses = JSON.parse(statusesAsText);

        const status: DvrRecordStatus = statusesCopy.dvrRecords[statusIndex];
        status.isActive = data.isActive;
        this.$statuses.next(statusesCopy);
      }
    }
  }

  public deleteDvrRecordStatus(dvrRecordId: string): void {
    const statuses: LiveMonitoringStatuses | null = this.$statuses.getValue();
    if (statuses) {
      statuses.dvrRecords = statuses.dvrRecords
        .filter((item: DvrRecordStatus) => item.id !== dvrRecordId);
      this.$statuses.next(statuses);
    }
  }
}
