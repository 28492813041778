import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Cluster} from '../../../../../models/cluster.interface';
import {Router} from '@angular/router';
import {ModalComponent} from '../../../../components/modal/modal.component';
import {MatDialog} from '@angular/material/dialog';
import {InputInterface} from '../../../../../models/input.interface';
import {User} from '../../../../../models/user.interface';

interface EntityConfiguration {
  name: string;
  url?: string | undefined;
  btnTitle: string;
}

interface EntityConfigurations {
  [key: string]: EntityConfiguration;
}

@Component({
  selector: 'app-entity-manage-button',
  templateUrl: './entity-manage-button.component.html',
  styleUrls: ['./entity-manage-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityManageButtonComponent implements OnChanges {
  @Input() entity: string;
  @Input() inputs: InputInterface[];
  @Input() clustersRecord: Cluster[];
  @Input() currentUser: User;
  @Output() clickEmit: EventEmitter<void> = new EventEmitter();
  public disabledBtn = false;
  public configurations: EntityConfigurations = {
    stream: {
      name: 'Stream',
      url: '/streams/new',
      btnTitle: 'Add New Stream'
    },
    destination: {
      name: 'Destination',
      url: '/destinations/new',
      btnTitle: 'Add New Destination'
    },
    grid: {
      name: 'Multiview',
      url: '/multiview/new',
      btnTitle: 'Add New Multiview'
    },
    dvr: {
      name: 'DVR',
      btnTitle: 'Add New Recording'
    },
    clip: {
      name: 'Clip',
      url: '/clips/new',
      btnTitle: 'Create A Clip'
    },
    production: {
      name: 'Production Instance',
      url: '/production/new',
      btnTitle: 'Add New Instance'
    }
  };

  constructor(
    public dialog: MatDialog,
    private readonly router: Router
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['entity'] || changes['inputs']) {
      this.disabledBtn = this.entity === 'stream' &&
        this.inputs && this.inputs.length >= 10 &&
        this.currentUser.currentOrganization.licence_id === 2;
    }
  }

  public onClick(): void {
    const isHaveActiveClusters: boolean = this.isHaveActiveClusters();
    if (isHaveActiveClusters === false) {
      this.showNoAvailableClustersModal();
      return;
    }

    const configuration: EntityConfiguration = this.configurations[this.entity];
    const url: string | undefined = configuration.url || undefined;

    if (!url) {
      this.clickEmit.emit();
      return;
    }

    this.router
      .navigate([url])
      .catch(error => console.error(`Navigate to ${url} error:`, error));
  }

  private isHaveActiveClusters(): boolean {
    const isEmptyClusters: boolean = !!this.clustersRecord && this.clustersRecord.length === 0;
    if (isEmptyClusters) {
      return false;
    }

    return this.clustersRecord &&
      this.clustersRecord.length > 0 &&
      this.clustersRecord.some((cluster: Cluster) => cluster.status === 'active');
  }

  private showNoAvailableClustersModal(): void {
    const resourceName: string = this.configurations[this.entity].name.toLowerCase();
    const dialogRef = this.dialog
      .open(ModalComponent, {
        width: '300px',
        autoFocus: false,
        data: {
          title: 'No available clusters',
          subtitle: `Please create or run at least one cluster in your account in order to create ${resourceName}.`,
          primaryBtn: 'Create a cluster',
          payload: true
        }
      });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.router.navigate(['/clusters/new'])
            .catch(error => console.error('Navigate to create new cluster page error:', error));
        }
      });
  }

}
