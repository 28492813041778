import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, concatMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as StartRecordActions from '../actions/start-record.actions';
import {DvrService} from '../../../services/api/dvr/dvr.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as RecordsActions from '../actions/records.actions';
import {StartDvrRecordPayload} from '../../../services/api/dvr/interfaces/start-dvr-record-payload.interface';


@Injectable()
export class StartRecordEffects {

  startRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StartRecordActions.startRecord),
      concatMap((payload: { data: StartDvrRecordPayload }) =>
        this.dvrService.startRecord(payload.data)
          .pipe(
            map(data => StartRecordActions.startRecordSuccess({data})),
            catchError(error => of(StartRecordActions.startRecordFailure({error})))
          )
      )
    );
  });

  startRecordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StartRecordActions.startRecordSuccess),
      tap(() => this.snackBar.open('Recording started successfully', 'Success', {duration: 3000})),
      map(() => RecordsActions.loadRecords())
    );
  });


  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private dvrService: DvrService
  ) {
  }

}
