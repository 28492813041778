import { createAction, props } from '@ngrx/store';

export const loadTranscodingProfiles = createAction(
  '[TranscodingProfiles] Load Transcoding Profiles'
);

export const loadTranscodingProfilesSuccess = createAction(
  '[TranscodingProfiles] Load Transcoding Profiles Success',
  props<{ data: any }>()
);

export const loadTranscodingProfilesFailure = createAction(
  '[TranscodingProfiles] Load Transcoding Profiles Failure',
  props<{ error: any }>()
);

export const clearTranscodingProfilesState = createAction(
  '[TranscodingProfiles] clear Transcoding Profiles State',
);
