export interface MenuItem {
  code: string;
  label: string;
  path?: string;
  icon: string;
  submenus?: MenuItem[];
  access?: number[];
  envAccess?: string[];
  allowedLicenses?: number[];
}
export const MENUS: MenuItem[] = [
  {
    code: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
    icon: 'Dashboard',
    access: [1, 5, 10],
    allowedLicenses: [1],
  },
  {
    code: 'clusters',
    label: 'Clusters',
    path: '/clusters',
    icon: 'Cloud',
    access: [1, 5, 10],
    allowedLicenses: [1]
  },
  {
    code: 'streams',
    label: 'Streams',
    path: '/streams',
    icon: 'Stream',
    access: [1, 5, 10],
    allowedLicenses: [1, 2]
  },
  {
    code: 'destinations',
    label: 'Destinations',
    path: '/destinations',
    icon: 'Destination',
    access: [1, 5, 10],
    allowedLicenses: [1, 2]
  },
  {
    code: 'multiview',
    label: 'Multiview',
    path: '/multiview',
    icon: 'Grid',
    access: [1, 5, 10],
    allowedLicenses: [1, 2]
  },
  {
    code: 'dvr',
    label: 'DVR',
    path: '/dvr',
    icon: 'DVR',
    access: [1, 5, 10],
    allowedLicenses: [1]
  },
  {
    code: 'clippost',
    label: 'Clip & Post',
    path: '/clips',
    icon: 'Clippost',
    access: [1, 5, 10],
    allowedLicenses: [1]
  },
  {
    code: 'kairos',
    label: 'Kairos',
    path: '/kairos',
    icon: 'KairosCores',
    access: [1, 5, 10],
    allowedLicenses: [1]
  },
  {
    code: 'device-management-system',
    label: 'Devices',
    path: '/device-management-systems',
    icon: 'KairosDeviceManagement',
    access: [1, 5, 10],
    allowedLicenses: [1]
  },
  {
    code: 'production',
    label: 'Production',
    path: '/production',
    icon: 'Production',
    access: [1, 5, 10],
    allowedLicenses: [1]
  }
];
