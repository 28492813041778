import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGrid from '../reducers/grid.reducer';

export const selectGridState = createFeatureSelector<fromGrid.State>(
  fromGrid.gridFeatureKey
);

export const getGridRecord = createSelector(
  selectGridState,
  (state) => state.record
);

export const getUpdatedGridRecord = createSelector(
  selectGridState,
  (state) => state.updatedRecord
);

export const getGridLoaded = createSelector(
  selectGridState,
  (state) => state.loaded
);

export const getGridLoading = createSelector(
  selectGridState,
  (state) => state.loading
);

export const getUpdatedVisibilityRecord = createSelector(
  selectGridState,
  (state) => state.updatedVisibility
);

export const getGridError = createSelector(
  selectGridState,
  (state) => state.error
);
