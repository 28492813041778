import {RtmpAuthSchemePayload} from '../interfaces/rtmp-auth-scheme.payload.interface';
import {RtmpAuthScheme} from '../interfaces/rtmp-auth-scheme.interface';

export function payloadToModel(payload: RtmpAuthSchemePayload[]): RtmpAuthScheme[] {
  const models: RtmpAuthScheme[] = payload.map(item => {
    return {
      id: item.id,
      name: item.name,
      label: item.label,
      auth: item.auth,
      streamId: item.streamId
    };
  });

  /* Set None and Default schemes first in list */
  const result: RtmpAuthScheme[] = [];

  const noneScheme: RtmpAuthScheme | undefined = models.find(model => model.label === 'None');
  if (noneScheme !== undefined) {
    result.push(noneScheme);
    models.splice(models.indexOf(noneScheme), 1);
  }

  const defaultScheme: RtmpAuthScheme | undefined = models.find(model => model.label === 'Default');
  if (defaultScheme !== undefined) {
    result.push(defaultScheme);
    models.splice(models.indexOf(defaultScheme), 1);
  }

  result.push(...models);

  return result;
}
