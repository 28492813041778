import { createReducer, on } from '@ngrx/store';
import * as ResetPasswordActions from '../actions/reset-password.actions';

export const resetPasswordFeatureKey = 'resetPassword';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(ResetPasswordActions.resetPasswords, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(ResetPasswordActions.resetPasswordsSuccess, (state, action) => ({
    record: action.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(ResetPasswordActions.resetPasswordsFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(ResetPasswordActions.clearState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
