import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MenuItem, MENUS} from './menus.config';
import {User} from '../../models/user.interface';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavComponent implements OnDestroy {
  @Input() currentUser: User;
  @Output() sidenavToggle: EventEmitter<void> = new EventEmitter();
  menus: MenuItem[];
  isOpenSubmenu = false;
  routerSubscription: Subscription;
  env = environment.env;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.menus = MENUS;
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.url;
        this.isOpenSubmenu = url.startsWith('/organizations') ||
          url.startsWith('/organization') ||
          url.startsWith('/account') ||
          url.startsWith('/usage') ||
          url.startsWith('/payment');
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public isShowMenuItem(menuItem: MenuItem): boolean {
    const roleId: number = this.currentUser.role.value;
    const accessRoleIds: number[] = menuItem.access;
    const isHaveRoleAccess: boolean = accessRoleIds.includes(roleId);

    let isHaveEnvironmentAccess = true;
    if (menuItem.envAccess && menuItem.envAccess.length > 0) {
      isHaveEnvironmentAccess = menuItem.envAccess.includes(this.env);
    }

    const licenceId: number = this.currentUser.currentOrganization.licence_id;
    const allowedLicenses: number[] = menuItem.allowedLicenses;
    const isHaveLicenseAccess: boolean = allowedLicenses.includes(licenceId);

    let isHaveModuleAccess = true;
    if (menuItem.code === 'kairos') {
      isHaveModuleAccess = this.currentUser.currentOrganization.isKairosModuleEnabled === true;
    }

    if (menuItem.code === 'device-management-system') {
      isHaveModuleAccess = this.currentUser.currentOrganization.isDevicesManagementModuleEnabled === true;
    }

    return isHaveRoleAccess && isHaveLicenseAccess && isHaveModuleAccess && isHaveEnvironmentAccess;
  }

  public getIconPath(menuItem: MenuItem): string {
    return `/assets/vvcr-icons/${menuItem.icon}.svg`;
  }
}
