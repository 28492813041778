import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {InputInterface} from '../../../../../models/input.interface';
import {Cluster} from '../../../../../models/cluster.interface';
import {Instance} from '../../../../../models/instances.interface';
import {map, startWith} from 'rxjs/operators';
import {FormControl, Validators} from '@angular/forms';
import {InstanceRole} from '../../../../../services/api/streams/streams.service';
import {SelectedEntity} from '../entity-select-form/entity-select-form.component';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSelectComponent implements OnChanges {
  @Input() entityControl;
  @Input() entity: any;
  @Input() index: number;
  @Input() validationRequired: boolean;
  @Input() inputsRecord: InputInterface[] = [];
  @Input() filterdInputsId;
  @Input() lableForUsedInput;
  @Output() public changeEntity: EventEmitter<SelectedEntity> = new EventEmitter<SelectedEntity>();
  @Output() mouseEnter: EventEmitter<any> = new EventEmitter();
  showInputDetails = false;
  filteredOptions: Observable<InputInterface[]>;
  public inputClusters: Cluster[] = [];
  public currentClusterInstances: Instance[] = [];
  currentInstance: Instance;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['entityControl'] && this.entityControl) {
      this.entityControl.addControl('type', new FormControl('stream'));
      this.entityControl.addControl('order', new FormControl(this.index));
      this.entityControl.addControl('entityId', new FormControl(''));
      this.entityControl.addControl('clusterId', new FormControl(''));
      this.entityControl.addControl('role', new FormControl(''));
      this.entityControl.addControl('currentEntity', new FormControl(''));
      this.setValidators();
    }

    if (this.inputsRecord) {
      this.filteredOptions = this.entityControl.get('currentEntity').valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value.name),
          map((name: string) => name ? this._filter(name) : this.inputsRecord.slice())
        );
    }

    if ((changes['entity'] || changes['inputsRecord']) && this.entity && this.inputsRecord) {
      const savedEntity: InputInterface | undefined = this.inputsRecord.find((input: InputInterface): boolean => input.id === this.entity.entityId);
      if (savedEntity) {
        this.entityControl.get('currentEntity').setValue(savedEntity);
        this.entityControl.get('entityId').setValue(this.entityControl.get('currentEntity').value.id);
        this.entityControl.get('clusterId').setValue(this.entity.clusterId);
        this.entityControl.get('role').setValue(this.entity.role);
      }
    }

    if (this.entityControl.get('currentEntity').value) {
      this.inputClusters = this.entityControl.get('currentEntity').value.clusters;
      this.showInputDetails = true;
      this.onChangeCluster();
    }
  }

  setValidators() {
    if (this.validationRequired) {
      this.entityControl.get('currentEntity').setValidators([Validators.required]);
    }
  }

  displayFn(input: InputInterface): string {
    return input && input.inputName ? input.inputName : '';
  }

  public onSelectInput(): void {
    const control = this.entityControl.get('currentEntity');
    const currentEntity: InputInterface = control.value;

    this.inputClusters = currentEntity.clusters;

    const entityId: string = currentEntity.id;
    this.entityControl.get('entityId').setValue(entityId);

    const currentCluster: Cluster | undefined = this.inputClusters[0];
    const clusterId: string = currentCluster.id;
    this.entityControl.get('clusterId').setValue(clusterId);

    const currentInstance: Instance = currentCluster.instances[0];
    const instanceRole: InstanceRole = currentInstance.role as InstanceRole;

    this.entityControl.get('role').setValue(instanceRole);
    control.setErrors(null);
    this.showInputDetails = true;

    this.emitChangeEntity();
    this.onChangeCluster();
  }

  onTypingInput() {
    this.entityControl.controls['currentEntity'].setErrors({incorrect: true});
    this.showInputDetails = false;
  }

  onInputCancelClick(event: Event) {
    event.stopPropagation();
    this.entityControl.get('entityId').setValue('');
    this.entityControl.get('clusterId').setValue('');
    this.entityControl.get('role').setValue('');
    this.entityControl.get('currentEntity').setValue('');
    this.showInputDetails = false;
  }

  public onChangeCluster(): void {
    if (this.inputClusters && this.inputClusters.length > 0) {
      const clusterId: string = this.entityControl.get('clusterId').value;
      const currentCluster: Cluster | undefined = this.inputClusters.find((cluster: Cluster): boolean => cluster.id === clusterId);
      if (currentCluster) {
        this.currentClusterInstances = currentCluster.instances;

        const instanceRole: InstanceRole = this.entityControl.get('role').value;
        this.currentInstance = this.currentClusterInstances.find((instance: Instance): boolean => instance.role === instanceRole);
        this.emitChangeEntity();
      }
    }
  }

  private _filter(name: string): InputInterface[] {
    const filterValue = name.toLowerCase();

    return this.inputsRecord.filter(option => option.inputName.toLowerCase().indexOf(filterValue) === 0);
  }

  private emitChangeEntity(): void {
    const inputId: string = this.entityControl.get('entityId').value;
    const clusterId: string = this.entityControl.get('clusterId').value;
    const instanceRole: InstanceRole = this.entityControl.get('role').value;

    const selectedEntity: SelectedEntity = {
      type: 'stream',
      entityId: inputId,
      inputId: inputId,
      clusterId: clusterId,
      instanceRole: instanceRole
    };
    this.changeEntity.emit(selectedEntity);
  }

}
