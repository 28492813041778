import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, first } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';

import * as StorageActions from '../actions/storage.actions';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../../services/api/dashboard.service';
import { getChartTimeRecord } from '../selectors/chart-time.selectors';
import { getStatisticsClusterRecord } from '../selectors/statistics-cluster.selectors';
import {StorageStatistics} from '../../../services/api/statistics/storages/storages.statistic.model';



@Injectable()
export class StorageEffects {

  // noinspection JSUnusedGlobalSymbols
  loadStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.loadStorage),
      switchMap(() =>
        forkJoin(
          this.store.select(getChartTimeRecord).pipe(first()),
          this.store.select(getStatisticsClusterRecord).pipe(first())
        ).pipe(
          first(),
          concatMap(([time, clusterId]) =>
            this.dashboardService.getStorage(time, clusterId)
              .pipe(
                map((data: StorageStatistics) => StorageActions.loadStorageSuccess({ data })),
                catchError(error => of(StorageActions.loadStorageFailure({ error })))
              )
          )
        )
      )
    );
  });



  constructor(private actions$: Actions,
              private store: Store,
              private dashboardService: DashboardService) {}

}
