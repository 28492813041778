import {Provider} from '@angular/core';
import {matPaginatorOptions} from './mat-paginator';
import {matSelectOptions} from './mat-select';
import {matSlideOptions} from './mat-slide';

export const materialComponentsOptions: Provider[] = [
  matPaginatorOptions,
  matSelectOptions,
  matSlideOptions
]
