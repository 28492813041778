<span class="status" matTooltip="{{tooltips[status]}}" matTooltipPosition="above">
  <span *ngIf="status === 'recording' || status === 'completed'; else textStatus"
        [ngStyle]="{'background-color':colors[status] ? colors[status] : status }"
        [ngClass]="{
            'dot': true,
            'flikering-dot': status === 'recording'
              }">

  </span>
  <ng-template #textStatus>{{tooltips[status]}}</ng-template>
</span>
