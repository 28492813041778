import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter, take, tap} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {loadInputs} from '../../../store/inputs/actions/inputs.actions';
import {getInputsLoaded, getInputsLoading, getInputsRecord} from '../../../store/inputs/selectors/inputs.selectors';
import {
  getDestinationsLoaded,
  getDestinationsLoading,
  getDestinationsRecord
} from '../../../store/destinations/selectors/destinations.selectors';
import {InputInterface} from '../../../models/input.interface';
import {Destination} from '../../../models/destination.interface';
import {loadDestinations} from '../../../store/destinations/actions/destinations.actions';
import {SelectedEntity} from './components/entity-select-form/entity-select-form.component';

@Component({
  selector: 'app-entity-select-form-container',
  template: `
    <app-entity-select-form [mode]="mode"
                            [index]="index"
                            [entityControl]="entityControl"
                            [entity]="entity"
                            [validationRequired]="validationRequired"
                            [inputsRecord]="inputsRecord$ | async"
                            [inputsLoading]="inputsLoading$ | async"
                            [destinations]="destinations$ | async"
                            [destinationsLoading]="destinationsLoading$ | async"
                            (changeEntity)="onEntityChange($event)"
                            (mouseEnter)="mouseEnter.emit($event)"></app-entity-select-form>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySelectFormContainerComponent {
  @Input() mode = 'all'; // all, streams, destinations
  @Input() index: number;
  @Input() entityControl;
  @Input() entity: any;
  @Input() validationRequired = false;
  @Output() changeEntity: EventEmitter<SelectedEntity> = new EventEmitter<SelectedEntity>();
  @Output() mouseEnter: EventEmitter<any> = new EventEmitter();
  // Inputs
  inputsRecord$: Observable<InputInterface[]>;
  inputsLoading$: Observable<boolean>;
  // Destinations
  destinations$: Observable<Destination[]>;
  destinationsLoading$: Observable<boolean>;

  constructor(private store: Store) {
    this.checkInputs();
    this.checkDestinations();
  }

  checkInputs() {
    this.inputsRecord$ = this.store.select(getInputsRecord);
    this.inputsLoading$ = this.store.select(getInputsLoading);
    combineLatest(
      this.store.pipe(select(getInputsLoaded)),
      this.store.pipe(select(getInputsLoading))
    ).pipe(
      tap(values => {
        if (values.every((val) => val === false)) {
          this.store.dispatch(loadInputs());
        }
      }),
      filter((values: Array<boolean>) => values.every((val) => val === false)),
      take(1)
    ).subscribe();
  }

  checkDestinations() {
    this.destinations$ = this.store.select(getDestinationsRecord);
    this.destinationsLoading$ = this.store.select(getDestinationsLoading);
    combineLatest(
      this.store.pipe(select(getDestinationsLoaded)),
      this.store.pipe(select(getDestinationsLoading))
    ).pipe(
      tap(values => {
        if (values.every((val) => val === false)) {
          this.store.dispatch(loadDestinations());
        }
      }),
      filter((values: Array<boolean>) => values.every((val) => val === false)),
      take(1)
    ).subscribe();
  }

  public onEntityChange(entity: SelectedEntity): void {
    this.changeEntity.emit(entity);
  }
}
