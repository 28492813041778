import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaymentApi } from './payment.api.interface';

@Injectable({ providedIn: 'root' })
export class PaymentApiV1Service implements PaymentApi {
  paymentApi: string;
  private options: { headers?: HttpHeaders; withCredentials: boolean } = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: false,
  };

  constructor(private http: HttpClient) {
    this.paymentApi = environment.paymentApiV1Url;
  }

  get<T = any>(url: string, params = {}, options = {}): Observable<any> {
    return this.http.get(this.paymentApi + url, {
      ...this.options,
      ...options,
      params,
    });
  }

  post<T = any>(url: string, params = {}): Observable<any> {
    return this.http.post(this.paymentApi + url, params, this.options);
  }

  delete<T = any>(url: string, params = {}): Observable<any> {
    return this.http.delete(this.paymentApi + url, { ...this.options, params });
  }
}
