import { createReducer, on } from '@ngrx/store';
import * as UserUpdateActions from '../actions/user-update.actions';

export const userUpdateFeatureKey = 'userUpdate';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(UserUpdateActions.userUpdate, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(UserUpdateActions.userUpdateSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(UserUpdateActions.userUpdateFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(UserUpdateActions.clearUserUpdateState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
