import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeleteClip from '../reducers/delete-clip.reducer';

export const selectDeleteClipState = createFeatureSelector<fromDeleteClip.State>(
  fromDeleteClip.deleteClipFeatureKey
);

export const getDeleteClipRecord = createSelector(
  selectDeleteClipState,
  (state) => state.record
);

export const getDeleteClipLoaded = createSelector(
  selectDeleteClipState,
  (state) => state.loaded
);

export const getDeleteClipLoading = createSelector(
  selectDeleteClipState,
  (state) => state.loading
);

export const getDeleteClipError = createSelector(
  selectDeleteClipState,
  (state) => state.error
);
