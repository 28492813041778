import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStartRecord from '../reducers/start-record.reducer';

export const selectStartRecordState = createFeatureSelector<fromStartRecord.State>(
  fromStartRecord.startRecordFeatureKey
);

export const getStartRecord = createSelector(
  selectStartRecordState,
  (state) => state.record
);

export const getStartRecordLoaded = createSelector(
  selectStartRecordState,
  (state) => state.loaded
);

export const getStartRecordLoading = createSelector(
  selectStartRecordState,
  (state) => state.loading
);

export const getStartRecordError = createSelector(
  selectStartRecordState,
  (state) => state.error
);
