import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as OrganizationLicensesActions from '../actions/organization-licenses.actions';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';



@Injectable()
export class OrganizationLicensesEffects {

  loadOrganizationLicenses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationLicensesActions.loadOrganizationLicenses),
      concatMap(() =>
        this.organizationsService.getOrganizationLicenses().pipe(
          map(data => OrganizationLicensesActions.loadOrganizationLicensesSuccess({ data })),
          catchError(error => of(OrganizationLicensesActions.loadOrganizationLicensesFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService) {}

}
