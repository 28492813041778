import { createReducer, on } from '@ngrx/store';
import * as DeleteRecordActions from '../actions/delete-record.actions';

export const deleteRecordFeatureKey = 'deleteRecord';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(DeleteRecordActions.deleteRecord, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(DeleteRecordActions.deleteRecordSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(DeleteRecordActions.deleteRecordFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(DeleteRecordActions.clearDeleteRecordState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);
