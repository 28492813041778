import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserUpdate from '../reducers/user-update.reducer';

export const selectUserUpdateState = createFeatureSelector<fromUserUpdate.State>(
  fromUserUpdate.userUpdateFeatureKey
);

export const getUserUpdateRecord = createSelector(
  selectUserUpdateState,
  (state) => state.record
);

export const getUserUpdateLoaded = createSelector(
  selectUserUpdateState,
  (state) => state.loaded
);

export const getUserUpdateLoading = createSelector(
  selectUserUpdateState,
  (state) => state.loading
);

export const getUserUpdateError = createSelector(
  selectUserUpdateState,
  (state) => state.error
);
