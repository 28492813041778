import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ChangeOrganizationActions from '../actions/change-organization.actions';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';
import { WebStorageService } from '../../../services/web-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as CurrentUserActions from '../../users/actions/current-user.actions';


@Injectable()
export class ChangeOrganizationEffects {

  changeOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChangeOrganizationActions.changeOrganization),
      concatMap((payload) =>
        this.organizationsService.changeOrganization(payload.data).pipe(
          map(data => {
            this.webStorageService.setItem('organizationId', data.data.organizationId);
            return ChangeOrganizationActions.changeOrganizationSuccess({ data });
          }),
          catchError(error => of(ChangeOrganizationActions.changeOrganizationFailure({ error }))))
      )
    );
  });

  changeOrganizationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChangeOrganizationActions.changeOrganizationSuccess),
      tap((res) => this.webStorageService.setItem('token', res.data.data.token)),
      switchMap(() => [
        CurrentUserActions.clearCurrentUserState(),
      ]),
      switchMap(() => [
        CurrentUserActions.loadCurrentUser()
      ]),
      tap(() => {
        setTimeout(() => {
          this.router
            .navigate(['/dashboard'])
            .then( () => window.location.reload());
        }, 500);
      })
    );
  });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService,
              private webStorageService: WebStorageService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {}

}
