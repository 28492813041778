import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStartDestination from '../reducers/start-destination.reducer';

export const selectStartStartDestinationtate = createFeatureSelector<fromStartDestination.State>(
  fromStartDestination.startDestinationFeatureKey
);

export const getStartDestinationRecord = createSelector(
  selectStartStartDestinationtate,
  (state) => state.record
);

export const getStartDestinationLoaded = createSelector(
  selectStartStartDestinationtate,
  (state) => state.loaded
);

export const getStartDestinationLoading = createSelector(
  selectStartStartDestinationtate,
  (state) => state.loading
);

export const getStartDestinationError = createSelector(
  selectStartStartDestinationtate,
  (state) => state.error
);
