import { createReducer, on } from '@ngrx/store';
import * as GridActions from '../actions/grid.actions';
import { DataTransform } from '../../../services/DataTransform';

export const gridFeatureKey = 'grid';

export interface State {
  record: any;
  updatedRecord: any;
  updatedVisibility: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  updatedRecord: null,
  updatedVisibility: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(GridActions.loadGrid, (state) => ({
    ...state,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(GridActions.loadGridSuccess, (state, action) => {
    const updatedDataGrids = action.data.data.entities ? DataTransform.transfromGrids(action.data.data) : action.data.data;
    return ({
      ...state,
      record: updatedDataGrids,
      error: null,
      loaded: true,
      loading: false,
    });
  }),
  on(GridActions.loadUpdatedGridSuccess, (state, action) => {
    const updatedDataGrids = DataTransform.transfromGrids(action.data.data);
    return ({
      ...state,
      updatedRecord: updatedDataGrids,
    });
  }),
  on(GridActions.loadGridFailure, (state, action) => ({
    ...state,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(GridActions.updateVisibility, (state, action) => ({
    ...state,
    updatedVisibility: true,
  })),
  on(GridActions.clearGridState, (state) => ({
    ...state,
    record: null,
    error: null,
    loaded: false,
    loading: false,
  })),
);

