import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as DeleteClipActions from '../actions/delete-clip.actions';
import {ClipsService} from '../../../services/api/clips.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as ClipsActions from '../actions/clips.actions';


@Injectable()
export class DeleteClipEffects {

  // noinspection JSUnusedGlobalSymbols
  deleteClip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteClipActions.deleteClip),
      concatMap((payload) => {
          const clipId: number = payload.data;
          return this.clipsService.deleteClip(clipId)
            .pipe(
              map(data => DeleteClipActions.deleteClipSuccess({data})),
              catchError(error => of(DeleteClipActions.deleteClipFailure({error})))
            );
        }
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  deleteClipSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteClipActions.deleteClipSuccess),
      tap(() => this.snackBar.open('Clip deleted successfully', 'Success', {duration: 3000})),
      map(() => ClipsActions.loadClips()),
    );
  });


  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly clipsService: ClipsService
  ) {
  }

}
