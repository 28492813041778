<mat-form-field>
  <mat-label>Search</mat-label>
  <input matInput type="text"  autocomplete="off" #searchInput (input)="search.emit(searchInput.value)">
  <button *ngIf="searchInput.value !== ''" class="clear-button"
          mat-icon-button matSuffix aria-label="Clear"
          (click)="searchInput.value = ''; search.emit(searchInput.value)">
    <mat-icon>close</mat-icon>
  </button>
  <mat-icon matSuffix *ngIf="searchInput.value === ''">filter_list</mat-icon>
</mat-form-field>
<mat-chip-listbox class="filtered-chip" *ngIf="searchInput.value !== ''">
  <mat-chip-option>{{ itemsCount || dataSource.filteredData.length }} {{ dataSource.filteredData.length === 1 ? 'item' : 'items' }}
    found
  </mat-chip-option>
</mat-chip-listbox>

