import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as OrganizationsActions from '../actions/organizations.actions';
import { OrganizationsService } from '../../../services/api/organizations/organizations.service';



@Injectable()
export class OrganizationsEffects {

  loadOrganizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.loadOrganizations),
      concatMap(() =>
        this.organizationsService.getOrganizations().pipe(
          map(data => OrganizationsActions.loadOrganizationsSuccess({ data })),
          catchError(error => of(OrganizationsActions.loadOrganizationsFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private organizationsService: OrganizationsService) {}

}
