import { createAction, props } from '@ngrx/store';

export const cutVideo = createAction(
  '[CutVideo] Cut Video',
  props<{ data: any }>()
);

export const cutVideoSuccess = createAction(
  '[CutVideo] Cut Video Success',
  props<{ data: any }>()
);

export const cutVideoFailure = createAction(
  '[CutVideo] Cut Video Failure',
  props<{ error: any }>()
);

export const clearCutVideoState = createAction(
  '[CutVideo] clear Cut Video State',
);
