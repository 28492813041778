import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreState} from '../../store.state.interface';
import {LiveMonitoring} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.interface';

export const liveMonitoringFeatureKey = 'live-monitoring';
export const featureSelector = createFeatureSelector<StoreState<LiveMonitoring>>(liveMonitoringFeatureKey);

export const liveMonitoringSelector = createSelector(
  featureSelector,
  (state) => state.data
);

export const liveMonitoringIsLoadedSelector = createSelector(
  featureSelector,
  (state) => state.isLoaded
);
