import {createAction, props} from '@ngrx/store';
import {LiveMonitoring} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.interface';
import {
  StreamerHardwareUsageStatus
} from '../../../services/api/live-monitoring/streamer-hardware-usage/streamer-hardware-usage.status.interface';
import {StreamStatus} from '../../../services/api/live-monitoring/streams-statuses/stream.status.interface';
import {DestinationStatus} from '../../../services/api/live-monitoring/destination-statuses/destination.status.interface';
import {ClipJobStatus} from '../../../services/api/live-monitoring/clip-jobs-statuses/clip-job.status.interface';

export const loadLiveMonitoring = createAction(
  '[Live Monitoring] Load Monitoring'
);

export const loadLiveMonitoringSuccess = createAction(
  '[Live Monitoring] Load Monitoring Success',
  props<{ data: LiveMonitoring }>()
);

export const filterLiveMonitoringByClusterId = createAction(
  '[Live Monitoring] Filter Monitoring By Cluster ID',
  props<{ clusterId: string | 'all' }>()
);

/* Streamer hardware usage */
export const createStreamerHardwareUsage = createAction(
  '[Live Monitoring] Create Streamer Hardware Usage',
  props<{ data: StreamerHardwareUsageStatus }>()
);

export const updateStreamerHardwareUsage = createAction(
  '[Live Monitoring] Update Hardware Usage',
  props<{ data: StreamerHardwareUsageStatus }>()
);

export const deleteStreamerHardwareUsage = createAction(
  '[Live Monitoring] Delete Hardware Usage',
  props<{ instanceId: string }>()
);

/* Streams statuses */
export const createStreamStatus = createAction(
  '[Live Monitoring] Create Stream Status',
  props<{ data: StreamStatus }>()
);

export const updateStreamStatus = createAction(
  '[Live Monitoring] Update Stream Status',
  props<{ data: StreamStatus }>()
);

export const deleteStreamStatus = createAction(
  '[Live Monitoring] Delete Stream Status',
  props<{ inputId: string }>()
);

/* Destinations statuses */
export const createDestinationStatus = createAction(
  '[Live Monitoring] Create Destination Status',
  props<{ data: DestinationStatus }>()
);

export const updateDestinationStatus = createAction(
  '[Live Monitoring] Update Destination Status',
  props<{ data: DestinationStatus }>()
);

export const deleteDestinationStatus = createAction(
  '[Live Monitoring] Delete Destination Status',
  props<{ destinationId: string }>()
);

/* Clip jobs statuses */
export const createClipJobStatus = createAction(
  '[Live Monitoring] Create Clip Job Status',
  props<{ data: ClipJobStatus }>()
);

export const updateClipJobStatus = createAction(
  '[Live Monitoring] Update Clip Job Status',
  props<{ data: ClipJobStatus }>()
);

export const deleteClipJobStatus = createAction(
  '[Live Monitoring] Delete Clip Job Status',
  props<{ clipJobId: string }>()
);

/* DVR record statuses */
export const createDvrRecordStatus = createAction(
  '[Live Monitoring] Create DVR Record Status',
  props<{ data: ClipJobStatus }>()
);

export const updateDvrRecordStatus = createAction(
  '[Live Monitoring] Update DVR Record Status',
  props<{ data: ClipJobStatus }>()
);

export const deleteDvrRecordStatus = createAction(
  '[Live Monitoring] Delete DVR Record Status',
  props<{ recordId: string }>()
);
