import { createAction, props } from '@ngrx/store';

export const loadNdiStreams = createAction(
  '[NdiStreams] Load NdiStreams',
  props<{ data: any }>()
);

export const loadNdiStreamsSuccess = createAction(
  '[NdiStreams] Load NdiStreams Success',
  props<{ data: any }>()
);

export const loadNdiStreamsFailure = createAction(
  '[NdiStreams] Load NdiStreams Failure',
  props<{ error: any }>()
);

export const clearNdiStreamsState = createAction(
  '[NdiStreams] clear NdiStreams State',
);
