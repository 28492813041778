import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromClusterRegions from '../reducers/cluster-regions.reducer';

export const selectClusterRegionsState = createFeatureSelector<fromClusterRegions.State>(
  fromClusterRegions.clusterRegionsFeatureKey
);

export const getClusterRegionsRecord = createSelector(
  selectClusterRegionsState,
  (state) => state.record
);

export const getClusterRegionsLoaded = createSelector(
  selectClusterRegionsState,
  (state) => state.loaded
);

export const getClusterRegionsLoading = createSelector(
  selectClusterRegionsState,
  (state) => state.loading
);

export const getClusterRegionsError = createSelector(
  selectClusterRegionsState,
  (state) => state.error
);
