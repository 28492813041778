import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStorage from '../reducers/storage.reducer';

export const selectStorageState = createFeatureSelector<fromStorage.State>(
  fromStorage.storageFeatureKey
);

export const getStorageRecord = createSelector(
  selectStorageState,
  (state) => state.record
);

export const getStorageLoaded = createSelector(
  selectStorageState,
  (state) => state.loaded
);

export const getStorageLoading = createSelector(
  selectStorageState,
  (state) => state.loading
);

export const getStorageError = createSelector(
  selectStorageState,
  (state) => state.error
);
