import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStopRecord from '../reducers/stop-record.reducer';

export const selectStopRecordState = createFeatureSelector<fromStopRecord.State>(
  fromStopRecord.stopRecordFeatureKey
);

export const getStopRecord = createSelector(
  selectStopRecordState,
  (state) => state.record
);

export const getStopRecordLoaded = createSelector(
  selectStopRecordState,
  (state) => state.loaded
);

export const getStopRecordLoading = createSelector(
  selectStopRecordState,
  (state) => state.loading
);

export const getStopRecordError = createSelector(
  selectStopRecordState,
  (state) => state.error
);
