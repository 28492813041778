import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UserActions from '../actions/user.actions';
import { UsersService } from '../../../services/api/users.service';



@Injectable()
export class UserEffects {

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      concatMap((payload) =>
        this.usersService.getUserById(payload.data).pipe(
          map(data => UserActions.loadUserSuccess({ data })),
          catchError(error => of(UserActions.loadUserFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private usersService: UsersService) {}

}
