interface HSL {
  hue: number;
  saturation: number;
  lightness: number;
}

export class ColorGenerator {
  private saturationRange: { min: number, max: number } = {min: 50, max: 75};
  private lightnessRange: { min: number, max: number } = {min: 25, max: 60};

  private getHashOfString(value: string): number {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
  }

  private normalizeHash(hash: number, min: number, max: number): number {
    return Math.floor((hash % (max - min)) + min);
  }

  private generateHSL(name: string): HSL {
    const hash: number = this.getHashOfString(name);
    const hue: number = this.normalizeHash(hash, 0, 360);
    const saturation: number = this.normalizeHash(hash, this.saturationRange.min, this.saturationRange.max);
    const lightness: number = this.normalizeHash(hash, this.lightnessRange.min, this.lightnessRange.max);
    return {
      hue: hue,
      saturation: saturation,
      lightness: lightness
    };
  }

  private HSLtoString(hsl: HSL): string {
    return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`;
  }

  public fromString(value: string): string {
    const hsl: HSL = this.generateHSL(value);
    return this.HSLtoString(hsl);
  }
}
