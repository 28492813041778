import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as CreateVmixServerActions from '../actions/create-vmix-server.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ProductionInstancesService} from '../../../services/api/production-instances/production-instances.service';


@Injectable()
export class CreateVmixServerEffects {

  // noinspection JSUnusedGlobalSymbols
  createProductionInstance = createEffect(() => {
    return this.actions.pipe(
      ofType(CreateVmixServerActions.createVmixServer),
      concatMap((payload) =>
        this.productionInstancesService.create(payload.data).pipe(
          map(data => CreateVmixServerActions.createVmixServerSuccess({data})),
          catchError(error => of(CreateVmixServerActions.createVmixServerFailure({error}))))
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  createProductionInstanceSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(CreateVmixServerActions.createVmixServerSuccess),
      tap(() => this.snackBar.open('Production instance creation successfully started', 'Success', {duration: 3000})),
      tap(() => this.router.navigate(['/production']))
    );
  }, {dispatch: false});

  constructor(
    private readonly actions: Actions,
    private readonly productionInstancesService: ProductionInstancesService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {
  }

}
