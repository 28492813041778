import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ActivateInvitationActions from '../actions/activate-invitation.actions';
import { AuthService } from '../../../../services/api/auth.service';



@Injectable()
export class ActivateInvitationEffects {

  activateInvitation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivateInvitationActions.activateInvitation),
      concatMap((payload) =>
        this.authService.activateInvitation(payload.data).pipe(
          map(data => ActivateInvitationActions.activateInvitationSuccess({ data })),
          catchError(error => of(ActivateInvitationActions.activateInvitationFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private authService: AuthService) {}

}
