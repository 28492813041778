import { createAction, props } from '@ngrx/store';

export const updateAccount = createAction(
  '[UpdateAccount] Update Account',
  props<{ data: FormData }>()
);

export const updateAccountSuccess = createAction(
  '[UpdateAccount] Update Account Success',
  props<{ data: any }>()
);

export const updateAccountFailure = createAction(
  '[UpdateAccount] Update Account Failure',
  props<{ error: any }>()
);

export const clearUpdateAccountState = createAction(
  '[UpdateAccount] clear Update Account State',
);
