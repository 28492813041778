import { createReducer, on } from '@ngrx/store';
import * as InstancesActions from '../actions/instances.actions';

export const instancesFeatureKey = 'instances';

export interface State {
  record: any[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(InstancesActions.loadInstances, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: true,
  })),
  on(InstancesActions.loadInstancesSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(InstancesActions.loadInstancesFailure, (state, action) => ({
    record: [],
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(InstancesActions.clearInstancesState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  }))
);
