import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOutcomeBandwidth from '../reducers/outcome-bandwidth.reducer';

export const selectOutcomeBandwidthState = createFeatureSelector<fromOutcomeBandwidth.State>(
  fromOutcomeBandwidth.outcomeBandwidthFeatureKey
);

export const getOutcomeBandwidthRecord = createSelector(
  selectOutcomeBandwidthState,
  (state) => state.record
);

export const getOutcomeBandwidthLoaded = createSelector(
  selectOutcomeBandwidthState,
  (state) => state.loaded
);

export const getOutcomeBandwidthLoading = createSelector(
  selectOutcomeBandwidthState,
  (state) => state.loading
);

export const getOutcomeBandwidthError = createSelector(
  selectOutcomeBandwidthState,
  (state) => state.error
);
