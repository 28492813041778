<div [formGroup]="entityControl" autocomplete="off">
  <mat-form-field>
    <mat-label>Destination Name
      <span *ngIf="entityControl.get('currentEntity').validator" style="color: #ED323A;"> *</span>
    </mat-label>
    <input #trigger="matAutocompleteTrigger" type="text" matInput formControlName="currentEntity" autocomplete="off"
           [matAutocomplete]="auto" (input)="onTypingDestination()">
    <button *ngIf="entityControl.value" class="clear-button"
            matSuffix mat-icon-button aria-label="Clear"
            (click)="trigger.openPanel(); onCancelIconClick($event);">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectDestination()">
      <mat-option (mouseenter)="mouseEnter.emit()"
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                  [disabled]="filterdDestinationsId && filterdDestinationsId.includes(option.id)">
        <div *ngIf="filterdDestinationsId && filterdDestinationsId.includes(option.id); else enabled">
          {{option.name}} <span class="used-label">({{ lableForUsedDestination }})</span>
        </div>

        <ng-template #enabled>
          {{ option.name }}
        </ng-template>

      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="entityControl.invalid &&
                    (entityControl.dirty || entityControl.touched)">
      Please choose value from the list
    </mat-error>
  </mat-form-field>
</div>
