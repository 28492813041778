import { createReducer, on } from '@ngrx/store';
import * as DeleteInputActions from '../actions/delete-input.actions';

export const deleteInputFeatureKey = 'deleteInput';

export interface State {
  record: any;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: null,
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(DeleteInputActions.deleteInput, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(DeleteInputActions.deleteInputSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(DeleteInputActions.deleteInputFailure, (state, action) => ({
    record: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(DeleteInputActions.cleardeleteInputState, () => ({
    record: null,
    error: null,
    loaded: false,
    loading: false,
  }))
);

