import { createAction, props } from '@ngrx/store';

export const updateDestination = createAction(
  '[UpdateDestination] Update Destination',
  props<{ data: any }>()
);

export const updateDestinationSuccess = createAction(
  '[UpdateDestination] Update Destination Success',
  props<{ data: any }>()
);

export const updateDestinationFailure = createAction(
  '[UpdateDestination] Update Destination Failure',
  props<{ error: any }>()
);

export const clearUpdateDestinationState = createAction(
  '[UpdateDestination] clear Update Destination State',
);
