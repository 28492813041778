import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as StartVmixServerActions from '../actions/start-vmix-server.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ProductionInstancesService} from '../../../services/api/production-instances/production-instances.service';



@Injectable()
export class StartVmixServerEffects {

  constructor(
    private readonly actions: Actions,
    private readonly productionInstancesService: ProductionInstancesService,
    private readonly snackBar: MatSnackBar) {}

  startProductionInstance = createEffect(() => {
    return this.actions.pipe(
      ofType(StartVmixServerActions.startVmixServer),
      concatMap((payload) =>
        this.productionInstancesService.start(payload.data).pipe(
          map(data => StartVmixServerActions.startVmixServerSuccess({ data })),
          catchError(error => of(StartVmixServerActions.startVmixServerFailure({ error }))))
      )
    );
  });

  startProductionInstanceSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(StartVmixServerActions.startVmixServerSuccess),
      tap(() => this.snackBar.open('Production instance successfully started', 'Success', {duration: 3000})),
    );
  }, {dispatch: false});

}
