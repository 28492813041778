import {LiveMonitoring} from '../../../pages/dashboard/components/dashboard/interfaces/live-monitoring.interface';

export const liveMonitoringInitialStateData: LiveMonitoring = {
  active: {
    streams: {
      total: 0,
      rtmpIn: 0,
      rtmpOut: 0,
      hlsOut: 0,
      srtIn: 0,
      srtOut: 0,
      ndiIn: 0
    },
    destinations: {
      total: 0,
      rtmp: 0,
      srt: 0,
      vvcrStream: 0
    },
    streamersHardwareUsage: [],
    productionInstances: 0,
    clipJobs: 0,
    dvrRecords: 0
  },
  inactive: {
    streams: {
      total: 0,
      rtmpIn: 0,
      rtmpOut: 0,
      hlsOut: 0,
      srtIn: 0,
      srtOut: 0,
      ndiIn: 0
    },
    destinations: {
      total: 0,
      rtmp: 0,
      srt: 0,
      vvcrStream: 0
    },
    productionInstances: 0,
    clipJobs: 0,
    dvrRecords: 0
  }
};
