import { Injectable } from '@angular/core';

type WebStorageType = 'localStorage' | 'sessionStorage';

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  private storage: Storage;

  private static serialize(data: any): string {
    return JSON.stringify(data);
  }

  private static unserialize(data: any): string {
    let storagedata;
    try {
      storagedata = JSON.parse(data);
    } catch (err) {
      storagedata = data;
    }
    return storagedata;
  }

  private init(webStorageType: WebStorageType): void {
    switch (webStorageType) {
      case 'localStorage':
        this.storage = localStorage;
        break;
      case 'sessionStorage':
        this.storage = sessionStorage;
        break;
    }
  }

  setItem(key: string, data: any, webStorageType: WebStorageType = 'localStorage'): void {
    this.init(webStorageType);
    const serializedData = WebStorageService.serialize(data);
    this.storage.setItem(key, serializedData);
  }

  getItem(key: string, webStorageType: WebStorageType = 'localStorage'): any {
    this.init(webStorageType);
    const data = this.storage.getItem(key);
    return WebStorageService.unserialize(data);
  }

  deleteItem(key: string, webStorageType: WebStorageType = 'localStorage'): void {
    this.init(webStorageType);
    this.storage.removeItem(key);
  }
}
