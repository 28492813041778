import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CreateDestinationActions from '../actions/create-destination.actions';
import { DestinationsService } from '../../../services/api/destinations/destinations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { InputsTableService } from '../../../pages/inputs/services/inputs-table.service';



@Injectable()
export class CreateDestinationEffects {

  createDestination$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateDestinationActions.createDestination),
      concatMap((payload) =>
        this.destinationsService.createDestination(payload.data).pipe(
          map(data => CreateDestinationActions.createDestinationSuccess({ data })),
          catchError(error => of(CreateDestinationActions.createDestinationFailure({ error }))))
      )
    );
  });

  createDestinationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreateDestinationActions.createDestinationSuccess),
      tap(() => {
        const config = this.inputsTableService.getTableConfig();
        this.snackBar.open('Destination successfully created', 'Success', {duration: 3000});
        if (config) {
          this.router.navigate(['/streams']);
        } else {
          this.router.navigate(['/destinations']);
        }
      })
    );
  }, { dispatch: false });



  constructor(private actions$: Actions,
              private snackBar: MatSnackBar,
              private router: Router,
              private inputsTableService: InputsTableService,
              private destinationsService: DestinationsService) {}

}
