import { createAction, props } from '@ngrx/store';

export const loadUsersRoles = createAction(
  '[UsersRoles] Load UsersRoles'
);

export const loadUsersRolesSuccess = createAction(
  '[UsersRoles] Load UsersRoles Success',
  props<{ data: any }>()
);

export const loadUsersRolesFailure = createAction(
  '[UsersRoles] Load UsersRoles Failure',
  props<{ error: any }>()
);

export const clearUsersRolesState = createAction(
  '[UsersRoles] clear UsersRoles State',
);
