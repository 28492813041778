import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDestinations from '../reducers/destinations.reducer';

export const selectDestinationsState = createFeatureSelector<fromDestinations.State>(
  fromDestinations.destinationsFeatureKey
);

export const getDestinationsRecord = createSelector(
  selectDestinationsState,
  (state) => state.record
);

export const getDestinationsLoaded = createSelector(
  selectDestinationsState,
  (state) => state.loaded
);

export const getDestinationsLoading = createSelector(
  selectDestinationsState,
  (state) => state.loading
);

export const getDestinationsError = createSelector(
  selectDestinationsState,
  (state) => state.error
);
