import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {modules} from './modules';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmptyTableMessageComponent} from './components/empty-table-message/empty-table-message.component';
import {StatusComponent} from './components/status/status.component';
import {CopyLinkComponent} from './components/copy-link/copy-link.component';
import {SldpPlayerComponent} from './components/sldp-player/sldp-player.component';
import {IsRequiredFieldComponent} from './components/is-required-field/is-required-field.component';
import {DestinationUrlComponent} from './components/destination-url/destination-url.component';
import {ModalComponent} from './components/modal/modal.component';
import {InputFormComponent} from './components/input-form/input-form.component';
import {StreamUrlComponent} from './components/stream-url/stream-url.component';
import {StreamTimerComponent} from './components/stream-timer/stream-timer.component';
import {SwitchRecordingBtnComponent} from './components/switch-recording-btn/switch-recording-btn.component';
import {InputFilterDirective} from './directives/input-filter.directive';
import {TableSearchComponent} from './components/table-search/table-search.component';
import {PermissionsDirective} from './directives/permissions.directive';
import {PermissionRoleDirective} from './directives/permission-role.directive';
import {SldpPlayerVuIndicatorComponent} from './components/sldp-player-vu-indicator/sldp-player-vu-indicator.component';
import {VideojsTrimmingPlayerComponent} from './components/videojs-trimming-player/videojs-trimming-player.component';
import {VideojsPlayerComponent} from './components/videojs-player/videojs-player.component';
import {DvrStatusComponent} from './components/dvr-status/dvr-status.component';
import {LicenceFilterDirective} from './directives/licence-filter.directive';
import {DestinationSrtCallerFormComponent} from './components/destination-srt-caller-form/destination-srt-caller-form.component';
import {DestinationRtmpPushFormComponent} from './components/destination-rtmp-push-form/destination-rtmp-push-form.component';
import {VolumeSliderComponent} from './components/volume-slider/volume-slider.component';
import { SevenSegmentClockComponent } from './components/seven-segment-clock/seven-segment-clock.component';

const components = [
  EmptyTableMessageComponent,
  StatusComponent,
  CopyLinkComponent,
  SldpPlayerComponent,
  IsRequiredFieldComponent,
  DestinationUrlComponent,
  InputFormComponent,
  ModalComponent,
  StreamUrlComponent,
  StreamTimerComponent,
  SwitchRecordingBtnComponent,
  TableSearchComponent,
  VideojsTrimmingPlayerComponent,
  SldpPlayerVuIndicatorComponent,
  VideojsPlayerComponent,
  DvrStatusComponent,
  DestinationSrtCallerFormComponent,
  DestinationRtmpPushFormComponent,
  SevenSegmentClockComponent
];

const directives = [
  InputFilterDirective,
  PermissionsDirective,
  PermissionRoleDirective,
  LicenceFilterDirective
];

@NgModule({
  declarations: [
    ...components,
    ...directives
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...modules,
    VolumeSliderComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...modules,
    ...components,
    ...directives,
    SevenSegmentClockComponent
  ]
})
export class SharedModule {
}
