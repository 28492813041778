import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as DestinationsActions from '../actions/destinations.actions';
import { DestinationsService } from '../../../services/api/destinations/destinations.service';



@Injectable()
export class DestinationsEffects {

  loadDestinations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DestinationsActions.loadDestinations),
      concatMap(() =>
        this.destinationsService.getDestinations().pipe(
          map(data => DestinationsActions.loadDestinationsSuccess({ data })),
          catchError(error => of(DestinationsActions.loadDestinationsFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private destinationsService: DestinationsService) {}

}
