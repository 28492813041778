import { createAction, props } from '@ngrx/store';

export const loadDestination = createAction(
  '[Destination] Load Destination',
  props<{ data: any }>()
);

export const loadDestinationSuccess = createAction(
  '[Destination] Load Destination Success',
  props<{ data: any }>()
);

export const loadDestinationFailure = createAction(
  '[Destination] Load Destination Failure',
  props<{ error: any }>()
);

export const clearDestinationState = createAction(
  '[Destination] clear Destination State',
);
