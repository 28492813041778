import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, first } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import * as OutcomeBandwidthActions from '../actions/outcome-bandwidth.actions';
import { DashboardService } from '../../../services/api/dashboard.service';
import { getChartTimeRecord } from '../selectors/chart-time.selectors';
import { getStatisticsClusterRecord } from '../selectors/statistics-cluster.selectors';
import { Store } from '@ngrx/store';



@Injectable()
export class OutcomeBandwidthEffects {

  loadOutcomeBandwidth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OutcomeBandwidthActions.loadOutcomeBandwidth),
      switchMap(() =>
        forkJoin(
          this.store.select(getChartTimeRecord).pipe(first()),
          this.store.select(getStatisticsClusterRecord).pipe(first())
        ).pipe(
          first(),
          concatMap(([time, clusterId]) =>
            this.dashboardService.getOutcomeBandwidth(time, clusterId).pipe(
              map(data => OutcomeBandwidthActions.loadOutcomeBandwidthSuccess({ data })),
              catchError(error => of(OutcomeBandwidthActions.loadOutcomeBandwidthFailure({ error }))))
          )
        )
      )
    );
  });



  constructor(private actions$: Actions,
              private store: Store,
              private dashboardService: DashboardService) {}

}
