import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentApiV1Service } from './payment-api/payment.api.v1.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private paymentApiService: PaymentApiV1Service) { }

  getPaymentInfo(): Observable<any> {
    return this.paymentApiService.get(`/payment_info`);
  }
}
