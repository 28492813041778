import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { WebStorageService } from '../services/web-storage.service';
import { select, Store } from '@ngrx/store';
import * as fromCurrentUser from '../store/users/reducers/current-user.reducer';
import { getCurrentUserLoaded } from '../store/users/selectors/current-user.selectors';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { loadCurrentUser } from '../store/users/actions/current-user.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private storageService: WebStorageService,
              private store: Store<fromCurrentUser.State>,
              private router: Router) {}

  canActivate(): Observable<boolean> {
    const token = this.storageService.getItem('token');
    const organizationId = this.storageService.getItem('organizationId');
    if (!token) {
      this.router.navigate(['/login']);
      of(false);
    }
    if (!organizationId) {
      this.router.navigate(['/choose-organization']);
      of(false);
    }
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.pipe(select(getCurrentUserLoaded)).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(loadCurrentUser());
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
