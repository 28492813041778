import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductionStatusContainerComponent } from './production-status-container.component';
import { ProductionStatusComponent } from './components/production-status/production-status.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    ProductionStatusContainerComponent,
    ProductionStatusComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatTooltipModule,
  ],
  exports: [
    ProductionStatusContainerComponent,
    ProductionStatusComponent,
  ]
})
export class ProductionStatusModule { }
