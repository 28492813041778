import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as NdiStreamsActions from '../actions/ndi-streams.actions';
import { InputsService } from '../../../services/api/inputs.service';



@Injectable()
export class NdiStreamsEffects {

  loadNdiStreams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NdiStreamsActions.loadNdiStreams),
      concatMap((payload) =>
        this.inputsService.getNdiStreams(payload.data).pipe(
          map(data => NdiStreamsActions.loadNdiStreamsSuccess({ data })),
          catchError(error => of(NdiStreamsActions.loadNdiStreamsFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private inputsService: InputsService) {}

}
