<app-progress-bar-container class="loader"></app-progress-bar-container>
<mat-toolbar class="header">
  <button mat-icon-button class="icon-menu" (click)="sidenavToggle.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="right-block">
    <app-collabora layoutMode="navbar"></app-collabora>
    <ng-container *ngIf="currentUser.role.value === 10 || currentUser.organizations.length > 1">
      <div class="organization">
        <div class="org-title">Organization</div>
        <div class="choose-organization">
          <div *ngIf="currentUser.role.value === 10; else user">
            <mat-select #orgs panelWidth="180px"
                        [value]="currentUser.currentOrganization.id"
                        (selectionChange)="onChangeOrganization(orgs.value)">
              <mat-option *ngFor="let organization of organizationsRecord" [value]="organization.id">
                {{organization.organizationName}}
              </mat-option>
            </mat-select>
          </div>
          <ng-template #user>
            <div *ngIf="currentUser.organizations.length > 1">
              <mat-select #orgs panelWidth="180px"
                          [value]="currentUser.currentOrganization.id"
                          (selectionChange)="onChangeOrganization(orgs.value)">
                <mat-option *ngFor="let organization of currentUser.organizations" [value]="organization.id">
                  {{organization.organizationName}}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <a class="username" mat-button [matMenuTriggerFor]="menu">
      <ng-container *ngIf="!currentUser.picture">
        <mat-icon>account_circle</mat-icon>
      </ng-container>

      <img class="user-avatar" *ngIf="currentUser.picture" [ngSrc]="pictureUrl" width="56" height="56" alt="user-image">
    </a>
    <mat-menu #menu="matMenu">
      <div class="user-menu">
        <div class="user-info">
          <div class="user-info-name">{{currentUser.fname}} {{currentUser.lname}}</div>
          <div class="user-info-email">{{currentUser.email}}</div>
        </div>
        <ng-container *ngFor="let menu of menusConfig">
          <button *ngIf="isShowMenuItem(menu)" mat-menu-item (click)="onMenuClick(menu)">
            <mat-icon [svgIcon]="menu.icon"></mat-icon>
            {{menu.label}}
          </button>
        </ng-container>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
