import { createAction, props } from '@ngrx/store';

export const loadOrganization = createAction(
  '[Organization] Load Organization',
  props<{ data: any }>()
);

export const loadOrganizationSuccess = createAction(
  '[Organization] Load Organization Success',
  props<{ data: any }>()
);

export const loadOrganizationFailure = createAction(
  '[Organization] Load Organization Failure',
  props<{ error: any }>()
);

export const clearLoadOrganizationState = createAction(
  '[Organization] Clear Load Organization State',
);
