import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ImgLoaderService } from '../services/img-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ImgLoaderGuard implements CanActivate {

  constructor(private imgLoaderService: ImgLoaderService) {}

  canActivate(): Observable<boolean> {
    this.imgLoaderService.loadImages();
    return of(true);
  }
}
