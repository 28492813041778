import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UpdateAccountActions from '../actions/update-account.actions';
import { AccountService } from '../../../services/api/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as CurrentUserActions from '../../users/actions/current-user.actions';



@Injectable()
export class UpdateAccountEffects {

  updateAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateAccountActions.updateAccount),
      concatMap((payload) =>
        this.accountService.updateAccount(payload.data).pipe(
          map(data => UpdateAccountActions.updateAccountSuccess({ data })),
          catchError(error => of(UpdateAccountActions.updateAccountFailure({ error }))))
      )
    );
  });

  updeteAccountSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UpdateAccountActions.updateAccountSuccess),
      tap(() => this.snackBar.open('Account successfully updated', 'Success', {duration: 3000})),
      map(() => CurrentUserActions.loadCurrentUser())
    );
  });


  constructor(private actions$: Actions,
              private accountService: AccountService,
              private snackBar: MatSnackBar) {}

}
