import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromStopVmixServer from '../reducers/stop-vmix-server.reducer';

export const selectStopVmixServerState = createFeatureSelector<fromStopVmixServer.State>(
  fromStopVmixServer.stopVmixServerFeatureKey
);

export const getStopVmixServerRecord = createSelector(
  selectStopVmixServerState,
  (state) => state.record
);

export const getStopVmixServerLoaded = createSelector(
  selectStopVmixServerState,
  (state) => state.loaded
);

export const getStopVmixServerLoading = createSelector(
  selectStopVmixServerState,
  (state) => state.loading
);

export const getStopVmixServerError = createSelector(
  selectStopVmixServerState,
  (state) => state.error
);
