import { createAction, props } from '@ngrx/store';

export const loadRecordStatus = createAction(
  '[RecordStatus] Load Record Status'
);

export const loadRecordStatusSuccess = createAction(
  '[RecordStatus] Load Record Status Success',
  props<{ data: any }>()
);

export const loadRecordStatusFailure = createAction(
  '[RecordStatus] Load Record Status Failure',
  props<{ error: any }>()
);

export const clearRecordStatusState = createAction(
  '[RecordStatus] clear Record Status State',
);
