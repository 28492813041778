import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromIncomeBandwidth from '../reducers/income-bandwidth.reducer';

export const selectIncomeBandwidthState = createFeatureSelector<fromIncomeBandwidth.State>(
  fromIncomeBandwidth.incomeBandwidthFeatureKey
);

export const getIncomeBandwidthRecord = createSelector(
  selectIncomeBandwidthState,
  (state) => state.record
);

export const getIncomeBandwidthLoaded = createSelector(
  selectIncomeBandwidthState,
  (state) => state.loaded
);

export const getIncomeBandwidthLoading = createSelector(
  selectIncomeBandwidthState,
  (state) => state.loading
);

export const getIncomeBandwidthError = createSelector(
  selectIncomeBandwidthState,
  (state) => state.error
);
