import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserInvite from '../reducers/user-invite.reducer';

export const selectUserInviteState = createFeatureSelector<fromUserInvite.State>(
  fromUserInvite.userInviteFeatureKey
);

export const getUserInviteRecord = createSelector(
  selectUserInviteState,
  (state) => state.record
);

export const getUserInviteLoaded = createSelector(
  selectUserInviteState,
  (state) => state.loaded
);

export const getUserInviteLoading = createSelector(
  selectUserInviteState,
  (state) => state.loading
);

export const getUserInviteError = createSelector(
  selectUserInviteState,
  (state) => state.error
);
