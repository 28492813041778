import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeleteRecord from '../reducers/delete-record.reducer';

export const selectDeleteRecordState = createFeatureSelector<fromDeleteRecord.State>(
  fromDeleteRecord.deleteRecordFeatureKey
);

export const getDeleteRecord = createSelector(
  selectDeleteRecordState,
  (state) => state.record
);

export const getDeleteRecordLoaded = createSelector(
  selectDeleteRecordState,
  (state) => state.loaded
);

export const getDeleteRecordLoading = createSelector(
  selectDeleteRecordState,
  (state) => state.loading
);

export const getDeleteRecordError = createSelector(
  selectDeleteRecordState,
  (state) => state.error
);
