import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromGrids from '../reducers/grids.reducer';

export const selectGridsState = createFeatureSelector<fromGrids.State>(
  fromGrids.gridsFeatureKey
);

export const getGridsRecord = createSelector(
  selectGridsState,
  (state) => state.record
);

export const getGridsLoaded = createSelector(
  selectGridsState,
  (state) => state.loaded
);

export const getGridsLoading = createSelector(
  selectGridsState,
  (state) => state.loading
);

export const getGridsError = createSelector(
  selectGridsState,
  (state) => state.error
);
