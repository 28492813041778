import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as AllInstanceTypesActions from '../actions/all-instance-types.actions';
import { InstancesService } from '../../../services/api/instances/instances.service';



@Injectable()
export class AllInstanceTypesEffects {

  loadAllInstanceTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AllInstanceTypesActions.loadAllInstanceTypes),
      concatMap(() =>
        this.instancesService.getAllInstanceTypes().pipe(
          map(data => AllInstanceTypesActions.loadAllInstanceTypesSuccess({ data })),
          catchError(error => of(AllInstanceTypesActions.loadAllInstanceTypesFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private instancesService: InstancesService) {}

}
