import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOrganizations from '../reducers/organizations.reducer';

export const selectOrganizationsState = createFeatureSelector<fromOrganizations.State>(
  fromOrganizations.organizationsFeatureKey
);

export const getOrganizationsRecord = createSelector(
  selectOrganizationsState,
  (state) => state.record
);

export const getOrganizationsLoaded = createSelector(
  selectOrganizationsState,
  (state) => state.loaded
);

export const getOrganizationsLoading = createSelector(
  selectOrganizationsState,
  (state) => state.loading
);

export const getOrganizationsError = createSelector(
  selectOrganizationsState,
  (state) => state.error
);
