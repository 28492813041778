import { createAction, props } from '@ngrx/store';

export const loadBillingPeriods = createAction(
  '[BillingPeriods] Load Billing Periods'
);

export const loadBillingPeriodsSuccess = createAction(
  '[BillingPeriods] Load Billing Periods Success',
  props<{ data: any }>()
);

export const loadBillingPeriodsFailure = createAction(
  '[BillingPeriods] Load Billing Periods Failure',
  props<{ error: any }>()
);

export const clearBillingPeriodsState = createAction(
  '[BillingPeriods] clear Billing Periods State',
);
