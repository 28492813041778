import { createAction, props } from '@ngrx/store';

export const loadOrganizationLicenses = createAction(
  '[OrganizationLicenses] Load Organization Licenses'
);

export const loadOrganizationLicensesSuccess = createAction(
  '[OrganizationLicenses] Load Organization Licenses Success',
  props<{ data: any }>()
);

export const loadOrganizationLicensesFailure = createAction(
  '[OrganizationLicenses] Load Organization Licenses Failure',
  props<{ error: any }>()
);

export const clearOrganizationLicensesState = createAction(
  '[OrganizationLicenses] Clear Organization Licenses State',
);
