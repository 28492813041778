import { createAction, props } from '@ngrx/store';

export const loadCurrentUser = createAction(
  '[CurrentUser] Load Current User'
);

export const loadCurrentUserSuccess = createAction(
  '[CurrentUser] Load Current User Success',
  props<{ data: any }>()
);

export const loadCurrentUserFailure = createAction(
  '[CurrentUser] Load Current User Failure',
  props<{ error: any }>()
);

export const clearCurrentUserState = createAction(
  '[CurrentUser] clear Current User State',
);
