import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCurrentOrganization from '../reducers/current-organization.reducer';

export const selectCurrentOrganizationState = createFeatureSelector<fromCurrentOrganization.State>(
  fromCurrentOrganization.currentOrganizationFeatureKey
);

export const getCurrentOrganizationRecord = createSelector(
  selectCurrentOrganizationState,
  (state) => state.record
);

export const getCurrentOrganizationLoaded = createSelector(
  selectCurrentOrganizationState,
  (state) => state.loaded
);

export const getCurrentOrganizationLoading = createSelector(
  selectCurrentOrganizationState,
  (state) => state.loading
);

export const getCurrentOrganizationError = createSelector(
  selectCurrentOrganizationState,
  (state) => state.error
);
