import { createAction, props } from '@ngrx/store';

export const deleteOrganization = createAction(
  '[DeleteOrganization] Delete Organization',
  props<{ data: any }>()
);

export const deleteOrganizationSuccess = createAction(
  '[DeleteOrganization] Delete Organization Success',
  props<{ data: any }>()
);

export const deleteOrganizationFailure = createAction(
  '[DeleteOrganization] Delete Organization Failure',
  props<{ error: any }>()
);

export const clearDeleteOrganizationState = createAction(
  '[DeleteOrganization] Clear Delete Organization State',
);
