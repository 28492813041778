import { createAction, props } from '@ngrx/store';
import {StorageStatistics} from '../../../services/api/statistics/storages/storages.statistic.model';

export const loadStorage = createAction(
  '[Storage] Load Storage',
);

export const loadStorageSuccess = createAction(
  '[Storage] Load Storage Success',
  props<{ data: StorageStatistics }>()
);

export const loadStorageFailure = createAction(
  '[Storage] Load Storage Failure',
  props<{ error: any }>()
);

export const clearStorageState = createAction(
  '[Storage] clear Storage State',
);
