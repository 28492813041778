import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCreateVmixServer from '../reducers/create-vmix-server.reducer';

export const selectCreateVmixServerState = createFeatureSelector<fromCreateVmixServer.State>(
  fromCreateVmixServer.createVmixServerFeatureKey
);

export const getCreateVmixServerRecord = createSelector(
  selectCreateVmixServerState,
  (state) => state.record
);

export const getCreateVmixServerLoaded = createSelector(
  selectCreateVmixServerState,
  (state) => state.loaded
);

export const getCreateVmixServerLoading = createSelector(
  selectCreateVmixServerState,
  (state) => state.loading
);

export const getCreateVmixServerError = createSelector(
  selectCreateVmixServerState,
  (state) => state.error
);
