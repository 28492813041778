import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImgLoaderService {

  images = [
    'vvcr-icons/Dashboard.svg',
    'vvcr-icons/Clippost.svg',
    'vvcr-icons/Cloud.svg',
    'vvcr-icons/Destination.svg',
    'vvcr-icons/DVR.svg',
    'vvcr-icons/Grid.svg',
    'vvcr-icons/Setting.svg',
    'vvcr-icons/Stream.svg',
    'vvcr-icons/Production.svg',
    'vvcr-icons/Organizations.svg',
    'vvcr-icons/Organization.svg',
    'vvcr-icons/Account.svg',
    'icons/info.svg',
    'icons/monitor.svg',
    'icons/total.svg',
    'icons/close.svg',
  ];


  loadImages() {
    for (const imagePath of this.images) {
      const img = new Image();
      img.src = '/assets/' + imagePath;
    }
  }
}
