import {ActionReducer, createReducer, on} from '@ngrx/store';
import * as StatisticsClusterActions from '../actions/statistics-cluster.actions';
import {ClusterID} from '../../../services/api/statistics/types/cluster-id.statistics.parameter.type';

export const statisticsClusterFeatureKey = 'statisticsCluster';

export interface State {
  clusterId: ClusterID;
}

export const initialState: State = {
  clusterId: 'all',
};

export const reducer: ActionReducer<State> = createReducer(
  initialState,

  on(StatisticsClusterActions.setStatisticsCluster, (state: State, action): {clusterId: ClusterID} => ({
    clusterId: action.data,
  })),
  on(StatisticsClusterActions.clearStatisticsClusterState, (): {clusterId: ClusterID} => ({
    clusterId: 'all',
  })),
);
