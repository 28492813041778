import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as InputsActions from '../actions/inputs.actions';
import { InputsService } from '../../../services/api/inputs.service';



@Injectable()
export class InputsEffects {

  loadInputs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InputsActions.loadInputs),
      concatMap(() =>
        this.inputsService.getInputs().pipe(
          map(data => InputsActions.loadInputsSuccess({ data })),
          catchError(error => of(InputsActions.loadInputsFailure({ error }))))
      )
    );
  });



  constructor(private actions$: Actions,
              private inputsService: InputsService) {}

}
