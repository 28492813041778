import { createReducer, on } from '@ngrx/store';
import * as BandwidthSettingsActions from '../actions/bandwidth-settings.actions';
import { BandwidthSettings } from '../../../models/dashboard.interface';

export const bandwidthSettingsFeatureKey = 'bandwidthSettings';

export interface State {
  settings: BandwidthSettings;
}

export const initialState: State = {
  settings: {
    all: true,
    RTMP: false,
    SRT: false,
    outAll: true,
  },
};

export const reducer = createReducer(
  initialState,

  on(BandwidthSettingsActions.setBandwidthSettings, (state, action) => ({
    settings: action.data,
  })),
  on(BandwidthSettingsActions.clearBandwidthSettingsState, () => ({
    settings: {
      all: true,
      RTMP: false,
      SRT: false,
      outAll: true,
    },
  })),
);
