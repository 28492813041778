import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCurrentUser from '../reducers/current-user.reducer';

export const selectCurrentUserState = createFeatureSelector<fromCurrentUser.State>(
  fromCurrentUser.userFeatureKey
);

export const getCurrentUserRecord = createSelector(
  selectCurrentUserState,
  (state) => state.record
);

export const getCurrentUserLoaded = createSelector(
  selectCurrentUserState,
  (state) => state.loaded
);

export const getCurrentUserLoading = createSelector(
  selectCurrentUserState,
  (state) => state.loading
);

export const getCurrentUserError = createSelector(
  selectCurrentUserState,
  (state) => state.error
);

