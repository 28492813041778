import { createReducer, on } from '@ngrx/store';
import * as UsersActions from '../actions/users.actions';

export const usersFeatureKey = 'users';

export interface State {
  record: any[];
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  record: [],
  error: null,
  loaded: false,
  loading: false,
};


export const reducer = createReducer(
  initialState,

  on(UsersActions.loadUsers, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: true,
  })),
  on(UsersActions.loadUsersSuccess, (state, action) => ({
    record: action.data.data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(UsersActions.loadUsersFailure, (state, action) => ({
    record: action.error,
    error: action,
    loaded: false,
    loading: false,
  })),
  on(UsersActions.clearUsersState, () => ({
    record: [],
    error: null,
    loaded: false,
    loading: false,
  })),
);
