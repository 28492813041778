import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GridsService {

  constructor(private apiService: ApiService) {}

  getGrids(): Observable<any> {
    return this.apiService.get(`/grid_cqrs`);
  }

  getGrid(data): Observable<any> {
    if (data.mode && data.mode === 'edit') {
      if (data.id) {
        return this.apiService.get(`/grid_cqrs/${data.id}`);
      } else if (data.dns && data.shortName) {
        return this.apiService.get(`/grid_cqrs/${data.dns}/${data.shortName}`);
      }
    } else {
      let params = new HttpParams();
      if (data && data.password) {
        params = params.append('password', data.password);
      }
      if (data.id) {
        return this.apiService.get(`/grid_cqrs/view/${data.id}`, params);
      } else if (data.dns && data.shortName) {
        return this.apiService.get(`/grid_cqrs/view/${data.dns}/${data.shortName}`, params);
      }
    }
  }

  createGrid(data): Observable<any> {
    return this.apiService.post(`/grid_cqrs`, data);
  }

  updateGrid(data): Observable<any> {
    return this.apiService.put(`/grid_cqrs/${data.id}`, data);
  }

  deleteGrid(gridId): Observable<any> {
    return this.apiService.delete(`/grid_cqrs/${gridId}`);
  }

  validateShortname(shortname: string) {
    return this.apiService.get(`/grid_cqrs/validate/${shortname}`);
  }
}
