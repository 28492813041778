import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './app-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { PermissionGuard } from './guards/permission.guard';
import { OnlyAdminGuard } from './guards/only-admin.guard';
import { RequiredClustersGuard } from './guards/required-clusters.guard';
import { LicenseGuard } from './guards/license.guard';
import { IsActiveLicenseGuard } from './guards/is-active-license.guard';
import { ImgLoaderGuard } from './guards/img-loader.guard';


const routes: Routes = [
    { path: '', component: AppLayoutComponent, canActivate: [AuthGuard, ImgLoaderGuard], children: [
        { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
        {
          path: 'dashboard', data: { allowedLicenses: [1] },
          loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
          canActivate: [LicenseGuard],
        },
        {
          path: 'clusters', data: { feature: 'clusters', permissionMode: 'read', allowedLicenses: [1] },
          loadChildren: () => import('./pages/clusters/clusters.module').then(m => m.ClustersModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'clusters/new', data: { mode: 'new', feature: 'clusters', permissionMode: 'create', allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-cluster/manage-cluster.module').then(m => m.ManageClusterModule),
          canActivate: [PermissionGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        { path: 'streams', data: { feature: 'streams', permissionMode: 'read' },
          loadChildren: () => import('./pages/inputs/inputs.module').then(m => m.InputsModule),
          canActivate: [IsActiveLicenseGuard],
        },
        {
          path: 'streams/new', data: { mode: 'new', feature: 'streams', permissionMode: 'create', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-input/manage-input.module').then(m => m.ManageInputModule),
          canActivate: [PermissionGuard, RequiredClustersGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'streams/new/:id', data: { mode: 'new-duplicate', feature: 'streams', permissionMode: 'create', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-input/manage-input.module').then(m => m.ManageInputModule),
          canActivate: [PermissionGuard, RequiredClustersGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'streams/edit/:id', data: { mode: 'edit', feature: 'streams', permissionMode: 'edit', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-input/manage-input.module').then(m => m.ManageInputModule),
          canActivate: [PermissionGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'destinations', data: { feature: 'destinations', permissionMode: 'read', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/destinations/destinations.module').then(m => m.DestinationsModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'destinations/new', data: { mode: 'new', feature: 'destinations', permissionMode: 'create', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-destination/manage-destination.module').then(m => m.ManageDestinationModule),
          canActivate: [PermissionGuard, RequiredClustersGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'destinations/new/:id', data: {
            mode: 'new-duplicate',
            feature: 'destinations',
            permissionMode: 'create',
            allowedLicenses: [1, 2]
          },
          loadChildren: () => import('./pages/manage-destination/manage-destination.module').then(m => m.ManageDestinationModule),
          canActivate: [PermissionGuard, RequiredClustersGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'destinations/edit/:id', data: { mode: 'edit', feature: 'destinations', permissionMode: 'edit', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-destination/manage-destination.module').then(m => m.ManageDestinationModule),
          canActivate: [PermissionGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'multiview', data: { feature: 'grids', permissionMode: 'read', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/grids/grids.module').then(m => m.GridsModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'multiview/new', data: { mode: 'new', feature: 'grids', permissionMode: 'create', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-multiview/manage-multiview.module').then(m => m.ManageMultiviewModule),
          canActivate: [PermissionGuard, RequiredClustersGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'multiview/edit/:id', data: { mode: 'edit', feature: 'grids', permissionMode: 'edit', allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-multiview/manage-multiview.module').then(m => m.ManageMultiviewModule),
          canActivate: [PermissionGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'dvr', data: { feature: 'dvr', permissionMode: 'read', allowedLicenses: [1] },
          loadChildren: () => import('./pages/dvr/dvr.module').then(m => m.DvrModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'organizations',  data: { access: [10], allowedLicenses: [1] },
          loadChildren: () => import('./pages/organizations/organizations.module').then(m => m.OrganizationsModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organizations/new', data: { mode: 'new', access: [10], allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-organizations/manage-organizations.module').then(m => m.ManageOrganizationsModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organizations/edit/:id', data: { mode: 'edit', access: [5, 10], allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-organizations/manage-organizations.module').then(m => m.ManageOrganizationsModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        { path: 'organization', data: { access: [5, 10], allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organization/edit/:id', data: { mode: 'edit', access: [5, 10], allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-organizations/manage-organizations.module').then(m => m.ManageOrganizationsModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organization/licenses-history', data: { mode: 'edit', access: [5, 10], allowedLicenses: [1] },
          loadChildren: () => import('./pages/transcoding-licenses-history/transcoding-licenses-history.module')
            .then(module => module.TranscodingLicensesHistoryModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organization/users/new', data: { mode: 'new', access: [5, 10], allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organizations/users/new', data: { mode: 'new', access: [5, 10], allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organization/users/edit/:id', data: { mode: 'edit', access: [5, 10], allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'organizations/users/edit/:id', data: { mode: 'edit', access: [5, 10], allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-user/manage-user.module').then(m => m.ManageUserModule),
          canActivate: [OnlyAdminGuard, LicenseGuard, IsActiveLicenseGuard]
        },
        {
          path: 'account', data: { allowedLicenses: [1, 2] },
          loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'clips', data: { allowedLicenses: [1] },
          loadChildren: () => import('./pages/clips/clips.module').then(m => m.ClipsModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'clips/new',
          data: { mode: 'new', feature: 'clips', permissionMode: 'create', allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-clip/manage-clip.module')
            .then(module => module.ManageClipModule),
          canActivate: [RequiredClustersGuard, PermissionGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'clips/edit/:id', data: { mode: 'edit', feature: 'clips', permissionMode: 'edit', allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-clip/manage-clip.module').then(m => m.ManageClipModule),
          canActivate: [PermissionGuard, PermissionGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'kairos', data: { feature: 'kairos', permissionMode: 'read', allowedLicenses: [1] },
          loadChildren: () => import('./pages/kairos/kairos.module').then(module => module.KairosModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'device-management-systems', data: { feature: 'device-management-systems', permissionMode: 'read', allowedLicenses: [1] },
          loadChildren: () => import('./pages/kairos-device-management-system/kairos-device-management-system.module')
            .then(module => module.KairosDeviceManagementSystemModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'production', data: { feature: 'production', permissionMode: 'read', allowedLicenses: [1] },
          loadChildren: () => import('./pages/production/production.module').then(m => m.ProductionModule),
          canActivate: [LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'production/new', data: { mode: 'new', feature: 'production', permissionMode: 'create', allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-production/manage-production.module').then(m => m.ManageProductionModule),
          canActivate: [PermissionGuard, PermissionGuard, RequiredClustersGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'production/edit/:id', data: { mode: 'edit', feature: 'production', permissionMode: 'edit', allowedLicenses: [1] },
          loadChildren: () => import('./pages/manage-production/manage-production.module').then(m => m.ManageProductionModule),
          canActivate: [PermissionGuard, PermissionGuard, RequiredClustersGuard, LicenseGuard, IsActiveLicenseGuard],
        },
        {
          path: 'payment', data: { access: [5], allowedLicenses: [2] },
          loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
          canActivate: [OnlyAdminGuard, LicenseGuard]
        },
        {
          path: 'usage', data: { access: [5, 10], allowedLicenses: [2] },
          loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
          canActivate: [OnlyAdminGuard, LicenseGuard]
        },
        { path: 'no-access', loadChildren: () => import('./pages/no-access/no-access.module').then(m => m.NoAccessModule) },
      ]
    },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    {
      path: 'forgot-password',
      loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
      path: 'reset-password/:token',
      loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
    { path: 'signup/:token', loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule) },
    { path: 'multiview/:id', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule) },
    { path: 'multiview/:dns/:shortName', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule) },
    { path: 'grids/:id', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule) },
    { path: 'grids/:dns/:shortName', loadChildren: () => import('./pages/video-grid/video-grid.module').then(m => m.VideoGridModule) },
    {
      path: 'choose-organization',
      loadChildren: () => import('./pages/choose-organization/choose-organization.module').then(m => m.ChooseOrganizationModule)
    },
    {
      path: 'invite/:token',
      loadChildren: () => import('./pages/activate-invitation/activate-invitation.module').then(m => m.ActivateInvitationModule)
    },
    { path: '**', redirectTo: 'clusters' },
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
