import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromCurrentUser from '../store/users/reducers/current-user.reducer';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { getCurrentUserRecord } from '../store/users/selectors/current-user.selectors';
import { User } from '../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class OnlyAdminGuard implements CanActivate {

  constructor(private store: Store<fromCurrentUser.State>,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const roles = route.data['access'];
    return this.checkRole(roles).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkRole(roles: number[]) {
    return this.store.select(getCurrentUserRecord)
      .pipe(
        tap((user: User) => {
          if (!(roles.includes(user.role.value))) {
            this.router.navigate(['']);
          }
        }),
        filter((user: User) => roles.includes(user.role.value)),
        take(1)
      );
  }
}
