import { createAction, props } from '@ngrx/store';

export const sendUserInvite = createAction(
  '[UserInvite] Send User Invite',
  props<{ data: any }>()
);

export const sendUserInviteSuccess = createAction(
  '[UserInvite] Send User Invite Success',
  props<{ data: any }>()
);

export const sendUserInviteFailure = createAction(
  '[UserInvite] Send User Invite Failure',
  props<{ error: any }>()
);

export const clearUserInviteState = createAction(
  '[UserInvite] clear User Invite State',
);
