import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import * as DeleteRecordActions from '../actions/delete-record.actions';
import {DvrService} from '../../../services/api/dvr/dvr.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as RecordsActions from '../actions/records.actions';
import {DeleteDvrRecordPayload} from '../../../services/api/dvr/interfaces/delete.dvr-record.payload.interface';


@Injectable()
export class DeleteRecordEffects {

  // noinspection JSUnusedGlobalSymbols
  deleteRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteRecordActions.deleteRecord),
      concatMap((payload: { data: DeleteDvrRecordPayload }) =>
        this.dvrService.deleteRecord(payload.data)
          .pipe(
            map(data => DeleteRecordActions.deleteRecordSuccess({data})),
            catchError(error => of(DeleteRecordActions.deleteRecordFailure({error})))
          )
      )
    );
  });

  // noinspection JSUnusedGlobalSymbols
  deleteRecordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DeleteRecordActions.deleteRecordSuccess),
      tap(() => this.snackBar.open('Record deleted successfully', 'Success', {duration: 3000})),
      map(() => RecordsActions.loadRecords())
    );
  });

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private dvrService: DvrService
  ) {
  }

}
